import { RedirectUriActions, RedirectUri, ExitUri } from './types';
import { SAVE_EXIT_URI, SAVE_REDIRECT_URI } from '../../helpers/actionTypes';

/**
 * Action Creator
 * @param newRedirectUri update value for action
 * @returns SaveRedirectUriActions
 */
export function SaveRedirectUriAction(
  newRedirectUri: RedirectUri,
): RedirectUriActions {
  return {
    type: SAVE_REDIRECT_URI,
    payload: newRedirectUri,
  };
}

/**
 * Action Creator
 * @param newRedirectUri update value for action
 * @returns SaveRedirectUriActions
 */
export function SaveExitUriAction(newExitUri: ExitUri): RedirectUriActions {
  return {
    type: SAVE_EXIT_URI,
    payload: newExitUri,
  };
}
