import { FooterActions, FooterDetails } from './types';
import { UPDATE_FOOTER } from '../../../helpers/actionTypes';

/**
 * Action creator for Footer
 * @param newFooterDetails
 * @returns Returns FooterActions
 */
export default function saveFooterAction(
  newFooterDetails: FooterDetails,
): FooterActions {
  return {
    type: UPDATE_FOOTER,
    payload: newFooterDetails,
  };
}
