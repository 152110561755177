import {
  CaptureActions,
  CaptureSequence,
  OverrideSession,
  OverrideSessionActions,
  OVERRIDE_SESSION_UNKNOWN,
  SavePageConfigActions,
  PageData,
} from './types';
import {
  CAPTURE_CONF,
  MULTIPLE_SESSION,
  SAVE_PAGE_CONFIG,
  PAGE_SEQ,
} from '../../../helpers/actionTypes';

const initialState: CaptureSequence = {
  pageSequence: [],
  settings: {},
};

const OverrideInitialState: OverrideSession = {
  value: OVERRIDE_SESSION_UNKNOWN,
};

const PageConfigInitialState: PageData = {};

/**
 * Reducer for Capture Page Sequence
 * @param state Previous state from the store
 * @param action Action to be performed
 */
export function IndvCaptureSeqReducer(
  state = initialState,
  action: CaptureActions,
): CaptureSequence {
  switch (action.type) {
    case CAPTURE_CONF:
      return {
        ...state,
        ...action.payload,
      };
    case PAGE_SEQ:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export function OverrideSessionReducer(
  state = OverrideInitialState,
  action: OverrideSessionActions,
): OverrideSession {
  switch (action.type) {
    case MULTIPLE_SESSION:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export function SavePageConfigReducer(
  state = PageConfigInitialState,
  action: SavePageConfigActions,
): PageData {
  switch (action.type) {
    case SAVE_PAGE_CONFIG:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
