import {
  SAVE_SKILL_CONFIG,
  UPDATE_SKILL_CONFIG,
} from '../../../../helpers/actionTypes';
import {
  SaveSkillConfigActions,
  SkillConfiguration,
  SkillDetails,
} from './types';

export function SaveSkillConfigAction(
  newSkillConfigData: SkillConfiguration,
): SaveSkillConfigActions {
  return {
    type: SAVE_SKILL_CONFIG,
    payload: newSkillConfigData,
  };
}

export function UpdateSkillConfigAction(
  newSkillDetails: SkillDetails,
): SaveSkillConfigActions {
  return {
    type: UPDATE_SKILL_CONFIG,
    payload: newSkillDetails,
  };
}
