import { UpdateInProgressDIV, DIVDetails } from './types';
import { IN_PROGRESS_DIV } from '../../helpers/actionTypes';

/**
 * Action Creator
 * @param newDIVDetails update value for action
 * @returns SaveDIVDetails
 */
export default function SaveDIVDetails(
  newDIVDetails: DIVDetails,
): UpdateInProgressDIV {
  return {
    type: IN_PROGRESS_DIV,
    payload: newDIVDetails,
  };
}
