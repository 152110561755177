import { PageHeaderActions, PageHeaderDetails } from './types';
import { UPDATE_PAGE_HEADER } from '../../helpers/actionTypes';

/**
 * Action creator for Header
 * @param newHeaderDetails
 * @returns Returns HeaderActions
 */
export default function saveResponseHeaderAction(
  newPageHeaderDetails: PageHeaderDetails,
): PageHeaderActions {
  return {
    type: UPDATE_PAGE_HEADER,
    payload: newPageHeaderDetails,
  };
}
