import React from 'react';
import { connect } from 'react-redux';
import { OverlayOptions } from '../../store/Error/types';
import { AppState } from '../../store';
import SimpleOverlay from '../SimpleOverlay';
import { log, LogLevel, logPageVisit } from '../../helpers/loggerWrapper';
import i18n from '../../i18n/i18n';
import { DataService } from '../../services/DataService';

interface StateProps {
  errorDetails: OverlayOptions;
}

type Props = StateProps;

const translate = i18n.t.bind(i18n);
export class ValidationFailureComponent extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.retry = this.retry.bind(this);
  }

  componentDidMount(): void {
    logPageVisit('ValidationError', 'ValidationFailureComponent', '', true);
  }

  /**
   * Method to retry validation check
   */
  retry(): void {
    const { errorDetails } = this.props;
    log(
      LogLevel.Info,
      {
        serviceCategory: 'Capture',
        service: 'ValidationsCheck',
        eventType: 'Clicked',
        eventName: 'Retry',
        component: 'ValidationFailureComponent',
        eventSource: 'retry',
      },
      {
        details: errorDetails.errorType,
      },
    );
    let { pageIndex } = window.history.state.state;
    pageIndex = pageIndex ? pageIndex : 0;
    if (DataService.IsVKycStarted()) {
      // eslint-disable-next-line
      DataService.SendVKycTaskData().then(() => {});
    } else {
      // eslint-disable-next-line
      DataService.ValidatePage(pageIndex).then(() => {});
    }
  }

  render(): JSX.Element {
    const { errorDetails } = this.props;
    return (
      <SimpleOverlay
        showHeader={false}
        titleConfig={{
          primaryText:
            errorDetails &&
            errorDetails.titleConfig &&
            errorDetails.titleConfig.primaryText
              ? errorDetails.titleConfig.primaryText
              : translate('INVALID_LOCATION'),
          secondaryText:
            errorDetails &&
            errorDetails.titleConfig &&
            errorDetails.titleConfig.secondaryText
              ? (errorDetails.titleConfig.secondaryText as string)
              : (errorDetails.errorMessage as string),
          titleSvg:
            errorDetails &&
            errorDetails.titleConfig &&
            errorDetails.titleConfig.titleSvg
              ? errorDetails.titleConfig.titleSvg
              : 'location',
        }}
        buttonConfig={[{ label: translate('RETRY_LABEL'), action: 'retry' }]}
        actionCallback={this.retry}
        showCrossButton={false}
        loaderConfig={errorDetails.loaderConfig}
      />
    );
  }
}

const mapStatetoProps = (state: AppState): StateProps => ({
  errorDetails: state.ErrorAndOverlay as OverlayOptions,
});

export default connect<StateProps, {}, {}, AppState>(mapStatetoProps)(
  ValidationFailureComponent,
);
