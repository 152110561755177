import { Middleware, MiddlewareAPI, Dispatch, AnyAction } from 'redux';
import { Rg4JsWrapperService } from '../helpers/raygunWrapper';

const BreadcrumbMiddleware: Middleware =
  (api: MiddlewareAPI) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction): void => {
    const previousState = api.getState();
    const nextAction = next(action);
    const state = api.getState();
    Rg4JsWrapperService.Log({
      callType: 'recordBreadcrumb',
      objectValue: {
        msgObj: {
          message: 'Recording Breadcrumb',
          level: 'info',
          metadata: {},
          location: 'Unknown:Location',
        },
        metaObj: {
          previousState,
          nextAction,
          state,
        },
      },
    });
  };

export default BreadcrumbMiddleware;
