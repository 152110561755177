import { Middleware, MiddlewareAPI, Dispatch, AnyAction } from 'redux';
import { PluginRegistry } from '../services/PluginRegistry';

const IDfyPluginMiddleware: Middleware =
  (api: MiddlewareAPI) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction): void => {
    PluginRegistry.execPlugins(api, action);
    next(action);
  };

export default IDfyPluginMiddleware;
