import { HeaderActions, HeaderDetails, HeaderTitleDetails } from './types';
import {
  UPDATE_HEADER,
  UPDATE_HEADER_TITLE,
} from '../../../helpers/actionTypes';

/**
 * Action creator for Header
 * @param newHeaderDetails
 * @returns Returns HeaderActions
 */
export function saveHeaderAction(
  newHeaderDetails: HeaderDetails,
): HeaderActions {
  return {
    type: UPDATE_HEADER,
    payload: newHeaderDetails,
  };
}

export function saveHeaderTitleAction(
  newTitleDetails: HeaderTitleDetails,
): HeaderActions {
  return {
    type: UPDATE_HEADER_TITLE,
    payload: newTitleDetails,
  };
}
