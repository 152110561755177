import { PageConfig, PageDetails } from '../components/IndvCapture/store/types';
import { CaptureConfigDetails } from '../components/Capture/store/types';
import {
  PERMISSION_DENIED,
  PERMISSION_GRANTED,
  CAPTURE_TASKS_DATA_CAPTURE,
  CAPTURE_TASKS_ASSISTED_VKYC,
  CAPTURE_TASKS_SV_SERVICE,
  CAPTURE_TEMPLATES_CARD,
  CAPTURE_TEMPLATES_TEXT,
  CAPTURE_TEMPLATES_BUTTON,
  CAPTURE_TEMPLATES_IMAGE,
  CAPTURE_TEMPLATES_GROUP,
  CAPTURE_TASKS_GROUPED,
  CAPTURE_TASKS_VERIFY_AADHAAR_XML,
  CAPTURE_TEMPLATES_FILE_PICKER,
  CAPTURE_TASKS_FETCH_AADHAAR_XML,
  CAPTURE_TEMPLATES_ANCHOR,
  CAPTURE_TEMPLATES_FORM,
  PAGE_SEQ_INTRO,
  PAGE_SEQ_CONSENT,
  PAGE_SEQ_PERMISSIONS,
  PAGE_SEQ_CAPTURE,
  PAGE_SEQ_AVKYC,
  PAGE_SEQ_SV_SERVICE,
  PAGE_SEQ_CAPTURE_PREREQUISITE,
  CAPTURE_TASKS_GENERATE_OTP,
  CAPTURE_TASKS_VALIDATE_OTP,
} from '../helpers/constants';
import { ThemeConfig } from '../store/ThemeConfig/types';
import { AxiosResponse } from 'axios';
import { ResponseDetails } from '../store/ResponseBody/types';
import { ValidateResponseType } from './DataService';

interface _GroupDefinition {
  header: {
    title: string;
    logo: string;
    logo_alt: string;
  };
  body: {
    info: {
      title: string;
      primary_text: string;
      secondary_text: string;
    };
    profiles: _GroupMember[];
  };
}

interface _GroupMember {
  full_name: string;
  status: string;
  capture_link: string;
  public_id: string;
  status_details: string;
  group_link: string;
}

interface _IndvCaptureStatus {
  header: {
    title: string;
    logo: string;
    logo_alt: string;
  };
  body: ResponseDetails;
  theme_config: ThemeConfig;
}

interface _CapturePageSequence {
  page_sequence: string[];
  introduction: {
    title: string;
    text: string;
  };
  consent: {
    title: string;
    text: string;
  };
}
interface _CaptureSequenceNew {
  page_sequence: PageConfig[];
  validation_config: {
    [indent: string]: {
      'task.type': string;
      attr: string;
      criteria: [];
      type: string;
    };
  };
  config?: {
    [indent: string]: PageDetails | CaptureConfigDetails;
  };
  settings: {
    [ident: string]:
      | boolean
      | string[]
      | {
          [ident: string]:
            | {
                min?: string;
                max?: string;
                include?: string[];
                exclude?: string[];
                os?: string[];
              }[]
            | string
            | boolean;
        };
  };
}

enum _SCHEDULE_CALL_BUTTON_STATE {
  ALWAYS = 'always',
  AFTER_THRESHOLD = 'after_threshold',
}

/**
 * Mocks the data service layer
 */
export class MockDataService {
  /**
   * Mocks fetching of group details
   * @param tok authorization token
   * @returns GroupDetails
   */
  static validateCounter = 0;
  static FetchGroupDetails = (tok: string): _GroupDefinition => {
    const grpMocksMap: { [grpIdent: string]: string } = {
      '1234': MockDataService.mockGroupDefn1(),
      '2345': MockDataService.mockGroupDefn2(),
      test1: MockDataService.mockGroupDefn3(),
      test2: MockDataService.mockGroupDefn4(),
    };
    return JSON.parse(grpMocksMap[tok]);
  };

  /**
   * Mocks fetching of individual's capture status details
   * @param tok authorization token
   * @returns CaptureStatusDetails
   */
  static FetchCaptureStatusDetails = (tok: string): _IndvCaptureStatus => {
    const indvDtlsMocksMap: { [indvIdent: string]: string } = {
      '1234': MockDataService.mockIndvDetails1(),
      '2345': MockDataService.mockIndvDetails2(),
      '3456': MockDataService.mockIndvDetails3(),
      '4567': MockDataService.mockIndvDetails4(),
      '5678': MockDataService.mockIndvDetails5(),
      '6789': MockDataService.mockIndvDetails6(),
      '7890': MockDataService.mockIndvDetails7(),
      av_skill_select: MockDataService.mockIndvDetails8(),
    };
    return JSON.parse(indvDtlsMocksMap[tok]);
  };

  /**
   * Mocks fetching of capture page sequence
   * @param cptId authorization token
   * @returns CaptureSequence
   */
  static FetchPageSequence = (cptId: string): _CapturePageSequence => {
    const pgSeqMocksMap: { [pgSeqIdent: string]: any } = {
      'cpt-1': MockDataService.mockPageSequence1(),
      consent_intro: MockDataService.mockPageSequence1(),
      intro_consent: MockDataService.mockPageSequence2(),
      'cpt-2': MockDataService.mockPageSequence3(),
      'cpt-3': MockDataService.mockPageSequence2(),
      'cpt-4': MockDataService.mockPageSequence1(),
      'cpt-5': MockDataService.mockPageSequence1(),
      'cpt-6': MockDataService.mockPageSequence1(),
      'cpt-7': MockDataService.mockPageSequence1(),
      av_skill_select: MockDataService.mockPageSequence5(),
    };
    return pgSeqMocksMap[cptId];
  };

  /**
   * Mocks fetching of capture configuration
   * @param cptId authorization token
   * @returns CaptureConfigDetails
   */
  static FetchCaptureConfig = (cptId: string): any => {
    const capConfMocksMap: { [cptCfgIdent: string]: any } = {
      'cpt-1': MockDataService.mockCaptureConfig6(),
      'cpt-2': MockDataService.mockCaptureConfig3(),
      'cpt-3': MockDataService.mockCaptureConfig5(),
      'cpt-4': MockDataService.mockCaptureConfig4(),
      'cpt-5': MockDataService.mockCaptureConfig1(),
      'cpt-6': MockDataService.mockCaptureConfig1(),
      'cpt-7': MockDataService.mockCaptureConfig1(),
      'test-1': MockDataService.mockCaptureConfig2(),
    };
    return capConfMocksMap[cptId];
  };

  private static getPageConfig(pageName: string): _CaptureSequenceNew {
    switch (pageName) {
      case 'introduction':
        return MockDataService.mockIntroductionPage();
      case 'consent':
        return MockDataService.mockConsentPage();
      case 'permissions':
        return MockDataService.mockPermissionPage();
      case 'capture':
        return MockDataService.mockDigiLockerWithinImage();
      case 'av_skill_select':
        return MockDataService.mockSkillSelectPage();
      case 'vkyc.assisted_vkyc':
        return MockDataService.mockAssistedVkycPage();
      case 'self_video':
        return MockDataService.mockSelfVkycDecoupled();
      case 'prerequisite':
        return MockDataService.mockPrerequisitePage();
      default:
        return MockDataService.mockIntroductionPage();
    }
  }

  /**
   * Mock the response of session:fetch_config
   */
  static GetPageData = (
    index: number,
    pageName?: string,
  ): _CaptureSequenceNew => {
    if (pageName) {
      return MockDataService.getPageConfig(pageName);
    }
    const MockPageData: { [pageIdent: string]: any } = {
      // '0': MockDataService.mockFirstPage(),
      // '1': MockDataService.mockSecondPage(),
      // '2': MockDataService.mockPermissionPage(),

      //use below for decoupled self services
      '0': MockDataService.mockSelfVkycDecoupled(),
      // '3': MockDataService.mockSelfVKycPageForDecoupled(),
      //use below for coupled self services
      // '3': MockDataService.mockSelfVkyc(),
      // '4': MockDataService.mockSelfVKycPAge()

      // '3': MockDataService.mockAaadhaarXMLWithImages()
    };
    MockDataService.validateCounter = MockDataService.validateCounter + 1;
    return MockPageData[index];
  };

  static mockIntroductionPage = (): any => {
    return {
      validations: [],
      data: {
        page: 'introduction',
        config: {
          title: 'Initiate your Verification.',
          text: '<p style=color:red>Details</p>',
        },
      },
    };
  };

  static mockConsentPage = (): any => {
    return {
      validations: [],
      data: {
        page: 'consent',
        config: {
          title: 'Consent',
          text: '<p style=color:red>Consent Details</p>',
        },
      },
    };
  };

  static mockSkillSelectPage = (): any => {
    return {
      validations: [],
      data: {
        page: 'av_skill_select',
        config: [
          {
            skill: 'language',
            label: 'Please select your language',
            options: ['hindi', 'english', 'marathi'],
            type: 'dropdown',
          },
          {
            skill: 'use_case',
            label: 'Please select your use case',
            options: ['credit_card', 'debit_card'],
            type: 'radio',
          },
          {
            skill: 'product_code',
            label: 'Please select your Product Code',
            options: ['credit_card', 'debit_card'],
            type: 'checkbox',
          },
        ],
      },
    };
  };

  static mockAssistedVkycPage = (): any => {
    return {
      data: {
        attempts: {
          attempts_exhausted: true,
          attempts_today: 0,
          last_attempt: false,
        },
        config: {
          wait_screen_configuration: {
            schedule_call_button: _SCHEDULE_CALL_BUTTON_STATE.ALWAYS,
            display_wait_screen_countdown: true,
            threshold: {
              message_before: '',
              message_after: '',
              time: 15000,
            },
          },
          bandwidth: {
            bitrate: {
              agent: {
                max_outgoing: 0,
              },
              user: {
                max_outgoing: 0,
              },
            },
            high_res: {
              manual_override: { enabled: false, label: '' },
              screenshot: false,
            },
            low_res_video: false,
          },
          network_check_config: {
            checks: { room_join: false },
            enabled: false,
            timeout: 0,
            health_check_failure_rules: null,
          },
          task: {
            configuration: {
              assigned_user: false,
              organization_unit_id: '',
              overrides: {
                assignee: [{ account_id: '', action: '', email: '' }],
              },
              validations: [],
            },
            error: '',
            key: '',
            request_uid: '',
            status: '',
            task_type_key: '',
            validation_error: '',
          },
          video_overlay: {
            enabled: true,
          },
          unavailable_agent_config: {
            title: 'title',
            text: 'text',
          },
        },
        scheduling: {
          enabled: false,
          slots_available: false,
          start_date: '',
          end_date: '',
          booking_details: {
            reference_id: '',
          },
        },
        page: 'vkyc.assisted_vkyc',
      },
      validations: [],
    };
  };

  static mockSelfVKycPage = (): any => {
    return {
      prerequisite_card: {
        box: false,
        title: '',
        prerequisite_items: [],
        display_as_checklist: false,
      },
      task: {
        task_type_key: CAPTURE_TASKS_SV_SERVICE,
        status: 'in_progress',
        request_uid: 'd6e7b94d-06bf-11ea-a076-a2a18de305e3',
        key: CAPTURE_TASKS_SV_SERVICE,
      },
    };
  };

  static mockSelfVKycPageForDecoupled = (): any => {
    return {
      validations: [],
      data: {
        page: PAGE_SEQ_SV_SERVICE,
        config: {
          task: {
            task_type_key: CAPTURE_TASKS_SV_SERVICE,
            status: 'in_progress',
            request_uid: '8ea1513d-f8a1-4dea-9bea-6b8f4b5b6e73',
            key: CAPTURE_TASKS_SV_SERVICE,
          },
          prerequisite_card: {
            box: false,
            title: '',
            prerequisite_items: ['pan', 'aadhar'],
            display_as_checklist: true,
          },
        },
      },
    };
  };

  static mockGenericPage(page_key: string): any {
    const mockedData: {
      [ident: string]: any;
    } = {
      generic_page1: {
        data: {
          config: {
            artifacts: {
              'nil.ind_pan.nil.1.front': {
                category: null,
                content_type: 'image/jpeg',
                document_type_key: 'ind_pan',
                error: null,
                fact_type_key: null,
                key: 'nil.ind_pan.nil.1.front',
                location: {},
                metadata: {
                  capture_mechanism: 'camera',
                  location: {
                    accuracy: 23.949,
                    latitude: 19.0252539,
                    longitude: 73.089911,
                  },
                },
                present: true,
                side: 'front',
                source: 1,
                tags: null,
                type: 'image',
                upload_url:
                  'https://e1dc150ac707.storage.pg.idfystaging.com/8c4bc153-d90e-4c45-872c-eb057fffcf10?Expires=1648917639&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=q0ybQoJXOMRok5FIPPtDrxpEp3rc0LhqBd%2Fy%2BK4JC7H9ykK%2FIcBqjGTAg%2FnUmhgTeihdg3%2BOtGYmlyI2iAzDfav6boUuA3VAEr%2BXYEYVQES27SAQt9voYNY5wjf4l%2Fjc6etEmZ7Nq7tRip%2B6aExp9tSmdz2GN81%2BZNpuNJB%2Br6XA1m9ymVo3yRQTD5m1vKbm%2Fa9CkLJYoQFsUoQyYTQfDKKbSFMz8dVCsMjVS9THmwpJy7VsYZvNcnml%2BmbWWpUe%2BxGT4eizTxneARh0od%2FpYN7rOSD9IcqTcg%2Baj%2FzZNriXPQfWLlPuqXRjMa5vSwwuJxVCwD7GZYx6AEIqUb%2Bu2Q%3D%3D',
                value:
                  'https://e1dc150ac707.storage.pg.idfystaging.com/8c4bc153-d90e-4c45-872c-eb057fffcf10?Expires=1648917639&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=BoZKTg5XnpIABBOVJc3KVDCYTbYVw09ntZbL5kI2AQ5tZIXAZfpZcidTTqYPJkm4Q3uWgl4hksQnI3%2BQU1wVAApTgIwh6arwGeY%2BLrEHIcrOdIz%2Fp2pxe9Q3dm%2BbZ3FMeBju958xTWYgwxfJrOj1YI6wqKaEG%2FZlPfYPGJ3O1awx8P3xCZMjYc2W%2BSspCDikB%2BTGp7zI5DK%2BCFfR2AQ9DJ0qlBwrdVW2puabpX0NrQO1DZEaJeFjdkj%2FldItpmk1r6%2BAA1oexq4BLHUAhmmBccusG006qh9dFfo7s5t99n2tco8s51DK9M%2FD8MLGL4NbtsP7Ub2iEq86oXNgbM3cMg%3D%3D',
              },
            },
            tasks: {},
            templates: {
              '43392': {
                allow_upload: true,
                artifacts: ['nil.ind_pan.nil.1.front'],
                capture_mechanisms: {},
                category_type: 'poi',
                dependencies: [],
                detail: null,
                document_type_key: 'ind_pan',
                fact_type_key: null,
                help_text: null,
                id: 43392,
                label: 'PAN Card',
                mandatory: false,
                options: [],
                overlay_type_id: null,
                placeholder: null,
                refs: [],
                regex: null,
                remarks_mandatory_for: [],
                request_data: null,
                response_ref: [],
                settings: {},
                side: 'front',
                task_key: 'key10',
                task_type: 'data_capture.data_capture',
                title: null,
                type: 'image',
              },
              '43393': {
                allow_upload: true,
                artifacts: [],
                capture_mechanisms: {},
                category_type: null,
                dependencies: [],
                detail: null,
                document_type_key: null,
                fact_type_key: null,
                help_text: null,
                id: 43393,
                label: null,
                mandatory: false,
                options: [],
                overlay_type_id: null,
                placeholder: null,
                refs: [],
                regex: null,
                remarks_mandatory_for: [],
                request_data: null,
                response_ref: [],
                settings: {},
                side: null,
                task_key: null,
                task_type: null,
                title: 'PAN Card',
                type: 'card',
              },
            },
            allowed_poa_documents: [],
            allowed_poi_documents: [],
            capture: [
              [
                {
                  tasks: [
                    {
                      artifacts: ['nil.ind_pan.nil.1.front'],
                      task_key: 'key10',
                      task_type: 'data_capture.data_capture',
                      template_id: 43392,
                    },
                  ],
                  template_id: 43393,
                },
              ],
            ],
            geotag: {
              images: true,
            },
            page_sequence: [
              {
                page: 'introduction',
                validations: [],
              },
              {
                page: 'permissions',
                validations: [],
              },
              {
                page: 'capture',
                validations: [],
              },
            ],
            permissions: {
              capture: [
                {
                  attr: 'location',
                  template: {
                    help_text: 'Required for verifying location',
                    label: 'Location',
                    mandatory: true,
                    type: 'span',
                  },
                },
                {
                  attr: 'camera',
                  template: {
                    help_text: 'Required for doing a video call',
                    label: 'Camera',
                    mandatory: true,
                    type: 'span',
                  },
                },
                {
                  attr: 'microphone',
                  template: {
                    help_text: 'Required for doing a video call',
                    label: 'Microphone',
                    mandatory: true,
                    type: '',
                  },
                },
              ],
              title: 'Ask Permissions',
            },
            validation_config: {
              location: {
                attr: 'location',
                attr_type: 'user',
                criteria: [
                  {
                    compare_with: ['IN'],
                    operator: '!=',
                    output: ['Location should be within India'],
                  },
                ],
                type: 'user',
              },
            },
          },
          capture_config_key: 'generic_page.1',
          page: 'generic_page',
          page_key: 'generic_page_1',
          page_type: 'capture',
        },
        validations: [],
      },
      generic_page2: {
        data: {
          config: {
            title: 'initiate your verification from mock',
            prerequisite_items: [
              'Well-lit surrounding',
              'Noise-free background',
            ],
            display_as_checklist: true,
            header: 'Header from mock',
            subtitle: 'Subtitle from mock',
          },
          page: 'generic_page',
          page_key: 'generic_page_2',
          page_type: 'av_prerequisite',
        },
        validations: [],
      },
      generic_page3: {
        data: {
          config: [
            {
              skill: 'language',
              label: 'Please select your language',
              options: ['hindi', 'english', 'marathi'],
              type: 'dropdown',
            },
            {
              skill: 'use_case',
              label: 'Please select your use case',
              options: ['credit_card', 'debit_card'],
              type: 'radio',
            },
            {
              skill: 'product_code',
              label: 'Please select your Product Code',
              options: ['credit_card', 'debit_card'],
              type: 'checkbox',
            },
          ],
          page: 'generic_page',
          page_key: 'generic_page_3',
          page_type: 'av_skill_select',
        },
        validations: [],
      },
      generic_page4: {
        data: {
          attempts: {
            attempts_exhausted: true,
            attempts_today: 0,
            last_attempt: false,
          },
          config: {
            wait_screen_configuration: {
              schedule_call_button: _SCHEDULE_CALL_BUTTON_STATE.ALWAYS,
              display_wait_screen_countdown: true,
              threshold: {
                message_before: '',
                message_after: '',
                time: 15000,
              },
            },
            bandwidth: {
              bitrate: {
                agent: {
                  max_outgoing: 0,
                },
                user: {
                  max_outgoing: 0,
                },
              },
              high_res: {
                manual_override: { enabled: false, label: '' },
                screenshot: false,
              },
              low_res_video: false,
            },
            network_check_config: {
              checks: { room_join: false },
              enabled: false,
              timeout: 0,
              health_check_failure_rules: null,
            },
            task: {
              configuration: {
                assigned_user: false,
                organization_unit_id: '',
                overrides: {
                  assignee: [{ account_id: '', action: '', email: '' }],
                },
                validations: [],
              },
              error: '',
              key: '',
              request_uid: '',
              status: '',
              task_type_key: '',
              validation_error: '',
            },
            video_overlay: {
              enabled: true,
            },
            unavailable_agent_config: {
              title: 'title',
              text: 'text',
            },
          },
          scheduling: {
            enabled: false,
            slots_available: false,
            start_date: '',
            end_date: '',
            booking_details: {
              reference_id: '',
            },
          },
          page: 'generic_page',
          page_key: 'generic_page_4',
          page_type: 'vkyc.assisted_vkyc',
        },
        validations: [],
      },
    };
    return mockedData[page_key];
  }

  private static mockGroupDefn1 = (): string => {
    return `{
      "header": {
        "title": "Group Verification",
        "logo": "https://static.wixstatic.com/media/18125b_1c55ed83ce124dafa588289340f80011~mv2.png",
        "logo_alt": "IDfy"
      },
      "body": {
        "info": {
          "title": "Welcome,",
          "primary_text": "To start with Test User-1 guest verification, start by clicking on Capture Details under the name below.",
          "secondary_text": "If you are a part of Test User-1 group, kindly use the Guest section."
        },
        "profiles": [
          {
            "full_name": "Test User-1",
            "status": "capture_pending",
            "capture_link": "http://localhost:3000/captures?t=1234",
            "public_id": "",
            "status_details": "Details Not Captured",
            "group_link": "http://localhost:3000/captures/group?t=1234"
          },
          {
            "full_name": "Only Capture Page In Page Sequence",
            "status": "recapture_pending",
            "capture_link": "http://localhost:3000/captures?t=2345",
            "public_id": "",
            "status_details": "Recapture Pending",
            "group_link": "http://localhost:3000/captures/group?t=1234"
          },
          {
            "full_name": "",
            "status": "failed",
            "capture_link": "http://localhost:3000/captures?t=3456",
            "public_id": "",
            "status_details": "Capture Failed",
            "group_link": "http://localhost:3000/captures/group?t=1234"
          }
        ]
      }
    }`;
  };

  private static mockGroupDefn2 = (): string => {
    return `{
      "header": {
        "title": "Group Verification 2",
        "logo": "https://static.wixstatic.com/media/18125b_1c55ed83ce124dafa588289340f80011~mv2.png",
        "logo_alt": "IDfy"
      },
      "body": {
        "info": {
          "title": "Welcome,",
          "primary_text": "To start with Test User-11 guest verification, start by clicking on Capture Details under the name below.",
          "secondary_text": "If you are a part of Test User-11 group, kindly use the Guest section."
        },
        "profiles": [
          {
            "full_name": "Test User-11",
            "status": "review",
            "capture_link": "http://localhost:3000/captures?t=4567",
            "public_id": "",
            "status_details": "Capture Under Review",
            "group_link": "http://localhost:3000/capture/group?t=2345"
          },
          {
            "full_name": "Test User-12",
            "status": "initiated",
            "capture_link": "http://localhost:3000/captures?t=5678",
            "public_id": "",
            "status_details": "Capture initiated",
            "group_link": "http://localhost:3000/capture/group?t=2345"
          },
          {
            "full_name": "Test User-13",
            "status": "capture_pending",
            "capture_link": "http://localhost:3000/captures?t=6789",
            "public_id": "",
            "status_details": "Details Not Captured",
            "group_link": "http://localhost:3000/capture/group?t=2345"
          },
          {
            "full_name": "Test User-14",
            "status": "capture_pending",
            "capture_link": "http://localhost:3000/captures?t=7890",
            "public_id": "",
            "status_details": "Details Not Captured",
            "group_link": "http://localhost:3000/capture/group?t=2345"
          }
        ]
      }
    }`;
  };

  private static mockGroupDefn3 = (): string => {
    return `{
      "header": {
        "title": "Guest Group",
        "logo": "https://static.wixstatic.com/media/18125b_1c55ed83ce124dafa588289340f80011~mv2.png/v1/fill/w_78,h_50,al_c,lg_1,q_80/Menu%20logo_all.webp",
        "logo_alt": ""
      },
      "body": {
        "info": {
          "title": "Welcome,",
          "primary_text": "To start with Guest-1 guest verification, start by clicking on Capture Details under the name below.",
          "secondary_text": "If you are a part of Guest-1 group, kindly use the Guest section."
        },
        "profiles": [
          {
            "full_name": "Test User",
            "status": "capture_pending",
            "capture_link": "https://capture.idfystaging.com/intro?t={auth_token}",
            "public_id": "",
            "status_details": "Details Not Captured",
            "group_link": "https://capture.idfystaging.com/groups/50a28389-78ec-4eb9-9770-9150ebeaa6ce"
          },
          {
            "full_name": "",
            "status": "recapture_pending",
            "capture_link": "https://capture.idfystaging.com/intro?t={auth_token}",
            "public_id": "",
            "status_details": "Recapture Pending",
            "group_link": "https://capture.idfystaging.com/groups/50a28389-78ec-4eb9-9770-9150ebeaa6ce"
          },
          {
            "full_name": "",
            "status": "failed",
            "capture_link": "https://capture.idfystaging.com/intro?t={auth_token}",
            "public_id": "",
            "status_details": "Capture Failed",
            "group_link": "https://capture.idfystaging.com/groups/50a28389-78ec-4eb9-9770-9150ebeaa6ce"
          },
          {
            "full_name": "",
            "status": "review",
            "capture_link": "https://capture.idfystaging.com/intro?t={auth_token}",
            "public_id": "",
            "status_details": "Capture Under Review",
            "group_link": "https://capture.idfystaging.com/groups/50a28389-78ec-4eb9-9770-9150ebeaa6ce"
          }
        ]
      }
    }`;
  };

  private static mockGroupDefn4 = (): string => {
    return `{
      "header": {
        "title": "Guest Group",
        "logo": "https://static.wixstatic.com/media/18125b_1c55ed83ce124dafa588289340f80011~mv2.png/v1/fill/w_78,h_50,al_c,lg_1,q_80/Menu%20logo_all.webp",
        "logo_alt": ""
      },
      "body": {
        "info": {
          "title": "Welcome,",
          "primary_text": "To start with Guest-1 guest verification, start by clicking on Capture Details under the name below.",
          "secondary_text": "If you are a part of Guest-1 group, kindly use the Guest section."
        },
        "profiles": [
          {
            "full_name": "",
            "status": "capture_pending",
            "capture_link": "https://capture.idfystaging.com/intro?t={auth_token}",
            "public_id": "",
            "status_details": "Details Not Captured",
            "group_link": "https://capture.idfystaging.com/groups/50a28389-78ec-4eb9-9770-9150ebeaa6ce"
          },
          {
            "full_name": "",
            "status": "recapture_pending",
            "capture_link": "https://capture.idfystaging.com/intro?t={auth_token}",
            "public_id": "",
            "status_details": "Details Not Captured",
            "group_link": "https://capture.idfystaging.com/groups/50a28389-78ec-4eb9-9770-9150ebeaa6ce"
          },
          {
            "full_name": "",
            "status": "review",
            "capture_link": "https://capture.idfystaging.com/intro?t={auth_token}",
            "public_id": "",
            "status_details": "Details Not Captured",
            "group_link": "https://capture.idfystaging.com/groups/50a28389-78ec-4eb9-9770-9150ebeaa6ce"
          },
          {
            "full_name": "",
            "status": "failed",
            "capture_link": "https://capture.idfystaging.com/intro?t={auth_token}",
            "public_id": "",
            "status_details": "Details Not Captured",
            "group_link": "https://capture.idfystaging.com/groups/50a28389-78ec-4eb9-9770-9150ebeaa6ce"
          }
        ]
      }
    }`;
  };

  private static mockIndvDetails1 = (): string => {
    return `{
      "header": {
        "title": "Individual Verification - Test User-1",
        "logo": "https://static.wixstatic.com/media/18125b_1c55ed83ce124dafa588289340f80011~mv2.png",
        "logo_alt": "IDfy"
      },
      "body": {
          "full_name": "Test User-1",
          "package_name": "",
          "status": "capture_pending",
          "status_detail": "Capture Pending",
          "capture_id": "cpt-1",
          "out_of_office": { "active":false, "title": null, "text":null }
        },
        "theme_config": {
          "palette":{"primary":{"main":"#279a9e","light":"#52aeb1","dark":"#1b6b6e","contrastText":"#fff"},"secondary":{"main":"#ffffff","light":"#ffffff","dark":"","contrastText":"#000000"},"info":{"main":"#279a9e","light":"#52aeb1","dark":"","contrastText":""},"success":{"main":"#4caf50","light":"#6fbf73","dark":"","contrastText":"#000000"},"error":{"main":"#f44336","light":"","dark":"","contrastText":""},"warning":{"main":"#ff9800","light":"#ffb74d","dark":"#f57c00","contrastText":"#000000"}},
          "typography": {
            "fontFamily": {
              "primary": {
                "fontFace": "indieflower",
                "url": "https://fonts.gstatic.com/s/indieflower/v11/m8JVjfNVeKWVnh3QMuKkFcZVaUuH.woff2"
              },
              "secondary": {
                "fontFace": "fondamento",
                "url": "https://fonts.gstatic.com/s/fondamento/v10/4UaFrEJGsxNmFTPDnkaJ96_Z67txEwI.woff2"
              }
            }
          }
        }
    }`;
  };

  private static mockIndvDetails2 = (): string => {
    return `{
      "header": {
        "title": "Individual Verification - Test User-2",
        "logo": "https://static.wixstatic.com/media/18125b_1c55ed83ce124dafa588289340f80011~mv2.png",
        "logo_alt": "IDfy"
      },
      "body": {
          "full_name": "",
          "package_name": "",
          "status": "recapture_pending",
          "status_detail": "Recapture Pending",
          "capture_id": "cpt-2",
          "out_of_office": { "active":false, "title": null, "text":null }
        }
    }`;
  };

  private static mockIndvDetails3 = (): string => {
    return `{
      "header": {
        "title": "Individual Verification - Test User-3",
        "logo": "https://static.wixstatic.com/media/18125b_1c55ed83ce124dafa588289340f80011~mv2.png",
        "logo_alt": "IDfy"
      },
      "body": {
        "full_name": "",
        "package_name": "",
        "status": "capture_pending",
        "status_detail": "Capture Pending",
        "capture_id": "cpt-3",
        "out_of_office": { "active":false, "title": null, "text":null }
      }
    }`;
  };

  private static mockIndvDetails4 = (): string => {
    return `{
      "header": {
        "title": "Individual Verification - Test User-4",
        "logo": "https://static.wixstatic.com/media/18125b_1c55ed83ce124dafa588289340f80011~mv2.png",
        "logo_alt": "IDfy"
      },
      "body": {
          "full_name": "Test User-4",
          "package_name": "",
          "status": "capture_pending",
          "status_detail": "Capture details under review",
          "capture_id": "cpt-4",
          "out_of_office": { "active":false, "title": null, "text":null }
        }
    }`;
  };

  private static mockIndvDetails5 = (): string => {
    return `{
      "header": {
        "title": "Individual Verification - Test User-5",
        "logo": "https://static.wixstatic.com/media/18125b_1c55ed83ce124dafa588289340f80011~mv2.png",
        "logo_alt": "IDfy"
      },
      "body": {
          "full_name": "Test User-5",
          "package_name": "",
          "status": "initiated",
          "status_detail": "Capture initiated",
          "capture_id": "cpt-5",
          "out_of_office": { "active":false, "title": null, "text":null }
        }
    }`;
  };

  private static mockIndvDetails6 = (): string => {
    return `{
      "header": {
        "title": "Individual Verification - Test User-6",
        "logo": "https://static.wixstatic.com/media/18125b_1c55ed83ce124dafa588289340f80011~mv2.png",
        "logo_alt": "IDfy"
      },
      "body": {
          "full_name": "Test User-6",
          "package_name": "",
          "status": "in_progress",
          "status_detail": "capture in_progress",
          "capture_id": "cpt-6",
          "out_of_office": { "active":false, "title": null, "text":null }
        }
    }`;
  };

  private static mockIndvDetails7 = (): string => {
    return `{
      "header": {
        "title": "Individual Verification - Test User-7",
        "logo": "https://static.wixstatic.com/media/18125b_1c55ed83ce124dafa588289340f80011~mv2.png",
        "logo_alt": "IDfy"
      },
      "body": {
          "full_name": "Test User-7",
          "package_name": "",
          "status": "capture_pending",
          "status_detail": "",
          "capture_id": "cpt-7",
          "out_of_office": { "active":false, "title": null, "text":null }
        }
    }`;
  };

  private static mockIndvDetails8 = (): string => {
    return `{
      "header": {
        "title": "Individual Verification - Test User-1",
        "logo": "https://static.wixstatic.com/media/18125b_1c55ed83ce124dafa588289340f80011~mv2.png",
        "logo_alt": "IDfy"
      },
      "body": {
          "full_name": "Test User-1",
          "package_name": "",
          "status": "capture_pending",
          "status_detail": "Capture Pending",
          "capture_id": "av_skill_select",
          "out_of_office": { "active":false, "title": null, "text":null }
        },
        "theme_config": {
          "palette":{"primary":{"main":"#279a9e","light":"#52aeb1","dark":"#1b6b6e","contrastText":"#fff"},"secondary":{"main":"#ffffff","light":"#ffffff","dark":"","contrastText":"#000000"},"info":{"main":"#279a9e","light":"#52aeb1","dark":"","contrastText":""},"success":{"main":"#4caf50","light":"#6fbf73","dark":"","contrastText":"#000000"},"error":{"main":"#f44336","light":"","dark":"","contrastText":""},"warning":{"main":"#ff9800","light":"#ffb74d","dark":"#f57c00","contrastText":"#000000"}},
          "typography": {
            "fontFamily": {
              "primary": {
                "fontFace": "indieflower",
                "url": "https://fonts.gstatic.com/s/indieflower/v11/m8JVjfNVeKWVnh3QMuKkFcZVaUuH.woff2"
              },
              "secondary": {
                "fontFace": "fondamento",
                "url": "https://fonts.gstatic.com/s/fondamento/v10/4UaFrEJGsxNmFTPDnkaJ96_Z67txEwI.woff2"
              }
            }
          }
        }
    }`;
  };

  private static mockPageSequence1 = (): _CaptureSequenceNew => {
    return {
      page_sequence: [
        {
          page: PAGE_SEQ_INTRO,
          validations: [],
          hasConfig: false,
        },
        {
          page: PAGE_SEQ_CONSENT,
          validations: [],
          hasConfig: false,
        },
        {
          page: PAGE_SEQ_PERMISSIONS,
          validations: [],
          hasConfig: false,
        },
        {
          page: PAGE_SEQ_CAPTURE,
          validations: [],
          hasConfig: false,
        },
        {
          page: PAGE_SEQ_CAPTURE_PREREQUISITE,
          validations: [],
          hasConfig: false,
        },
        {
          page: PAGE_SEQ_AVKYC,
          validations: [],
          hasConfig: false,
        },
      ],
      validation_config: {},
      settings: {
        camera: false,
        location: false,
        file_upload: true,
        webrtc: false,
        blacklisted_browsers: ['XiaoMi/MiuiBrowser/'],
      },
    };
  };

  private static mockPageSequence2 = (): _CaptureSequenceNew => {
    return {
      page_sequence: [
        {
          page: PAGE_SEQ_INTRO,
          validations: ['location'],
          hasConfig: false,
        },
        {
          page: PAGE_SEQ_CONSENT,
          validations: ['location'],
          hasConfig: false,
        },
        {
          page: PAGE_SEQ_PERMISSIONS,
          validations: ['location'],
          hasConfig: false,
        },
        {
          page: PAGE_SEQ_CAPTURE,
          validations: ['location'],
          hasConfig: false,
        },
      ],
      validation_config: {
        location: {
          'task.type': 'validate',
          attr: 'location',
          criteria: [],
          type: 'client',
        },
      },
      settings: {
        camera: false,
        location: false,
        file_upload: true,
        webrtc: false,
        blacklisted_browsers: ['XiaoMi/MiuiBrowser/'],
      },
    };
  };

  private static mockPageSequence3 = (): _CapturePageSequence => {
    return {
      page_sequence: ['capture'],
      introduction: {
        title: '',
        text: '',
      },
      consent: {
        title: '',
        text: '',
      },
    };
  };

  private static mockPageSequence4 = (): _CapturePageSequence => {
    return {
      page_sequence: ['consent', 'capture'],
      introduction: {
        title: 'Important Instructions',
        text: "<p class='MuiTypography-root MuiTypography-body1'><p style='background-color: rgb(217, 228, 239); padding: 0.5em 0px 0.5em 1em; border-radius: 0.4em;'>Allow camera permissions for a smooth verification experience.</p><p>You will be asked to capture your address proof and to take a selfie. Your information will be used only for verification of your booking.</p><p style='margin: 0em;'>Please ensure that:</p><ul><li>Document images are readable</li><li>Selfie matches with the document</li></ul></p>",
      },
      consent: {
        title: 'Provide Consent',
        text: 'I, a Guest with XYZ Ltd., consent to Baldor Technologies Private Limited and XYZ Ltd. collecting my Driving Licence, Aadhaar number, or Voter I.D., and an image of my facial features, and storing and using the same for the purpose of granting privileged remote check-in at the XYZ Ltd. property post an Identity Verification. I understand that the submission of my Aadhaar number is not mandatory and that I may submit any of the following in its place: Driving License and Voter I.D.',
      },
    };
  };

  private static mockPageSequence5 = (): _CaptureSequenceNew => {
    return {
      page_sequence: [
        { page: 'introduction', validations: [], hasConfig: false },
        { page: 'consent', validations: [], hasConfig: false },
        { page: 'permissions', validations: [], hasConfig: false },
        { page: 'av_skill_select', validations: [], hasConfig: false },
        { page: 'vkyc.assisted_vkyc', validations: [], hasConfig: false },
      ],
      settings: {
        camera: false,
        location: false,
        file_upload: true,
        webrtc: false,
        blacklisted_browsers: ['XiaoMi/MiuiBrowser/'],
      },
      validation_config: {},
    };
  };

  private static mockCaptureConfig1 = (): any => {
    return {
      validations: [],
      data: {
        page: 'capture',
        config: {
          tasks: {
            'vkyc.assisted_vkyc': {
              key: CAPTURE_TASKS_ASSISTED_VKYC,
              request_uid: 'f41476d4-65e3-41e4-937e-af0c310ffe25',
              status: 'in_progress',
              task_type_key: CAPTURE_TASKS_ASSISTED_VKYC,
            },
            'capture.capture_video_activity': {
              task_type_key: CAPTURE_TASKS_SV_SERVICE,
              status: 'in_progress',
              request_uid: 'd6e7b94d-06bf-11ea-a076-a2a18de305e3',
              key: CAPTURE_TASKS_SV_SERVICE,
            },
          },
          templates: {
            1: {
              type: CAPTURE_TEMPLATES_CARD,
              title: 'Pan Details',
              detail: 'Please Enter Your PAN Number',
            },
            2: {
              type: CAPTURE_TEMPLATES_TEXT,
              document_type: 'ind_pan',
              fact_type_key: 'id_number',
              label: 'Pan Details',
              help_text: 'Enter your PAN number',
              mandatory: true,
              placeholder: 'Enter your pan number',
              regex: '^[A-Z]{3}[ABCFGHLJPT]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$',
            },
            3: {
              type: CAPTURE_TEMPLATES_CARD,
              title: 'Self VKYC',
            },
            4: {
              type: CAPTURE_TEMPLATES_BUTTON,
              mandatory: false,
              side: '',
              options: [],
              value: 'https://www.google.com',
              label: 'Capture',
            },
            5: {
              type: CAPTURE_TEMPLATES_TEXT,
              document_type: 'ind_aadhaar',
              fact_type_key: 'id_number',
              label: 'Aadhaar Details',
              help_text: '',
              mandatory: true,
              placeholder: 'Enter your aadhaar number',
              regex: '^[0-9]{12}$',
            },
            6: {
              type: CAPTURE_TEMPLATES_CARD,
              title: 'ID Proof',
              detail: 'Select valid ID Proof - Upload Allowed',
            },
            7: {
              type: CAPTURE_TEMPLATES_IMAGE,
              label: '',
              category_type: 'poi',
              mandatory: false,
              side: 'front',
              options: [],
              allow_upload: true,
              document_type_key: '',
              help_text: '',
            },
            8: {
              type: CAPTURE_TEMPLATES_IMAGE,
              label: '',
              category_type: 'poi',
              mandatory: false,
              side: 'back',
              options: [],
              allow_upload: true,
              document_type_key: '',
              help_text: '',
            },
            9: {
              type: CAPTURE_TEMPLATES_TEXT,
              document_type: 'ind_driving_license',
              fact_type_key: 'id_number',
              label: 'Driving License Details',
              help_text: 'Two Letter State Code/RTO#/Year/#',
              mandatory: false,
              placeholder: 'AANN-YYYYNNNNNNN',
              regex: '^[A-Z]{2}[0-9]{2}-[0-9]{4}[0-9]{7}$',
            },
            10: {
              type: CAPTURE_TEMPLATES_CARD,
              title: 'Driving License Details',
              detail: 'Please enter your DL#',
            },
            11: {
              type: CAPTURE_TEMPLATES_CARD,
              title: 'Selfie',
              detail: 'Take a selfie',
            },
            12: {
              type: CAPTURE_TEMPLATES_IMAGE,
              label: '',
              category_type: '',
              mandatory: true,
              side: 'front',
              options: [],
              allow_upload: false,
              document_type_key: 'selfie',
              help_text: '',
            },
            13: {
              type: CAPTURE_TEMPLATES_GROUP,
              title: 'Group Image Captures',
              detail: 'Please Upload',
            },
            14: {
              allow_upload: false,
              artifacts: [],
              category_type: null,
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 73,
              label: null,
              mandatory: true,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: CAPTURE_TASKS_ASSISTED_VKYC,
              task_type: CAPTURE_TASKS_ASSISTED_VKYC,
              title: 'Assisted VKYC',
              type: CAPTURE_TEMPLATES_BUTTON,
            },
            15: {
              allow_upload: false,
              artifacts: [],
              category_type: null,
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 73,
              label: null,
              mandatory: true,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: CAPTURE_TASKS_SV_SERVICE,
              task_type: CAPTURE_TASKS_SV_SERVICE,
              title: 'Assisted VKYC',
              type: CAPTURE_TEMPLATES_BUTTON,
            },
          },
          capture: [
            [
              {
                template_id: '13',
                tasks: [
                  {
                    task_key: '',
                    artifacts: [''],
                    task_type: CAPTURE_TASKS_GROUPED,
                    template_id: '11',
                    tasks: [
                      {
                        task_key: '',
                        artifacts: ['group_image_front'],
                        task_type: CAPTURE_TASKS_DATA_CAPTURE,
                        template_id: 7,
                      },
                      {
                        task_key: '',
                        artifacts: ['group_image_back'],
                        task_type: CAPTURE_TASKS_DATA_CAPTURE,
                        template_id: 8,
                      },
                      {
                        public_id: '',
                        artifacts: ['pan'],
                        task_type: CAPTURE_TASKS_DATA_CAPTURE,
                        template_id: 2,
                      },
                      {
                        public_id: '',
                        artifacts: ['aadhaar'],
                        task_type: CAPTURE_TASKS_DATA_CAPTURE,
                        template_id: 5,
                      },
                      {
                        public_id: '',
                        artifacts: ['imageFront'],
                        task_type: CAPTURE_TASKS_DATA_CAPTURE,
                        template_id: 7,
                      },
                      {
                        public_id: '',
                        artifacts: ['imageBack'],
                        task_type: CAPTURE_TASKS_DATA_CAPTURE,
                        template_id: 8,
                      },
                    ],
                  },
                ],
              },
              {
                task_key: CAPTURE_TASKS_ASSISTED_VKYC,
                task_type: CAPTURE_TASKS_ASSISTED_VKYC,
                template_id: 14,
                // "template_id": 15,
                // "task_type": CAPTURE_TASKS_SELF_VKYC,
                // "task_key": CAPTURE_TASKS_SELF_VKYC
              },
            ],
          ],
          allowed_poa_documents: [
            'ind_aadhaar',
            'ind_driving_license',
            'ind_voter',
            'ind_passport',
          ],
          allowed_poi_documents: ['ind_pan'],
          artifacts: {
            imageFront: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url:
                'https://e1dc150ac707.storage.pg.idfystaging.com/8b1cd793-fb4c-48ce-9702-49d167ecbf2a?Expires=1632731310&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=zt8PP%2Bi31b8hjOA92vEJdj3FWw6Tjhk3%2FcUkXbaA9nA7zHHjGdzYF1iVOvR46CakXV9R%2FszcHAPLrP4n3fMVRKnDen3g13dko3JVHfcCUomC2KF2jcFr3Wq0Xtye91%2FTDdUA0UJfA7h%2BKqT25rYzLzQbiIwLtIaq8mG7CjOk8cxs0M7pm6gy8UfHQcs%2FPbtI%2FHqs8vl%2BgXIc7D8M9R3fLA9gUeenq5qzdrt1qQgkq3EU%2FEzIWDkOeWlaZhrfOFsaR8Jj2v7J87RcNPThbrT0UQmCp0VfHqd7YchZO58Patd9dBLyuQTD%2FpAmZBKAn2kZvHrQKTpXCVIQIUG8czz1Zw%3D%3D',
              value:
                'https://e1dc150ac707.storage.pg.idfystaging.com/8b1cd793-fb4c-48ce-9702-49d167ecbf2a?Expires=1632731310&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=CFBjBTFOKFw5QogNiNHlSyhhYP02wynF7ECPJbKyQ4byLnw5eMS6kogdG2e%2FwY6O4amAKF6OhpNOI4ScKxAg4NAhbzFcsu6t46qPCQV7N32YgZQLdM2bkaoHHCtig5wKpo6GW4y6QocozfOwQPRXs6QUgzwTFoTyp74W%2B0CyMfxFk%2FOe745rT0rFipVVbk6HD4%2FEI4wUBV4HkgybzPmV%2FjbN9e8okT%2BHwkMp4l148lmtVI43M3inn3QVLCpvCu33K8GlbSRxN37J0NuZsqlSkBOas1Jy%2BOuGWsWHNdlyDfd%2F%2BbX6ZtgioEquszK9ZaTI8f3KgcFnrOyjbIoDidleog%3D%3D',
              present: false,
            },
            imageBack: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: '',
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url:
                'https://e1dc150ac707.storage.pg.idfystaging.com/8b1cd793-fb4c-48ce-9702-49d167ecbf2a?Expires=1632731310&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=zt8PP%2Bi31b8hjOA92vEJdj3FWw6Tjhk3%2FcUkXbaA9nA7zHHjGdzYF1iVOvR46CakXV9R%2FszcHAPLrP4n3fMVRKnDen3g13dko3JVHfcCUomC2KF2jcFr3Wq0Xtye91%2FTDdUA0UJfA7h%2BKqT25rYzLzQbiIwLtIaq8mG7CjOk8cxs0M7pm6gy8UfHQcs%2FPbtI%2FHqs8vl%2BgXIc7D8M9R3fLA9gUeenq5qzdrt1qQgkq3EU%2FEzIWDkOeWlaZhrfOFsaR8Jj2v7J87RcNPThbrT0UQmCp0VfHqd7YchZO58Patd9dBLyuQTD%2FpAmZBKAn2kZvHrQKTpXCVIQIUG8czz1Zw%3D%3D',
              value:
                'https://e1dc150ac707.storage.pg.idfystaging.com/8b1cd793-fb4c-48ce-9702-49d167ecbf2a?Expires=1632731310&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=CFBjBTFOKFw5QogNiNHlSyhhYP02wynF7ECPJbKyQ4byLnw5eMS6kogdG2e%2FwY6O4amAKF6OhpNOI4ScKxAg4NAhbzFcsu6t46qPCQV7N32YgZQLdM2bkaoHHCtig5wKpo6GW4y6QocozfOwQPRXs6QUgzwTFoTyp74W%2B0CyMfxFk%2FOe745rT0rFipVVbk6HD4%2FEI4wUBV4HkgybzPmV%2FjbN9e8okT%2BHwkMp4l148lmtVI43M3inn3QVLCpvCu33K8GlbSRxN37J0NuZsqlSkBOas1Jy%2BOuGWsWHNdlyDfd%2F%2BbX6ZtgioEquszK9ZaTI8f3KgcFnrOyjbIoDidleog%3D%3D',
              present: false,
            },
            pan: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: 'Pan',
              type: CAPTURE_TEMPLATES_TEXT,
              value: '',
              present: false,
            },
            aadhaar: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: '',
              type: CAPTURE_TEMPLATES_TEXT,
              value: '',
              present: false,
            },
            drv_lic: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: '',
              type: CAPTURE_TEMPLATES_TEXT,
              value: '',
              present: false,
            },
            imageSelfie: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: '',
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url:
                'https://e1dc150ac707.storage.pg.idfystaging.com/8b1cd793-fb4c-48ce-9702-49d167ecbf2a?Expires=1632731310&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=zt8PP%2Bi31b8hjOA92vEJdj3FWw6Tjhk3%2FcUkXbaA9nA7zHHjGdzYF1iVOvR46CakXV9R%2FszcHAPLrP4n3fMVRKnDen3g13dko3JVHfcCUomC2KF2jcFr3Wq0Xtye91%2FTDdUA0UJfA7h%2BKqT25rYzLzQbiIwLtIaq8mG7CjOk8cxs0M7pm6gy8UfHQcs%2FPbtI%2FHqs8vl%2BgXIc7D8M9R3fLA9gUeenq5qzdrt1qQgkq3EU%2FEzIWDkOeWlaZhrfOFsaR8Jj2v7J87RcNPThbrT0UQmCp0VfHqd7YchZO58Patd9dBLyuQTD%2FpAmZBKAn2kZvHrQKTpXCVIQIUG8czz1Zw%3D%3D',
              value:
                'https://e1dc150ac707.storage.pg.idfystaging.com/8b1cd793-fb4c-48ce-9702-49d167ecbf2a?Expires=1632731310&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=CFBjBTFOKFw5QogNiNHlSyhhYP02wynF7ECPJbKyQ4byLnw5eMS6kogdG2e%2FwY6O4amAKF6OhpNOI4ScKxAg4NAhbzFcsu6t46qPCQV7N32YgZQLdM2bkaoHHCtig5wKpo6GW4y6QocozfOwQPRXs6QUgzwTFoTyp74W%2B0CyMfxFk%2FOe745rT0rFipVVbk6HD4%2FEI4wUBV4HkgybzPmV%2FjbN9e8okT%2BHwkMp4l148lmtVI43M3inn3QVLCpvCu33K8GlbSRxN37J0NuZsqlSkBOas1Jy%2BOuGWsWHNdlyDfd%2F%2BbX6ZtgioEquszK9ZaTI8f3KgcFnrOyjbIoDidleog%3D%3D',
              present: false,
            },
            group_image_back: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: 'back',
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url:
                'https://e1dc150ac707.storage.pg.idfystaging.com/8b1cd793-fb4c-48ce-9702-49d167ecbf2a?Expires=1632731310&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=zt8PP%2Bi31b8hjOA92vEJdj3FWw6Tjhk3%2FcUkXbaA9nA7zHHjGdzYF1iVOvR46CakXV9R%2FszcHAPLrP4n3fMVRKnDen3g13dko3JVHfcCUomC2KF2jcFr3Wq0Xtye91%2FTDdUA0UJfA7h%2BKqT25rYzLzQbiIwLtIaq8mG7CjOk8cxs0M7pm6gy8UfHQcs%2FPbtI%2FHqs8vl%2BgXIc7D8M9R3fLA9gUeenq5qzdrt1qQgkq3EU%2FEzIWDkOeWlaZhrfOFsaR8Jj2v7J87RcNPThbrT0UQmCp0VfHqd7YchZO58Patd9dBLyuQTD%2FpAmZBKAn2kZvHrQKTpXCVIQIUG8czz1Zw%3D%3D',
              value:
                'https://e1dc150ac707.storage.pg.idfystaging.com/8b1cd793-fb4c-48ce-9702-49d167ecbf2a?Expires=1632731310&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=CFBjBTFOKFw5QogNiNHlSyhhYP02wynF7ECPJbKyQ4byLnw5eMS6kogdG2e%2FwY6O4amAKF6OhpNOI4ScKxAg4NAhbzFcsu6t46qPCQV7N32YgZQLdM2bkaoHHCtig5wKpo6GW4y6QocozfOwQPRXs6QUgzwTFoTyp74W%2B0CyMfxFk%2FOe745rT0rFipVVbk6HD4%2FEI4wUBV4HkgybzPmV%2FjbN9e8okT%2BHwkMp4l148lmtVI43M3inn3QVLCpvCu33K8GlbSRxN37J0NuZsqlSkBOas1Jy%2BOuGWsWHNdlyDfd%2F%2BbX6ZtgioEquszK9ZaTI8f3KgcFnrOyjbIoDidleog%3D%3D',
              present: false,
            },
            group_image_front: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: 'front',
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url:
                'https://e1dc150ac707.storage.pg.idfystaging.com/8b1cd793-fb4c-48ce-9702-49d167ecbf2a?Expires=1632731310&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=zt8PP%2Bi31b8hjOA92vEJdj3FWw6Tjhk3%2FcUkXbaA9nA7zHHjGdzYF1iVOvR46CakXV9R%2FszcHAPLrP4n3fMVRKnDen3g13dko3JVHfcCUomC2KF2jcFr3Wq0Xtye91%2FTDdUA0UJfA7h%2BKqT25rYzLzQbiIwLtIaq8mG7CjOk8cxs0M7pm6gy8UfHQcs%2FPbtI%2FHqs8vl%2BgXIc7D8M9R3fLA9gUeenq5qzdrt1qQgkq3EU%2FEzIWDkOeWlaZhrfOFsaR8Jj2v7J87RcNPThbrT0UQmCp0VfHqd7YchZO58Patd9dBLyuQTD%2FpAmZBKAn2kZvHrQKTpXCVIQIUG8czz1Zw%3D%3D',
              value:
                'https://e1dc150ac707.storage.pg.idfystaging.com/8b1cd793-fb4c-48ce-9702-49d167ecbf2a?Expires=1632731310&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=CFBjBTFOKFw5QogNiNHlSyhhYP02wynF7ECPJbKyQ4byLnw5eMS6kogdG2e%2FwY6O4amAKF6OhpNOI4ScKxAg4NAhbzFcsu6t46qPCQV7N32YgZQLdM2bkaoHHCtig5wKpo6GW4y6QocozfOwQPRXs6QUgzwTFoTyp74W%2B0CyMfxFk%2FOe745rT0rFipVVbk6HD4%2FEI4wUBV4HkgybzPmV%2FjbN9e8okT%2BHwkMp4l148lmtVI43M3inn3QVLCpvCu33K8GlbSRxN37J0NuZsqlSkBOas1Jy%2BOuGWsWHNdlyDfd%2F%2BbX6ZtgioEquszK9ZaTI8f3KgcFnrOyjbIoDidleog%3D%3D',
              present: false,
            },
            group_pan: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: '',
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url:
                'https://e1dc150ac707.storage.pg.idfystaging.com/8b1cd793-fb4c-48ce-9702-49d167ecbf2a?Expires=1632731310&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=zt8PP%2Bi31b8hjOA92vEJdj3FWw6Tjhk3%2FcUkXbaA9nA7zHHjGdzYF1iVOvR46CakXV9R%2FszcHAPLrP4n3fMVRKnDen3g13dko3JVHfcCUomC2KF2jcFr3Wq0Xtye91%2FTDdUA0UJfA7h%2BKqT25rYzLzQbiIwLtIaq8mG7CjOk8cxs0M7pm6gy8UfHQcs%2FPbtI%2FHqs8vl%2BgXIc7D8M9R3fLA9gUeenq5qzdrt1qQgkq3EU%2FEzIWDkOeWlaZhrfOFsaR8Jj2v7J87RcNPThbrT0UQmCp0VfHqd7YchZO58Patd9dBLyuQTD%2FpAmZBKAn2kZvHrQKTpXCVIQIUG8czz1Zw%3D%3D',
              value:
                'https://e1dc150ac707.storage.pg.idfystaging.com/8b1cd793-fb4c-48ce-9702-49d167ecbf2a?Expires=1632731310&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=CFBjBTFOKFw5QogNiNHlSyhhYP02wynF7ECPJbKyQ4byLnw5eMS6kogdG2e%2FwY6O4amAKF6OhpNOI4ScKxAg4NAhbzFcsu6t46qPCQV7N32YgZQLdM2bkaoHHCtig5wKpo6GW4y6QocozfOwQPRXs6QUgzwTFoTyp74W%2B0CyMfxFk%2FOe745rT0rFipVVbk6HD4%2FEI4wUBV4HkgybzPmV%2FjbN9e8okT%2BHwkMp4l148lmtVI43M3inn3QVLCpvCu33K8GlbSRxN37J0NuZsqlSkBOas1Jy%2BOuGWsWHNdlyDfd%2F%2BbX6ZtgioEquszK9ZaTI8f3KgcFnrOyjbIoDidleog%3D%3D',
              present: false,
            },
          },
        },
        avkyc_introduction: {
          title: 'Video-KYC Page',
          text: "<p class='MuiTypography-root MuiTypography-body1'>Please be prepared with :<p class='MuiTypography-root MuiTypography-body1'><ul><li>Pan Card - Physical copy</li><li>A paper and pen</li></ul></p></p><p class='MuiTypography-root MuiTypography-body1'>Ensure you are in a :<p class='MuiTypography-root MuiTypography-body1'><ul><li>Good Network</li><li>Well Lit Surroundings</li></ul></p></p>",
        },
      },
    };
  };

  private static mockSelfVkyc = (): any => {
    return {
      data: {
        config: {
          artifacts: {},
          tasks: {
            'capture.capture_video_activity': {
              error: null,
              key: 'capture.capture_video_activity',
              request_uid: 'c8f7e9a1-1fe1-42dd-b4fe-2ab27e5b178f',
              status: 'in_progress',
              task_type_key: 'capture.capture_video_activity',
              validation_error: null,
            },
          },
          templates: {
            '15095': {
              allow_upload: false,
              artifacts: [],
              category_type: null,
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 15095,
              label: 'Start Self Video Service',
              mandatory: true,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: 'capture.capture_video_activity',
              task_type: 'capture.capture_video_activity',
              title: 'Assisted Video KYC',
              type: 'button',
            },
            '17123': {
              allow_upload: false,
              artifacts: [],
              category_type: null,
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 17123,
              label: null,
              mandatory: false,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: null,
              task_type: null,
              title: 'Self Video KYC',
              type: 'card',
            },
          },
          allowed_poa_documents: [],
          allowed_poi_documents: [],
          capture: [
            [
              {
                tasks: [
                  {
                    task_key: 'capture.capture_video_activity',
                    task_type: 'capture.capture_video_activity',
                    template_id: 15095,
                  },
                ],
                template_id: 17123,
              },
            ],
          ],
          page_sequence: [{ page: 'capture', validations: [] }],
          validation_config: {},
        },
        page: 'capture',
      },
      validations: [],
    };
  };

  private static mockSelfVkycDecoupled = (): any => {
    return {
      data: {
        config: {
          artifacts: {
            'nil.ind_pan.nil.1.nil': {
              category: null,
              document_type_key: 'ind_pan',
              error: null,
              fact_type_key: null,
              key: 'nil.ind_pan.nil.1.nil',
              metadata: {},
              present: false,
              side: null,
              source: 1,
              tags: null,
              type: 'document',
              upload_url:
                'https://e1dc150ac707.storage.pg.idfystaging.com/87a54a48-1320-4f6d-9a77-a654571490b6?Expires=1628163825&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=LweQdTzql%2Bf0cZhR1BkpqKL8kfSuzw5X78w8Ve6yF7p%2BRDO1lESX%2BD3NrExX5nuPWY67F1aWj%2FtgjZfoBxFo6Spdqgz2mB5TWNqHUi5tks%2FT%2FPpE6gzIxKohCkhTmSZt67v7RvSK%2FTJ49i5QundgthPcfnYaPzz2klkXSs3LBzgPOvm%2Fd15ml8p5CL7Uf5ZTqeTpIR0jUFZyFR4exRtjTitqZZVRHZFi6Qb7mgmu%2B8yYUO9%2BM6noTFwQAyH4tNOXzctrlzfYAAv7RA0u%2Fo0hWg6vZdpRzosqwcfswWEm0FYqoG2rT3map1jIzh9ObeVWcAF7o1lbo8LxU6TbuJkcSg%3D%3D',
              value:
                'https://e1dc150ac707.storage.pg.idfystaging.com/87a54a48-1320-4f6d-9a77-a654571490b6?Expires=1628163825&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=T4sizNnGPj3V%2Fu2uyf%2FfH8jJxF7oFsr2yxDdW0omMnzZnsjEeBhsSMAZ%2BU1nygZzaxTD2hqy1YER53XZ%2F1uf82ogTKsBViWKiTIFpaEUyyMnXh1NrIt6CUisCi7Qtgmxy9EhBrhXAB%2FdEgjYdRqitcE%2BEztaei5XQCXQ4Wr3VS8Wad4L%2FXPxvOUx%2B7kWQbmry8nZVwspDwypwe1ZDLhFFSY7hoxV7MzjMhPIBJiFP2gQIQReeaBFSuyGHE3TB08doVYSyxAuibCKBlcoNNCEC53ZDW%2BDk1CpzG4srTh2T0GD7WPqwMdfODw8T1v9IyCmxzlw%2Fq8f91zQKUg%2F1fmw3w%3D%3D',
            },
          },
          tasks: {},
          templates: {
            '16565': {
              allow_upload: false,
              artifacts: ['nil.ind_pan.nil.1.nil'],
              category_type: null,
              dependencies: [],
              detail: null,
              document_type_key: 'ind_pan',
              fact_type_key: null,
              help_text: null,
              id: 16565,
              label: 'PAN Capture',
              mandatory: false,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: 'key19',
              task_type: 'data_capture.data_capture',
              title: null,
              type: 'document',
              capture_mechanisms: {
                digilocker: {
                  details: [],
                  sources: [],
                },
              },
            },
            '16583': {
              allow_upload: false,
              artifacts: [],
              category_type: null,
              dependencies: [],
              detail: 'Capture PAN',
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 16583,
              label: null,
              mandatory: false,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: null,
              task_type: null,
              title: 'PAN Card',
              type: 'card',
            },
          },
          allowed_poa_documents: [],
          allowed_poi_documents: [],
          capture: [
            [
              {
                tasks: [
                  {
                    artifacts: ['nil.ind_pan.nil.1.nil'],
                    task_key: 'key19',
                    task_type: 'data_capture.data_capture',
                    template_id: 16565,
                  },
                ],
                template_id: 16583,
              },
            ],
          ],
          page_sequence: [
            // { page: 'introduction', validations: [] },
            // { page: 'consent', validations: [] },
            // { page: 'permissions', validations: [] },
            { page: 'capture', validations: [] },
            // { page: 'capture.capture_video_activity', validations: [] },
          ],
          permissions: {
            capture: [
              {
                attr: 'camera',
                template: {
                  help_text: '',
                  label: 'Please give access to camera',
                  mandatory: true,
                  type: 'span',
                },
              },
              {
                attr: 'microphone',
                template: {
                  help_text: '',
                  label: 'Please give access to microphone',
                  mandatory: true,
                  type: '',
                },
              },
            ],
            title: 'Ask Permissions',
          },
          validation_config: {},
        },
        page: 'capture',
      },
      validations: [],
    };
  };

  private static mockCaptureConfig2 = (): any => {
    return {
      validations: [],
      data: {
        page: 'capture',
        config: {
          templates: {
            1: {
              type: CAPTURE_TEMPLATES_CARD,
              title: 'Pan Details',
              detail: 'Please Enter Your PAN Number',
            },
            2: {
              type: CAPTURE_TEMPLATES_TEXT,
              document_type: 'ind_pan',
              fact_type_key: 'id_number',
              label: 'Pan Details',
              help_text: 'Enter your PAN number',
              mandatory: true,
              placeholder: 'Enter your pan number',
              regex: '^[A-Z]{3}[ABCFGHLJPT]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$',
            },
            3: {
              type: CAPTURE_TEMPLATES_CARD,
              title: 'Self VKYC',
            },
            4: {
              type: CAPTURE_TEMPLATES_BUTTON,
              mandatory: false,
              side: '',
              options: [],
              value: 'https://www.google.com',
              label: 'Capture',
            },
            5: {
              type: CAPTURE_TEMPLATES_TEXT,
              document_type: 'ind_aadhaar',
              fact_type_key: 'id_number',
              label: 'Aadhaar Details',
              help_text: '',
              mandatory: true,
              placeholder: 'Enter your aadhaar number',
              regex: '^[0-9]{12}$',
            },
            6: {
              type: CAPTURE_TEMPLATES_CARD,
              title: 'ID Proof',
              detail: 'Select valid ID Proof - Upload Allowed',
            },
            7: {
              type: CAPTURE_TEMPLATES_IMAGE,
              label: '',
              category_type: 'poa',
              mandatory: false,
              side: 'front',
              options: [],
              allow_upload: true,
              document_type_key: '',
              help_text: '',
            },
            8: {
              type: CAPTURE_TEMPLATES_IMAGE,
              label: '',
              category_type: 'poi',
              mandatory: false,
              side: 'back',
              options: [],
              allow_upload: true,
              document_type_key: '',
              help_text: '',
            },
            9: {
              type: CAPTURE_TEMPLATES_TEXT,
              document_type: 'ind_driving_license',
              fact_type_key: 'id_number',
              label: 'Driving License Details',
              help_text: 'Two Letter State Code/RTO#/Year/#',
              mandatory: false,
              placeholder: 'AANN-YYYYNNNNNNN',
              regex: '^[A-Z]{2}[0-9]{2}-[0-9]{4}[0-9]{7}$',
            },
            10: {
              type: CAPTURE_TEMPLATES_CARD,
              title: 'Driving License Details',
              detail: 'Please enter your DL#',
            },
            11: {
              type: CAPTURE_TEMPLATES_CARD,
              title: 'Selfie',
              detail: 'Take a selfie',
            },
            12: {
              type: CAPTURE_TEMPLATES_IMAGE,
              label: '',
              category_type: '',
              mandatory: true,
              side: 'front',
              options: [],
              allow_upload: false,
              document_type_key: 'selfie',
              help_text: '',
            },
            '448': {
              allow_upload: false,
              artifacts: [],
              category_type: null,
              dependencies: [],
              detail: 'Click on Button to start self video service',
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 448,
              label: null,
              mandatory: true,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: CAPTURE_TASKS_SV_SERVICE,
              task_type: CAPTURE_TASKS_SV_SERVICE,
              title: 'Self Video KYC',
              type: CAPTURE_TEMPLATES_BUTTON,
            },
          },
          capture: [
            [
              {
                public_id: '',
                artifacts: [],
                template_id: 1,
                tasks: [
                  {
                    public_id: '',
                    artifacts: ['pan'],
                    task_type: CAPTURE_TASKS_DATA_CAPTURE,
                    template_id: 2,
                  },
                  {
                    public_id: '',
                    artifacts: ['aadhaar'],
                    task_type: CAPTURE_TASKS_DATA_CAPTURE,
                    template_id: 5,
                  },
                ],
              },
              {
                public_id: '',
                artifacts: [],
                template_id: 3,
                tasks: [
                  {
                    public_id: '',
                    artifacts: [],
                    task_type: CAPTURE_TASKS_DATA_CAPTURE,
                    template_id: 4,
                  },
                ],
              },
              {
                public_id: '',
                artifacts: [],
                template_id: 6,
                tasks: [
                  {
                    public_id: '',
                    artifacts: ['imageFront'],
                    task_type: CAPTURE_TASKS_DATA_CAPTURE,
                    template_id: 7,
                  },
                  {
                    public_id: '',
                    artifacts: ['imageBack'],
                    task_type: CAPTURE_TASKS_DATA_CAPTURE,
                    template_id: 8,
                  },
                ],
              },
            ],
            [
              {
                public_id: '',
                artifacts: [],
                template_id: 10,
                tasks: [
                  {
                    public_id: '',
                    artifacts: ['drv_lic'],
                    task_type: CAPTURE_TASKS_DATA_CAPTURE,
                    template_id: 9,
                  },
                ],
              },
              {
                public_id: '',
                artifacts: [],
                template_id: 11,
                tasks: [
                  {
                    public_id: '',
                    artifacts: ['imageSelfie'],
                    task_type: CAPTURE_TASKS_DATA_CAPTURE,
                    template_id: 12,
                  },
                ],
              },
              {
                task_key: CAPTURE_TASKS_SV_SERVICE,
                task_type: CAPTURE_TASKS_SV_SERVICE,
                template_id: 448,
              },
            ],
          ],
          allowed_poa_documents: [
            'ind_aadhaar',
            'ind_driving_license',
            'ind_voter',
            'ind_passport',
          ],
          allowed_poi_documents: ['ind_pan'],
          artifacts: {
            imageFront: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url: 'upload_url',
              value: '',
              present: false,
            },
            imageBack: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: '',
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url: 'upload_url',
              value: '',
              present: false,
            },
            pan: {
              category_type: '',
              document_type_key: 'pan',
              error: '',
              fact_type_key: '123',
              message: 'pan Details',
              type: CAPTURE_TEMPLATES_TEXT,
              value: '',
              present: false,
            },
            aadhaar: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: 'aadhar details',
              type: CAPTURE_TEMPLATES_TEXT,
              value: '',
              present: false,
            },
            drv_lic: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: '',
              type: CAPTURE_TEMPLATES_TEXT,
              value: '',
              present: false,
            },
            imageSelfie: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: '',
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url: 'upload_url',
              value: '',
              present: false,
            },
          },
          tasks: {
            'capture.capture_video_activity': {
              key: CAPTURE_TASKS_SV_SERVICE,
              request_uid:
                'https://video-kyc.idfy.com/video_kycs/829f5d1c-5f95-11ea-9cd3-929acd68462a',
              status: 'pending',
              task_type_key: CAPTURE_TASKS_SV_SERVICE,
            },
          },
        },
      },
    };
  };

  private static mockCaptureConfig3 = (): any => {
    return {
      validations: [],
      data: {
        page: 'capture',
        config: {
          templates: {
            1: {
              type: CAPTURE_TEMPLATES_CARD,
              title: 'Text Field Combinations',
              detail: 'Please Enter Your Id Numbers',
            },
            2: {
              type: CAPTURE_TEMPLATES_TEXT,
              document_type: 'ind_pan',
              fact_type_key: 'id_number',
              label: 'Pan Details',
              help_text: 'Enter your PAN number (regex, mandatory)',
              mandatory: true,
              placeholder: 'Enter your pan number',
              regex: '^[A-Z]{3}[ABCFGHLJPT]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$',
            },
            3: {
              type: CAPTURE_TEMPLATES_TEXT,
              document_type: 'ind_passport',
              fact_type_key: 'id_number',
              label: 'Passport Details',
              help_text: 'Enter your passport number (no regex, mandatory)',
              mandatory: true,
              placeholder: '',
              regex: '',
            },
            5: {
              type: CAPTURE_TEMPLATES_TEXT,
              document_type: 'ind_aadhaar',
              fact_type_key: 'id_number',
              label: 'Aadhaar Details',
              help_text: 'Enter your aadhaar number (regex, optional)',
              mandatory: false,
              placeholder: '',
              regex: '^[0-9]{12}$',
            },
            6: {
              type: CAPTURE_TEMPLATES_CARD,
              title: 'ID Proof',
              detail: 'Select valid ID Proof - Upload Allowed',
            },
            7: {
              type: CAPTURE_TEMPLATES_IMAGE,
              label: '',
              category_type: 'poa',
              mandatory: false,
              side: 'front',
              options: [],
              allow_upload: true,
              document_type_key: '',
              help_text: '',
            },
            8: {
              type: CAPTURE_TEMPLATES_IMAGE,
              label: '',
              category_type: 'poi',
              mandatory: false,
              side: 'back',
              options: [],
              allow_upload: true,
              document_type_key: '',
              help_text: '',
            },
            9: {
              type: CAPTURE_TEMPLATES_TEXT,
              document_type: 'ind_driving_license',
              fact_type_key: 'id_number',
              label: 'Driving License Details',
              help_text: 'Two Letter State Code/RTO#/Year/#',
              mandatory: false,
              placeholder: 'AANN-YYYYNNNNNNN',
              regex: '^[A-Z]{2}[0-9]{2}-[0-9]{4}[0-9]{7}$',
            },
            10: {
              type: CAPTURE_TEMPLATES_CARD,
              title: 'Driving License Details',
              detail: 'Please enter your DL#',
            },
            11: {
              type: CAPTURE_TEMPLATES_CARD,
              title: 'Selfie',
              detail: 'Take a selfie',
            },
            12: {
              type: CAPTURE_TEMPLATES_IMAGE,
              label: '',
              category_type: '',
              mandatory: true,
              side: 'front',
              options: [],
              allow_upload: false,
              document_type_key: 'selfie',
              help_text: '',
            },
          },
          capture: [
            [
              {
                public_id: '',
                artifacts: [],
                template_id: 1,
                tasks: [
                  {
                    public_id: '',
                    artifacts: ['pan'],
                    task_type: CAPTURE_TASKS_DATA_CAPTURE,
                    template_id: 2,
                  },
                  {
                    public_id: '',
                    artifacts: ['passport'],
                    task_type: CAPTURE_TASKS_DATA_CAPTURE,
                    template_id: 3,
                  },
                  {
                    public_id: '',
                    artifacts: ['aadhaar'],
                    task_type: CAPTURE_TASKS_DATA_CAPTURE,
                    template_id: 5,
                  },
                ],
              },
              {
                public_id: '',
                artifacts: [],
                template_id: 6,
                tasks: [
                  {
                    public_id: '',
                    artifacts: ['imageFront'],
                    task_type: CAPTURE_TASKS_DATA_CAPTURE,
                    template_id: 7,
                  },
                  {
                    public_id: '',
                    artifacts: ['imageBack'],
                    task_type: CAPTURE_TASKS_DATA_CAPTURE,
                    template_id: 8,
                  },
                ],
              },
            ],
            [
              {
                public_id: '',
                artifacts: [],
                template_id: 10,
                tasks: [
                  {
                    public_id: '',
                    artifacts: ['drv_lic'],
                    task_type: CAPTURE_TASKS_DATA_CAPTURE,
                    template_id: 9,
                  },
                ],
              },
              {
                public_id: '',
                artifacts: [],
                template_id: 11,
                tasks: [
                  {
                    public_id: '',
                    artifacts: ['imageSelfie'],
                    task_type: CAPTURE_TASKS_DATA_CAPTURE,
                    template_id: 12,
                  },
                ],
              },
            ],
          ],
          allowed_poa_documents: [
            'ind_aadhaar',
            'ind_driving_license',
            'ind_voter',
            'ind_passport',
          ],
          allowed_poi_documents: ['ind_pan'],
          artifacts: {
            imageFront: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url: 'upload_url',
              value: '',
              present: false,
            },
            imageBack: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: '',
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url: 'upload_url',
              value: '',
              present: false,
            },
            pan: {
              category_type: '',
              document_type_key: 'pan',
              error: '',
              fact_type_key: '123',
              message: 'pan Details',
              type: CAPTURE_TEMPLATES_TEXT,
              value: '',
              present: false,
            },
            aadhaar: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: 'aadhar details',
              type: CAPTURE_TEMPLATES_TEXT,
              value: '',
              present: false,
            },
            passport: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: 'passport details',
              type: CAPTURE_TEMPLATES_TEXT,
              value: '',
              present: false,
            },
            drv_lic: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: '',
              type: CAPTURE_TEMPLATES_TEXT,
              value: '',
              present: false,
            },
            imageSelfie: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: '',
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url: 'upload_url',
              value: '',
              present: false,
            },
          },
        },
      },
    };
  };

  private static mockCaptureConfig4 = (): any => {
    return {
      validations: [],
      data: {
        page: 'capture',
        config: {
          tasks: {
            'vkyc.assisted_vkyc': {
              key: CAPTURE_TASKS_ASSISTED_VKYC,
              request_uid: 'f41476d4-65e3-41e4-937e-af0c310ffe25',
              status: 'in_progress',
              task_type_key: CAPTURE_TASKS_ASSISTED_VKYC,
            },
            'capture.capture_video_activity': {
              task_type_key: CAPTURE_TASKS_SV_SERVICE,
              status: 'in_progress',
              request_uid: 'd6e7b94d-06bf-11ea-a076-a2a18de305e3',
              key: CAPTURE_TASKS_SV_SERVICE,
            },
          },
          templates: {
            1: {
              type: CAPTURE_TEMPLATES_CARD,
              title: 'Pan Details',
              detail: 'Please Enter Your PAN Number',
            },
            2: {
              type: CAPTURE_TEMPLATES_TEXT,
              document_type: 'ind_pan',
              fact_type_key: 'id_number',
              label: 'Pan Details',
              help_text: 'Enter your PAN number',
              mandatory: true,
              placeholder: 'Enter your pan number',
              regex: '^[A-Z]{3}[ABCFGHLJPT]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$',
            },
            3: {
              type: CAPTURE_TEMPLATES_CARD,
              title: 'Self VKYC',
            },
            4: {
              type: CAPTURE_TEMPLATES_BUTTON,
              mandatory: false,
              side: '',
              options: [],
              value: 'https://www.google.com',
              label: 'Capture',
            },
            5: {
              type: CAPTURE_TEMPLATES_TEXT,
              document_type: 'ind_aadhaar',
              fact_type_key: 'id_number',
              label: 'Aadhaar Details',
              help_text: '',
              mandatory: true,
              placeholder: 'Enter your aadhaar number',
              regex: '^[0-9]{12}$',
            },
            6: {
              type: CAPTURE_TEMPLATES_CARD,
              title: 'ID Proof',
              detail: 'Select valid ID Proof - Upload Allowed',
            },
            7: {
              type: CAPTURE_TEMPLATES_IMAGE,
              label: '',
              category_type: 'poi',
              mandatory: false,
              side: 'front',
              options: [],
              allow_upload: true,
              document_type_key: '',
              help_text: '',
            },
            8: {
              type: CAPTURE_TEMPLATES_IMAGE,
              label: '',
              category_type: 'poi',
              mandatory: false,
              side: 'back',
              options: [],
              allow_upload: true,
              document_type_key: '',
              help_text: '',
            },
            9: {
              type: CAPTURE_TEMPLATES_TEXT,
              document_type: 'ind_driving_license',
              fact_type_key: 'id_number',
              label: 'Driving License Details',
              help_text: 'Two Letter State Code/RTO#/Year/#',
              mandatory: false,
              placeholder: 'AANN-YYYYNNNNNNN',
              regex: '^[A-Z]{2}[0-9]{2}-[0-9]{4}[0-9]{7}$',
            },
            10: {
              type: CAPTURE_TEMPLATES_CARD,
              title: 'Driving License Details',
              detail: 'Please enter your DL#',
            },
            11: {
              type: CAPTURE_TEMPLATES_CARD,
              title: 'Selfie',
              detail: 'Take a selfie',
            },
            12: {
              type: CAPTURE_TEMPLATES_IMAGE,
              label: '',
              category_type: '',
              mandatory: true,
              side: 'front',
              options: [],
              allow_upload: false,
              document_type_key: 'selfie',
              help_text: '',
            },
            13: {
              type: CAPTURE_TEMPLATES_GROUP,
              title: 'Group Image Captures',
              detail: 'Please Upload',
            },
          },
          capture: [
            [
              {
                template_id: '13',
                tasks: [
                  {
                    task_key: '',
                    artifacts: [''],
                    task_type: CAPTURE_TASKS_GROUPED,
                    template_id: '11',
                    tasks: [
                      {
                        task_key: '',
                        artifacts: ['group_image_front'],
                        task_type: CAPTURE_TASKS_DATA_CAPTURE,
                        template_id: 7,
                      },
                      {
                        task_key: '',
                        artifacts: ['group_image_back'],
                        task_type: CAPTURE_TASKS_DATA_CAPTURE,
                        template_id: 8,
                      },
                      {
                        public_id: '',
                        artifacts: ['imageFront'],
                        task_type: CAPTURE_TASKS_DATA_CAPTURE,
                        template_id: 7,
                      },
                      {
                        public_id: '',
                        artifacts: ['pan'],
                        task_type: CAPTURE_TASKS_DATA_CAPTURE,
                        template_id: 2,
                      },
                      {
                        public_id: '',
                        artifacts: ['aadhaar'],
                        task_type: CAPTURE_TASKS_DATA_CAPTURE,
                        template_id: 5,
                      },
                      {
                        public_id: '',
                        artifacts: ['imageBack'],
                        task_type: CAPTURE_TASKS_DATA_CAPTURE,
                        template_id: 8,
                      },
                    ],
                  },
                ],
              },
              {
                task_key: CAPTURE_TASKS_ASSISTED_VKYC,
                task_type: CAPTURE_TASKS_ASSISTED_VKYC,
                template_id: 14,
                // "template_id": 15,
                // "task_type": CAPTURE_TASKS_SELF_VKYC,
                // "task_key": CAPTURE_TASKS_SELF_VKYC
              },
            ],
          ],
          allowed_poa_documents: [
            'ind_aadhaar',
            'ind_driving_license',
            'ind_voter',
            'ind_passport',
          ],
          allowed_poi_documents: ['ind_pan'],
          artifacts: {
            imageFront: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url:
                'https://storage.googleapis.com/kyc-gateway-dev-hrishi/test.jpg?Expires=1573635975&GoogleAccessId=hrishikesh-bihani%40idfy-dev.iam.gserviceaccount.com&Signature=yhXY5VsW%2FPNrM3IS8b2vMGc8G7hkLldKSGRZkNDDiHUctLTHLfnpEVwHfFDBZO7lvu40fU184RYuPij2L8vos0RAnMoWLn9Jx5WSwcr2DLt4i8Tq8vxa55Z8SQLGS1NIYu6%2FB2rbYkNgey8qmwYPrr%2F9F7lNa8vN6w0v%2BXRQDM8GfwWcGNm4x1Cu%2BoWAgVY9rlLHb2G9aE34k0Qaq%2FsYUhPl1d3gwRg%2FH653jQIY1e46SSzotMN%2BnkaeRTmz3GFvozIKV1WvgFLq%2BRAt0AFC68AHHwSCSkubNhmPaZQTrEDn%2BihwhpaTudOVwuPW4PvXkPBQw9%2BBnwwpO4Bo03ZkFQ%3D%3D',
              value:
                'https://storage.googleapis.com/kyc-gateway-dev-hrishi/test.jpg?Expires=1573635972&GoogleAccessId=hrishikesh-bihani%40idfy-dev.iam.gserviceaccount.com&Signature=vJShdf6WcB8%2Biv8SCNhV75QbbpcBXEh6K5COMkMHDgh%2Fz0%2BDW4WSdP%2FQ4YAR%2F40DUQ%2FE4BAXI%2Bma8I5ax5q32sdjP%2FJtz4iNgSAEH7QN5vQ0phB9B851%2FW0uUbFmMed2NhxwJj0aLI10Bf8g%2BatP2jXfn3k7R5pFbKcK6XsOYLS93rtqcyjvoyNI8USJjCfRoqXOQQhevl6L5wzrjiaEHoo5sf1%2FQvRw47l7lIRF0f7dlkf6S8qO1PP4%2FRf9uewj%2Bh2pNtPvMIrhFvyedFN4kKMrgMz85DxUJTGYLnnQ4iysAvP5FlCrZCAgcRUS1yg45s%2B3SM4A2AlBbc0RPMT82g%3D%3D',
              present: false,
            },
            imageBack: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: '',
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url:
                'https://storage.googleapis.com/kyc-gateway-dev-hrishi/test.jpg?Expires=1573635975&GoogleAccessId=hrishikesh-bihani%40idfy-dev.iam.gserviceaccount.com&Signature=yhXY5VsW%2FPNrM3IS8b2vMGc8G7hkLldKSGRZkNDDiHUctLTHLfnpEVwHfFDBZO7lvu40fU184RYuPij2L8vos0RAnMoWLn9Jx5WSwcr2DLt4i8Tq8vxa55Z8SQLGS1NIYu6%2FB2rbYkNgey8qmwYPrr%2F9F7lNa8vN6w0v%2BXRQDM8GfwWcGNm4x1Cu%2BoWAgVY9rlLHb2G9aE34k0Qaq%2FsYUhPl1d3gwRg%2FH653jQIY1e46SSzotMN%2BnkaeRTmz3GFvozIKV1WvgFLq%2BRAt0AFC68AHHwSCSkubNhmPaZQTrEDn%2BihwhpaTudOVwuPW4PvXkPBQw9%2BBnwwpO4Bo03ZkFQ%3D%3D',
              value:
                'https://storage.googleapis.com/kyc-gateway-dev-hrishi/test.jpg?Expires=1573635975&GoogleAccessId=hrishikesh-bihani%40idfy-dev.iam.gserviceaccount.com&Signature=yhXY5VsW%2FPNrM3IS8b2vMGc8G7hkLldKSGRZkNDDiHUctLTHLfnpEVwHfFDBZO7lvu40fU184RYuPij2L8vos0RAnMoWLn9Jx5WSwcr2DLt4i8Tq8vxa55Z8SQLGS1NIYu6%2FB2rbYkNgey8qmwYPrr%2F9F7lNa8vN6w0v%2BXRQDM8GfwWcGNm4x1Cu%2BoWAgVY9rlLHb2G9aE34k0Qaq%2FsYUhPl1d3gwRg%2FH653jQIY1e46SSzotMN%2BnkaeRTmz3GFvozIKV1WvgFLq%2BRAt0AFC68AHHwSCSkubNhmPaZQTrEDn%2BihwhpaTudOVwuPW4PvXkPBQw9%2BBnwwpO4Bo03ZkFQ%3D%3D',
              present: false,
            },
            pan: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: 'Pan',
              type: CAPTURE_TEMPLATES_TEXT,
              value: '',
              present: false,
            },
            aadhaar: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: '',
              type: CAPTURE_TEMPLATES_TEXT,
              value: '',
              present: false,
            },
            group_image_back: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: 'back',
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url:
                'https://storage.googleapis.com/kyc-gateway-dev-hrishi/test.jpg?Expires=1573635975&GoogleAccessId=hrishikesh-bihani%40idfy-dev.iam.gserviceaccount.com&Signature=yhXY5VsW%2FPNrM3IS8b2vMGc8G7hkLldKSGRZkNDDiHUctLTHLfnpEVwHfFDBZO7lvu40fU184RYuPij2L8vos0RAnMoWLn9Jx5WSwcr2DLt4i8Tq8vxa55Z8SQLGS1NIYu6%2FB2rbYkNgey8qmwYPrr%2F9F7lNa8vN6w0v%2BXRQDM8GfwWcGNm4x1Cu%2BoWAgVY9rlLHb2G9aE34k0Qaq%2FsYUhPl1d3gwRg%2FH653jQIY1e46SSzotMN%2BnkaeRTmz3GFvozIKV1WvgFLq%2BRAt0AFC68AHHwSCSkubNhmPaZQTrEDn%2BihwhpaTudOVwuPW4PvXkPBQw9%2BBnwwpO4Bo03ZkFQ%3D%3D',
              value:
                'https://storage.googleapis.com/kyc-gateway-dev-hrishi/test.jpg?Expires=1573635972&GoogleAccessId=hrishikesh-bihani%40idfy-dev.iam.gserviceaccount.com&Signature=vJShdf6WcB8%2Biv8SCNhV75QbbpcBXEh6K5COMkMHDgh%2Fz0%2BDW4WSdP%2FQ4YAR%2F40DUQ%2FE4BAXI%2Bma8I5ax5q32sdjP%2FJtz4iNgSAEH7QN5vQ0phB9B851%2FW0uUbFmMed2NhxwJj0aLI10Bf8g%2BatP2jXfn3k7R5pFbKcK6XsOYLS93rtqcyjvoyNI8USJjCfRoqXOQQhevl6L5wzrjiaEHoo5sf1%2FQvRw47l7lIRF0f7dlkf6S8qO1PP4%2FRf9uewj%2Bh2pNtPvMIrhFvyedFN4kKMrgMz85DxUJTGYLnnQ4iysAvP5FlCrZCAgcRUS1yg45s%2B3SM4A2AlBbc0RPMT82g%3D%3D',
              present: false,
            },
            group_image_front: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: 'front',
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url:
                'https://storage.googleapis.com/kyc-gateway-dev-hrishi/test.jpg?Expires=1573635975&GoogleAccessId=hrishikesh-bihani%40idfy-dev.iam.gserviceaccount.com&Signature=yhXY5VsW%2FPNrM3IS8b2vMGc8G7hkLldKSGRZkNDDiHUctLTHLfnpEVwHfFDBZO7lvu40fU184RYuPij2L8vos0RAnMoWLn9Jx5WSwcr2DLt4i8Tq8vxa55Z8SQLGS1NIYu6%2FB2rbYkNgey8qmwYPrr%2F9F7lNa8vN6w0v%2BXRQDM8GfwWcGNm4x1Cu%2BoWAgVY9rlLHb2G9aE34k0Qaq%2FsYUhPl1d3gwRg%2FH653jQIY1e46SSzotMN%2BnkaeRTmz3GFvozIKV1WvgFLq%2BRAt0AFC68AHHwSCSkubNhmPaZQTrEDn%2BihwhpaTudOVwuPW4PvXkPBQw9%2BBnwwpO4Bo03ZkFQ%3D%3D',
              value:
                'https://storage.googleapis.com/kyc-gateway-dev-hrishi/test.jpg?Expires=1573635972&GoogleAccessId=hrishikesh-bihani%40idfy-dev.iam.gserviceaccount.com&Signature=vJShdf6WcB8%2Biv8SCNhV75QbbpcBXEh6K5COMkMHDgh%2Fz0%2BDW4WSdP%2FQ4YAR%2F40DUQ%2FE4BAXI%2Bma8I5ax5q32sdjP%2FJtz4iNgSAEH7QN5vQ0phB9B851%2FW0uUbFmMed2NhxwJj0aLI10Bf8g%2BatP2jXfn3k7R5pFbKcK6XsOYLS93rtqcyjvoyNI8USJjCfRoqXOQQhevl6L5wzrjiaEHoo5sf1%2FQvRw47l7lIRF0f7dlkf6S8qO1PP4%2FRf9uewj%2Bh2pNtPvMIrhFvyedFN4kKMrgMz85DxUJTGYLnnQ4iysAvP5FlCrZCAgcRUS1yg45s%2B3SM4A2AlBbc0RPMT82g%3D%3D',
              present: false,
            },
          },
        },
      },
    };
  };

  private static mockPermissionPage = (): any => {
    return {
      validations: [],
      data: {
        page: 'permissions',
        config: {
          title: 'Ask Permissions',
          capture: [
            {
              attr: 'location',
              template: {
                type: 'span',
                mandatory: true,
                label: 'Please give location',
                help_text: 'Required to capture images for the KYC process',
              },
            },
            {
              attr: 'camera',
              template: {
                type: 'span',
                mandatory: true,
                label: 'Please give access to camera',
                help_text: 'Required to capture images for the KYC process',
              },
            },
            {
              attr: 'microphone',
              template: {
                type: '',
                mandatory: true,
                label: 'Please give access to microphone',
                help_text: '',
              },
            },
          ],
        },
      },
    };
  };

  private static mockCaptureConfig6 = (): any => {
    return {
      validations: [],
      data: {
        page: 'capture',
        config: {
          tasks: {
            'vkyc.assisted_vkyc': {
              key: CAPTURE_TASKS_ASSISTED_VKYC,
              request_uid: 'f41476d4-65e3-41e4-937e-af0c310ffe25',
              status: 'in_progress',
              task_type_key: CAPTURE_TASKS_ASSISTED_VKYC,
            },
            'capture.capture_video_activity': {
              task_type_key: CAPTURE_TASKS_SV_SERVICE,
              status: 'in_progress',
              request_uid: 'd6e7b94d-06bf-11ea-a076-a2a18de305e3',
              key: CAPTURE_TASKS_SV_SERVICE,
            },
          },
          templates: {
            1: {
              type: CAPTURE_TEMPLATES_CARD,
              title: 'Pan Details',
              detail: 'Please Enter Your PAN Number',
            },
            2: {
              type: CAPTURE_TEMPLATES_TEXT,
              document_type: 'ind_pan',
              fact_type_key: 'id_number',
              label: 'Pan Details',
              help_text: 'Enter your PAN number',
              mandatory: true,
              placeholder: 'Enter your pan number',
              regex: '^[A-Z]{3}[ABCFGHLJPT]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$',
            },
            3: {
              type: CAPTURE_TEMPLATES_CARD,
              title: 'Self VKYC',
            },
            4: {
              type: CAPTURE_TEMPLATES_BUTTON,
              mandatory: false,
              side: '',
              options: [],
              value: 'https://www.google.com',
              label: 'Capture',
            },
            5: {
              type: CAPTURE_TEMPLATES_TEXT,
              document_type: 'ind_aadhaar',
              fact_type_key: 'id_number',
              label: 'Aadhaar Details',
              help_text: '',
              mandatory: true,
              placeholder: 'Enter your aadhaar number',
              regex: '^[0-9]{12}$',
            },
            6: {
              type: CAPTURE_TEMPLATES_CARD,
              title: 'ID Proof',
              detail: 'Select valid ID Proof - Upload Allowed',
            },
            7: {
              type: CAPTURE_TEMPLATES_IMAGE,
              label: '',
              category_type: 'poi',
              mandatory: false,
              side: 'front',
              options: [],
              allow_upload: true,
              document_type_key: '',
              help_text: '',
            },
            8: {
              type: CAPTURE_TEMPLATES_IMAGE,
              label: '',
              category_type: 'poi',
              mandatory: false,
              side: 'back',
              options: [],
              allow_upload: true,
              document_type_key: '',
              help_text: '',
            },
            9: {
              type: CAPTURE_TEMPLATES_TEXT,
              document_type: 'ind_driving_license',
              fact_type_key: 'id_number',
              label: 'Driving License Details',
              help_text: 'Two Letter State Code/RTO#/Year/#',
              mandatory: false,
              placeholder: 'AANN-YYYYNNNNNNN',
              regex: '^[A-Z]{2}[0-9]{2}-[0-9]{4}[0-9]{7}$',
            },
            10: {
              type: CAPTURE_TEMPLATES_CARD,
              title: 'Driving License Details',
              detail: 'Please enter your DL#',
            },
            11: {
              type: CAPTURE_TEMPLATES_CARD,
              title: 'Selfie',
              detail: 'Take a selfie',
            },
            12: {
              type: CAPTURE_TEMPLATES_IMAGE,
              label: '',
              category_type: '',
              mandatory: true,
              side: 'front',
              options: [],
              allow_upload: false,
              document_type_key: 'selfie',
              help_text: '',
            },
            13: {
              type: CAPTURE_TEMPLATES_GROUP,
              title: 'Group Image Captures',
              detail: 'Please Upload',
            },
            14: {
              allow_upload: false,
              artifacts: [],
              category_type: null,
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 73,
              label: null,
              mandatory: true,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: CAPTURE_TASKS_ASSISTED_VKYC,
              task_type: CAPTURE_TASKS_ASSISTED_VKYC,
              title: 'Assisted VKYC',
              type: CAPTURE_TEMPLATES_BUTTON,
            },
            15: {
              allow_upload: false,
              artifacts: [],
              category_type: null,
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 73,
              label: null,
              mandatory: true,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: CAPTURE_TASKS_SV_SERVICE,
              task_type: CAPTURE_TASKS_SV_SERVICE,
              title: 'Assisted VKYC',
              type: CAPTURE_TEMPLATES_BUTTON,
            },
          },
          capture: [
            [
              {
                public_id: '',
                artifacts: [],
                template_id: 1,
                tasks: [
                  {
                    public_id: '',
                    artifacts: ['pan'],
                    task_type: CAPTURE_TASKS_DATA_CAPTURE,
                    template_id: 2,
                  },
                  {
                    public_id: '',
                    artifacts: ['aadhaar'],
                    task_type: CAPTURE_TASKS_DATA_CAPTURE,
                    template_id: 5,
                  },
                ],
              },
              {
                public_id: '',
                artifacts: [],
                template_id: 3,
                tasks: [
                  {
                    public_id: '',
                    artifacts: [],
                    task_type: CAPTURE_TASKS_DATA_CAPTURE,
                    template_id: 4,
                  },
                ],
              },
              {
                public_id: '',
                artifacts: [],
                template_id: 6,
                tasks: [
                  {
                    public_id: '',
                    artifacts: ['imageFront'],
                    task_type: CAPTURE_TASKS_DATA_CAPTURE,
                    template_id: 7,
                  },
                  {
                    public_id: '',
                    artifacts: ['imageBack'],
                    task_type: CAPTURE_TASKS_DATA_CAPTURE,
                    template_id: 8,
                  },
                ],
              },
            ],
            [
              {
                public_id: '',
                artifacts: [],
                template_id: 10,
                tasks: [
                  {
                    public_id: '',
                    artifacts: ['drv_lic'],
                    task_type: CAPTURE_TASKS_DATA_CAPTURE,
                    template_id: 9,
                  },
                ],
              },
              {
                public_id: '',
                artifacts: [],
                template_id: 11,
                tasks: [
                  {
                    public_id: '',
                    artifacts: ['imageSelfie'],
                    task_type: CAPTURE_TASKS_DATA_CAPTURE,
                    template_id: 12,
                  },
                ],
              },
            ],
          ],
          allowed_poa_documents: [
            'ind_aadhaar',
            'ind_driving_license',
            'ind_voter',
            'ind_passport',
          ],
          allowed_poi_documents: ['ind_pan'],
          artifacts: {
            imageFront: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url:
                'https://storage.googleapis.com/kyc-gateway-dev-hrishi/test.jpg?Expires=1573635975&GoogleAccessId=hrishikesh-bihani%40idfy-dev.iam.gserviceaccount.com&Signature=yhXY5VsW%2FPNrM3IS8b2vMGc8G7hkLldKSGRZkNDDiHUctLTHLfnpEVwHfFDBZO7lvu40fU184RYuPij2L8vos0RAnMoWLn9Jx5WSwcr2DLt4i8Tq8vxa55Z8SQLGS1NIYu6%2FB2rbYkNgey8qmwYPrr%2F9F7lNa8vN6w0v%2BXRQDM8GfwWcGNm4x1Cu%2BoWAgVY9rlLHb2G9aE34k0Qaq%2FsYUhPl1d3gwRg%2FH653jQIY1e46SSzotMN%2BnkaeRTmz3GFvozIKV1WvgFLq%2BRAt0AFC68AHHwSCSkubNhmPaZQTrEDn%2BihwhpaTudOVwuPW4PvXkPBQw9%2BBnwwpO4Bo03ZkFQ%3D%3D',
              value:
                'https://storage.googleapis.com/kyc-gateway-dev-hrishi/test.jpg?Expires=1573635972&GoogleAccessId=hrishikesh-bihani%40idfy-dev.iam.gserviceaccount.com&Signature=vJShdf6WcB8%2Biv8SCNhV75QbbpcBXEh6K5COMkMHDgh%2Fz0%2BDW4WSdP%2FQ4YAR%2F40DUQ%2FE4BAXI%2Bma8I5ax5q32sdjP%2FJtz4iNgSAEH7QN5vQ0phB9B851%2FW0uUbFmMed2NhxwJj0aLI10Bf8g%2BatP2jXfn3k7R5pFbKcK6XsOYLS93rtqcyjvoyNI8USJjCfRoqXOQQhevl6L5wzrjiaEHoo5sf1%2FQvRw47l7lIRF0f7dlkf6S8qO1PP4%2FRf9uewj%2Bh2pNtPvMIrhFvyedFN4kKMrgMz85DxUJTGYLnnQ4iysAvP5FlCrZCAgcRUS1yg45s%2B3SM4A2AlBbc0RPMT82g%3D%3D',
              present: false,
            },
            imageBack: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: '',
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url:
                'https://storage.googleapis.com/kyc-gateway-dev-hrishi/test.jpg?Expires=1573635972&GoogleAccessId=hrishikesh-bihani%40idfy-dev.iam.gserviceaccount.com&Signature=vJShdf6WcB8%2Biv8SCNhV75QbbpcBXEh6K5COMkMHDgh%2Fz0%2BDW4WSdP%2FQ4YAR%2F40DUQ%2FE4BAXI%2Bma8I5ax5q32sdjP%2FJtz4iNgSAEH7QN5vQ0phB9B851%2FW0uUbFmMed2NhxwJj0aLI10Bf8g%2BatP2jXfn3k7R5pFbKcK6XsOYLS93rtqcyjvoyNI8USJjCfRoqXOQQhevl6L5wzrjiaEHoo5sf1%2FQvRw47l7lIRF0f7dlkf6S8qO1PP4%2FRf9uewj%2Bh2pNtPvMIrhFvyedFN4kKMrgMz85DxUJTGYLnnQ4iysAvP5FlCrZCAgcRUS1yg45s%2B3SM4A2AlBbc0RPMT82g%3D%3D',
              value:
                'https://storage.googleapis.com/kyc-gateway-dev-hrishi/test.jpg?Expires=1573635972&GoogleAccessId=hrishikesh-bihani%40idfy-dev.iam.gserviceaccount.com&Signature=vJShdf6WcB8%2Biv8SCNhV75QbbpcBXEh6K5COMkMHDgh%2Fz0%2BDW4WSdP%2FQ4YAR%2F40DUQ%2FE4BAXI%2Bma8I5ax5q32sdjP%2FJtz4iNgSAEH7QN5vQ0phB9B851%2FW0uUbFmMed2NhxwJj0aLI10Bf8g%2BatP2jXfn3k7R5pFbKcK6XsOYLS93rtqcyjvoyNI8USJjCfRoqXOQQhevl6L5wzrjiaEHoo5sf1%2FQvRw47l7lIRF0f7dlkf6S8qO1PP4%2FRf9uewj%2Bh2pNtPvMIrhFvyedFN4kKMrgMz85DxUJTGYLnnQ4iysAvP5FlCrZCAgcRUS1yg45s%2B3SM4A2AlBbc0RPMT82g%3D%3D',
              present: false,
            },
            pan: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: 'Pan',
              type: CAPTURE_TEMPLATES_TEXT,
              value: '',
              present: false,
            },
            aadhaar: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: '',
              type: CAPTURE_TEMPLATES_TEXT,
              value: '',
              present: false,
            },
            drv_lic: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: '',
              type: CAPTURE_TEMPLATES_TEXT,
              value: '',
              present: false,
            },
            imageSelfie: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: '',
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url:
                'https://storage.googleapis.com/kyc-gateway-dev-hrishi/test.jpg?Expires=1573635975&GoogleAccessId=hrishikesh-bihani%40idfy-dev.iam.gserviceaccount.com&Signature=yhXY5VsW%2FPNrM3IS8b2vMGc8G7hkLldKSGRZkNDDiHUctLTHLfnpEVwHfFDBZO7lvu40fU184RYuPij2L8vos0RAnMoWLn9Jx5WSwcr2DLt4i8Tq8vxa55Z8SQLGS1NIYu6%2FB2rbYkNgey8qmwYPrr%2F9F7lNa8vN6w0v%2BXRQDM8GfwWcGNm4x1Cu%2BoWAgVY9rlLHb2G9aE34k0Qaq%2FsYUhPl1d3gwRg%2FH653jQIY1e46SSzotMN%2BnkaeRTmz3GFvozIKV1WvgFLq%2BRAt0AFC68AHHwSCSkubNhmPaZQTrEDn%2BihwhpaTudOVwuPW4PvXkPBQw9%2BBnwwpO4Bo03ZkFQ%3D%3D',
              value:
                'https://storage.googleapis.com/kyc-gateway-dev-hrishi/test.jpg?Expires=1573635972&GoogleAccessId=hrishikesh-bihani%40idfy-dev.iam.gserviceaccount.com&Signature=vJShdf6WcB8%2Biv8SCNhV75QbbpcBXEh6K5COMkMHDgh%2Fz0%2BDW4WSdP%2FQ4YAR%2F40DUQ%2FE4BAXI%2Bma8I5ax5q32sdjP%2FJtz4iNgSAEH7QN5vQ0phB9B851%2FW0uUbFmMed2NhxwJj0aLI10Bf8g%2BatP2jXfn3k7R5pFbKcK6XsOYLS93rtqcyjvoyNI8USJjCfRoqXOQQhevl6L5wzrjiaEHoo5sf1%2FQvRw47l7lIRF0f7dlkf6S8qO1PP4%2FRf9uewj%2Bh2pNtPvMIrhFvyedFN4kKMrgMz85DxUJTGYLnnQ4iysAvP5FlCrZCAgcRUS1yg45s%2B3SM4A2AlBbc0RPMT82g%3D%3D',
              present: false,
            },
            group_image_back: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: 'back',
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url:
                'https://storage.googleapis.com/kyc-gateway-dev-hrishi/test.jpg?Expires=1573635975&GoogleAccessId=hrishikesh-bihani%40idfy-dev.iam.gserviceaccount.com&Signature=yhXY5VsW%2FPNrM3IS8b2vMGc8G7hkLldKSGRZkNDDiHUctLTHLfnpEVwHfFDBZO7lvu40fU184RYuPij2L8vos0RAnMoWLn9Jx5WSwcr2DLt4i8Tq8vxa55Z8SQLGS1NIYu6%2FB2rbYkNgey8qmwYPrr%2F9F7lNa8vN6w0v%2BXRQDM8GfwWcGNm4x1Cu%2BoWAgVY9rlLHb2G9aE34k0Qaq%2FsYUhPl1d3gwRg%2FH653jQIY1e46SSzotMN%2BnkaeRTmz3GFvozIKV1WvgFLq%2BRAt0AFC68AHHwSCSkubNhmPaZQTrEDn%2BihwhpaTudOVwuPW4PvXkPBQw9%2BBnwwpO4Bo03ZkFQ%3D%3D',
              value:
                'https://storage.googleapis.com/kyc-gateway-dev-hrishi/test.jpg?Expires=1573635972&GoogleAccessId=hrishikesh-bihani%40idfy-dev.iam.gserviceaccount.com&Signature=vJShdf6WcB8%2Biv8SCNhV75QbbpcBXEh6K5COMkMHDgh%2Fz0%2BDW4WSdP%2FQ4YAR%2F40DUQ%2FE4BAXI%2Bma8I5ax5q32sdjP%2FJtz4iNgSAEH7QN5vQ0phB9B851%2FW0uUbFmMed2NhxwJj0aLI10Bf8g%2BatP2jXfn3k7R5pFbKcK6XsOYLS93rtqcyjvoyNI8USJjCfRoqXOQQhevl6L5wzrjiaEHoo5sf1%2FQvRw47l7lIRF0f7dlkf6S8qO1PP4%2FRf9uewj%2Bh2pNtPvMIrhFvyedFN4kKMrgMz85DxUJTGYLnnQ4iysAvP5FlCrZCAgcRUS1yg45s%2B3SM4A2AlBbc0RPMT82g%3D%3D',
              present: false,
            },
            group_image_front: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: 'front',
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url:
                'https://storage.googleapis.com/kyc-gateway-dev-hrishi/test.jpg?Expires=1573635975&GoogleAccessId=hrishikesh-bihani%40idfy-dev.iam.gserviceaccount.com&Signature=yhXY5VsW%2FPNrM3IS8b2vMGc8G7hkLldKSGRZkNDDiHUctLTHLfnpEVwHfFDBZO7lvu40fU184RYuPij2L8vos0RAnMoWLn9Jx5WSwcr2DLt4i8Tq8vxa55Z8SQLGS1NIYu6%2FB2rbYkNgey8qmwYPrr%2F9F7lNa8vN6w0v%2BXRQDM8GfwWcGNm4x1Cu%2BoWAgVY9rlLHb2G9aE34k0Qaq%2FsYUhPl1d3gwRg%2FH653jQIY1e46SSzotMN%2BnkaeRTmz3GFvozIKV1WvgFLq%2BRAt0AFC68AHHwSCSkubNhmPaZQTrEDn%2BihwhpaTudOVwuPW4PvXkPBQw9%2BBnwwpO4Bo03ZkFQ%3D%3D',
              value:
                'https://storage.googleapis.com/kyc-gateway-dev-hrishi/test.jpg?Expires=1573635972&GoogleAccessId=hrishikesh-bihani%40idfy-dev.iam.gserviceaccount.com&Signature=vJShdf6WcB8%2Biv8SCNhV75QbbpcBXEh6K5COMkMHDgh%2Fz0%2BDW4WSdP%2FQ4YAR%2F40DUQ%2FE4BAXI%2Bma8I5ax5q32sdjP%2FJtz4iNgSAEH7QN5vQ0phB9B851%2FW0uUbFmMed2NhxwJj0aLI10Bf8g%2BatP2jXfn3k7R5pFbKcK6XsOYLS93rtqcyjvoyNI8USJjCfRoqXOQQhevl6L5wzrjiaEHoo5sf1%2FQvRw47l7lIRF0f7dlkf6S8qO1PP4%2FRf9uewj%2Bh2pNtPvMIrhFvyedFN4kKMrgMz85DxUJTGYLnnQ4iysAvP5FlCrZCAgcRUS1yg45s%2B3SM4A2AlBbc0RPMT82g%3D%3D',
              present: false,
            },
            group_pan: {
              category_type: '',
              document_type_key: '',
              error: '',
              fact_type_key: '',
              message: '',
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url: '',
              value: '',
              present: false,
            },
          },
        },
      },
    };
  };

  private static mockCaptureConfig5_1 = (): any => {
    return {
      validations: [],
      data: {
        page: 'capture',
        config: {
          tasks: {
            'vkyc.assisted_vkyc': {
              key: CAPTURE_TASKS_ASSISTED_VKYC,
              request_uid: 'f41476d4-65e3-41e4-937e-af0c310ffe25',
              status: 'in_progress',
              task_type_key: CAPTURE_TASKS_ASSISTED_VKYC,
            },
            'capture.capture_video_activity': {
              task_type_key: CAPTURE_TASKS_SV_SERVICE,
              status: 'in_progress',
              request_uid: 'd6e7b94d-06bf-11ea-a076-a2a18de305e3',
              key: CAPTURE_TASKS_SV_SERVICE,
            },
            'verify_with_source.ind_aadhaar_xml': {
              task_type_key: CAPTURE_TASKS_VERIFY_AADHAAR_XML,
              status: 'in_progress',
              request_uid: '',
              key: CAPTURE_TASKS_VERIFY_AADHAAR_XML,
              error: '',
            },
          },
          templates: {
            6023: {
              allow_upload: false,
              artifacts: ['nil.ind_aadhaar_xml.nil.1.nil'],
              category_type: null,
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 6023,
              label: 'Upload XML File',
              mandatory: true,
              options: [
                'application/x-compressed',
                'application/x-zip-compressed',
                'application/zip',
                'multipart/x-zip',
              ],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: null,
              task_type: CAPTURE_TASKS_DATA_CAPTURE,
              title: null,
              type: CAPTURE_TEMPLATES_FILE_PICKER,
            },
            6019: {
              allow_upload: false,
              artifacts: [],
              category_type: null,
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 6019,
              label: 'Get XML',
              mandatory: true,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: null,
              task_type: CAPTURE_TASKS_FETCH_AADHAAR_XML,
              title: null,
              type: CAPTURE_TEMPLATES_ANCHOR,
            },
            6020: {
              allow_upload: false,
              artifacts: ['nil.nil.first_name.1.nil'],
              category_type: null,
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 6020,
              label: 'name',
              mandatory: true,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: null,
              task_type: CAPTURE_TASKS_DATA_CAPTURE,
              title: null,
              type: CAPTURE_TEMPLATES_TEXT,
            },
            6021: {
              allow_upload: false,
              artifacts: ['nil.selfie.nil.1.nil'],
              category_type: 'selfie',
              dependencies: [],
              detail: null,
              document_type_key: 'selfie',
              fact_type_key: null,
              help_text: null,
              id: 6021,
              label: null,
              mandatory: true,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: 'key6',
              task_type: CAPTURE_TASKS_DATA_CAPTURE,
              title: null,
              type: CAPTURE_TEMPLATES_IMAGE,
            },
            6022: {
              allow_upload: false,
              artifacts: ['poa.nil.nil.1.back'],
              category_type: 'poa',
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 6022,
              label: null,
              mandatory: true,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: 'back',
              task_key: 'key25',
              task_type: CAPTURE_TASKS_DATA_CAPTURE,
              title: null,
              type: CAPTURE_TEMPLATES_IMAGE,
            },
            6024: {
              allow_upload: false,
              artifacts: ['poa.nil.nil.1.front'],
              category_type: 'poa',
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 6024,
              label: null,
              mandatory: true,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: 'front',
              task_key: 'key24',
              task_type: CAPTURE_TASKS_DATA_CAPTURE,
              title: null,
              type: CAPTURE_TEMPLATES_IMAGE,
            },
            6025: {
              allow_upload: false,
              artifacts: ['nil.ind_aadhaar_xml.share_code.1.nil'],
              category_type: null,
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 6025,
              label: 'label',
              mandatory: true,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: '^[0-9]{4}$',
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: null,
              task_type: CAPTURE_TASKS_DATA_CAPTURE,
              title: null,
              type: CAPTURE_TEMPLATES_TEXT,
            },
            6027: {
              allow_upload: false,
              artifacts: [],
              category_type: null,
              dependencies: [],
              detail: 'Capture selfie',
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 6027,
              label: null,
              mandatory: false,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: null,
              task_type: null,
              title: 'Selfie',
              type: CAPTURE_TEMPLATES_CARD,
            },
            6029: {
              allow_upload: false,
              artifacts: [],
              category_type: null,
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 6029,
              label: 'Verify Aadhaar XML',
              mandatory: false,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {
                capture_actions: [
                  {
                    action: CAPTURE_TASKS_FETCH_AADHAAR_XML,
                  },
                ],
              },
              side: null,
              task_key: null,
              task_type: CAPTURE_TASKS_VERIFY_AADHAAR_XML,
              title: null,
              type: CAPTURE_TEMPLATES_FORM,
            },
            6030: {
              allow_upload: false,
              artifacts: [],
              category_type: 'poa',
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 6030,
              label: null,
              mandatory: false,
              options: [
                'ind_aadhaar',
                'ind_driving_license',
                'ind_voter_id',
                'ind_passport',
              ],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: null,
              task_type: CAPTURE_TASKS_GROUPED,
              title: null,
              type: CAPTURE_TEMPLATES_GROUP,
            },
            6031: {
              allow_upload: false,
              artifacts: [],
              category_type: null,
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 6031,
              label: null,
              mandatory: false,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: null,
              task_type: null,
              title: 'Aadhaar XML Upload',
              type: CAPTURE_TEMPLATES_CARD,
            },
            6032: {
              allow_upload: false,
              artifacts: [],
              category_type: null,
              dependencies: [],
              detail: 'Select a valid Address Proof',
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 6032,
              label: null,
              mandatory: false,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: null,
              task_type: null,
              title: 'Address Proof',
              type: CAPTURE_TEMPLATES_CARD,
            },
          },
          capture: [
            [
              {
                template_id: 6031,
                tasks: [
                  {
                    task_key: '',
                    task_type: CAPTURE_TASKS_VERIFY_AADHAAR_XML,
                    template_id: 6029,
                    tasks: [
                      {
                        template_id: 6023,
                        task_type: CAPTURE_TASKS_DATA_CAPTURE,
                        artifacts: ['nil.ind_aadhaar_xml.nil.1.nil'],
                      },
                      {
                        task_type: CAPTURE_TASKS_FETCH_AADHAAR_XML,
                        template_id: 6019,
                      },
                      {
                        template_id: 6025,
                        task_type: CAPTURE_TASKS_DATA_CAPTURE,
                        artifacts: ['nil.ind_aadhaar_xml.share_code.1.nil'],
                      },
                      // {
                      //   template_id: 6020,
                      //   task_type: CAPTURE_TASKS_DATA_CAPTURE,
                      //   artifacts: [
                      //     "nil.nil.name.1.nil"
                      //   ]
                      // }
                    ],
                  },
                ],
              },
            ],
          ],
          allowed_poa_documents: [
            'ind_aadhaar',
            'ind_driving_license',
            'ind_voter',
            'ind_passport',
          ],
          allowed_poi_documents: ['ind_pan'],
          artifacts: {
            'nil.ind_aadhaar_xml.nil.1.nil': {
              category: null,
              document_type_key: 'ind_aadhaar_xml',
              error: null,
              fact_type_key: null,
              key: 'nil.ind_aadhaar_xml.nil.1.nil',
              present: false,
              side: null,
              source: 1,
              tags: null,
              type: CAPTURE_TEMPLATES_TEXT,
              value: null,
            },
            'nil.ind_aadhaar_xml.share_code.1.nil': {
              category: null,
              document_type_key: 'ind_aadhaar_xml',
              error: null,
              fact_type_key: 'share_code',
              key: 'nil.ind_aadhaar_xml.share_code.1.nil',
              present: false,
              side: null,
              source: 1,
              tags: null,
              type: CAPTURE_TEMPLATES_TEXT,
              value: null,
            },
            'nil.nil.name.1.nil': {
              category: null,
              document_type_key: null,
              error: null,
              fact_type_key: 'name',
              key: 'nil.nil.name.1.nil',
              present: false,
              side: null,
              source: 1,
              tags: null,
              type: CAPTURE_TEMPLATES_TEXT,
              value: null,
            },
            'nil.selfie.nil.1.nil': {
              category: null,
              document_type_key: 'selfie',
              error: null,
              fact_type_key: null,
              key: 'nil.selfie.nil.1.nil',
              present: false,
              side: null,
              source: 1,
              tags: null,
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url:
                'https://storage.googleapis.com/idfy-kyc-staging-kyc-gateway/e04a0d6c-fad3-40bd-964e-56d521fea19b?Expires=1580470971&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=opG5gEnxIzJOubf2k9V8mwoOiAnN30RPo%2B2wYAybLK%2BmqTTN0xi4vgptbzhVH9bRm7hbNh2APiewRoXwdTHXdTdnOveoB%2B6i2ij1ht6VjpFSTqGNgF1a6vHRMbX7PgKuCzGpjmX%2F1QXztQ3mAnUmhiM%2B%2Fbu%2BVTPXhV0M2DZBGhglKJBSuf5WljnXp2lbZzUmOtYXfLdUJfdlQYiRjf9ixhcraE%2BkVtC2kVox3Cs49rt0x1CiSlA5XLPINgkz3e9TiyFrRRZ5hESNr%2BKEfxpyy7wm2Flnv%2Fb%2BiFKbV6dxZZu7uc%2FR8OasO6Gnhbbjv6KONxvFNOZLUeOeSIMV%2BI3uew%3D%3D',
              value:
                'https://storage.googleapis.com/idfy-kyc-staging-kyc-gateway/e04a0d6c-fad3-40bd-964e-56d521fea19b?Expires=1580470971&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=0PlwoStyb10gNTv%2FeIGPXOpNwU3sokAoXphtTlpkJhSn1SWfALnHFhmsYWdUlsYU7nxS9zouFW4jS2lRXoQKZMApvz%2FqUlTba7pDifaUDtUKtm3bCZTWwq%2FcsStnJ51XsczvNC8FFCDgnwNbTeE9yAp5hCwknxNQah4oBnhw59cm5V5L7XKzZrZiznLmTL5LUJSU7fo6J97phknFrbOZ4rYet%2FkcXyx5i65lhLbgf2StgqtPpEBlUfDs%2FJ1hUAnurhkGptUnWNOuWaU2pas6RCCvGT9w8mspPBnKEHyTqqehpWSrMbS03%2B5lKZYIMExt2X60hFZZB%2FYskhpGtlT3vA%3D%3D',
            },
            'poa.nil.nil.1.back': {
              category: 'poa',
              document_type_key: null,
              error: null,
              fact_type_key: null,
              key: 'poa.nil.nil.1.back',
              present: false,
              side: 'back',
              source: 1,
              tags: null,
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url:
                'https://storage.googleapis.com/idfy-kyc-staging-kyc-gateway/c5dbcc1b-fa1c-49ad-bb97-7fa08160144b?Expires=1580470971&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=FMVCBTsFgoox57%2BAAnamlnk4rGOFSl%2FkoufqLLTTy%2BPthy4glmF3WpRWHWiEwaXkGnAkpCQxJ5NfImfbKAWkiP1T%2Fj1C1VGQRrMRNBDswsJOu%2B0e28TQxKfrJuhRZQd5Pq5YlLJdUjsydDOHCIfYcrBMPZhqwzWju2gJfZLW2AmEMburtdI0OymW%2F8mFjtQf9ItDFlRA59OwLilW4YfGu4PKPKGgLBBjc3mn0Y4lrZD9WzV4fAMs08sXFgXUxwF2USfHQHdTRh8arWPRV7bLIOzofaBIGnmejy7BfVJZ9XKFsunYnGJDb4xH6kgCuWpGK6aiJ2b4pRxvkNH08NSJeA%3D%3D',
              value:
                'https://storage.googleapis.com/idfy-kyc-staging-kyc-gateway/c5dbcc1b-fa1c-49ad-bb97-7fa08160144b?Expires=1580470971&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=3wgir4%2BymwKLj7iiemgUwExffu4z9jttsjy2wZfVLjilLn77PhNjSLAY665pn%2BeIfUJeIYWML2%2FMw%2F14Udtqg%2FcytqSnaT4G6WFV7VrHMkLztouBeUFS3Mu291d3FN6QHSjDB3aHSK86%2FoZFuOJ8thC4RmM28CP24hI4JNxNVIH0DLAPCLmFKdoUGCEhMBL4zMgiQ0kRzEkO%2FMQLSD9ZtE2pxmCbTE4qtnbcBZ5aL3Lo%2B5xEB55ZtTfFanacrOOT1%2BlPEXgqgzZ%2FeavlN0zix5EGi2MrqVgRvrdzQOiOxbNc%2BpMFCcbnPjTdOKqSqAbRloiqCG4r3XV%2B%2B8Rcaj6FPQ%3D%3D',
            },
            'poa.nil.nil.1.front': {
              category: 'poa',
              document_type_key: null,
              error: null,
              fact_type_key: null,
              key: 'poa.nil.nil.1.front',
              present: false,
              side: 'front',
              source: 1,
              tags: null,
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url:
                'https://storage.googleapis.com/idfy-kyc-staging-kyc-gateway/27bd3c7f-284b-4055-863f-d904c18e720a?Expires=1580470971&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=JOPXo%2BLQH%2FUt3VqskEwYbqEuf9gL3SrZ1RIdP9za76xupk6lakHMbJfy1nLKalbpxz%2BYdD00BFteZIYOrpYgghiPlI000Bb%2FPmX4iUkecqjDGFsjbwoUmdhpvzZ9we4wA911A7nts7ldTJQfeLmirmSdEwBhy9E4IV0Q2iloc8HYOVX31btx05KfNg4wCdEbJ9PzQTffT4Y1IVPheF3%2Ft2f9uhqUDRfT3hc%2F1SfTUUB%2BlUGiavgKpx%2Bxj8paHha2Bky8aiJPkQS2lidQkwz73foiuhIPZ9OQSKeaaIRHkpn5Orhle%2FSL8qTEYbXpTNjmMhbL80Wsws2XoaBR00qiGQ%3D%3D',
              value:
                'https://storage.googleapis.com/idfy-kyc-staging-kyc-gateway/27bd3c7f-284b-4055-863f-d904c18e720a?Expires=1580470971&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=hyr7rHBGKqbrVR9c9WU2i09MkhNWulnzJRyfh0swdEgEYVL%2BwSRU15Ewa8eYtv7rDmSnSzzTzu8hzPychvEg8BJ%2B81zDq9otA2fpn7rq%2BHTqkaT3N4apXmgPvQFwoejWz%2B9pZLrd0u%2FJ5Z7vCbHMMqYUHUnZfnIduXiL%2Bmn%2FzUYEIsiaCZL15s%2Fu%2Fb2Xb%2B4FjtP61yWpW94yX%2B4Rksmzf9hb0iODs1tbe0bhhshdD3l%2FM06043LncIRreP4ElSfMD%2Fdw378iToiSkXzPhwhJ8so1FC5iQA0ETywVRpNb9AqE0K6UOzJm6AEN7S4XC3Wwn7B0eEgUq%2FQaUfQiXt2gag%3D%3D',
            },
          },
        },
      },
    };
  };

  static mockCaptureAmexOTP = (): any => {
    return {
      validations: [],
      data: {
        page: 'capture',
        config: {
          artifacts: {
            'nil.nil.mobile_numbers.1.nil': {
              category: null,
              document_type_key: null,
              error: null,
              fact_type_key: 'mobile_numbers',
              key: 'nil.nil.mobile_numbers.1.nil',
              present: false,
              side: null,
              source: 1,
              tags: null,
              type: CAPTURE_TEMPLATES_TEXT,
              value: null,
            },
            'nil.nil.otp.1.nil': {
              category: null,
              document_type_key: null,
              error: null,
              fact_type_key: 'otp',
              key: 'nil.nil.otp.1.nil',
              present: false,
              side: null,
              source: 1,
              tags: null,
              type: CAPTURE_TEMPLATES_TEXT,
              value: null,
            },
          },
          tasks: {
            generate_otp: {
              task_type_key: CAPTURE_TASKS_GENERATE_OTP,
              status: 'in_progress',
              request_uid: '',
              key: 'generate_otp',
              error: '',
            },
            validate_otp: {
              task_type_key: CAPTURE_TASKS_VALIDATE_OTP,
              status: 'in_progress',
              request_uid: '',
              key: 'validate_otp',
              error: '',
            },
          },
          templates: {
            '6023': {
              allow_upload: false,
              artifacts: ['nil.nil.mobile_numbers.1.nil'],
              category_type: null,
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: 'Mobile Number',
              id: 6023,
              label: 'Please Enter 10 digit mobile number',
              mandatory: true,
              options: [],
              overlay_type_id: null,
              placeholder: 'Enter 10 digit',
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: null,
              task_type: CAPTURE_TASKS_DATA_CAPTURE,
              title: null,
              type: CAPTURE_TEMPLATES_TEXT,
            },
            '6024': {
              allow_upload: false,
              artifacts: ['nil.nil.otp.1.nil'],
              category_type: null,
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: 'otp',
              help_text: 'OTP',
              id: 6024,
              label: 'Please enter the otp',
              mandatory: true,
              options: [],
              overlay_type_id: null,
              placeholder: 'OTP',
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: 'key24',
              task_type: CAPTURE_TASKS_DATA_CAPTURE,
              title: null,
              type: CAPTURE_TEMPLATES_TEXT,
            },
            '6029': {
              allow_upload: false,
              artifacts: [],
              category_type: null,
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 6029,
              label: 'Generate OTP',
              mandatory: false,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: 'generate_otp',
              task_type: CAPTURE_TASKS_GENERATE_OTP,
              title: null,
              type: CAPTURE_TEMPLATES_FORM,
            },
            '6031': {
              allow_upload: false,
              artifacts: [],
              category_type: null,
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 6031,
              label: 'Validate OTP',
              mandatory: false,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: 'validate_otp',
              task_type: CAPTURE_TASKS_VALIDATE_OTP,
              title: null,
              type: CAPTURE_TEMPLATES_FORM,
            },
            '6032': {
              allow_upload: false,
              artifacts: [],
              category_type: null,
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 6032,
              label: 'OTP verification',
              mandatory: false,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: 'key11',
              title: null,
              type: CAPTURE_TEMPLATES_CARD,
            },
          },
          allowed_poa_documents: [],
          allowed_poi_documents: [],
          capture: [
            [
              {
                tasks: [
                  {
                    task_key: 'generate_otp',
                    task_type: CAPTURE_TASKS_GENERATE_OTP,
                    tasks: [
                      {
                        artifacts: ['nil.nil.mobile_numbers.1.nil'],
                        task_type: CAPTURE_TASKS_DATA_CAPTURE,
                        template_id: 6023,
                      },
                    ],
                    template_id: 6029,
                  },
                  {
                    task_key: 'validate_otp',
                    task_type: CAPTURE_TASKS_VALIDATE_OTP,
                    tasks: [
                      {
                        artifacts: [
                          'nil.nil.otp.1.nil',
                          'nil.nil.mobile_numbers.1.nil',
                        ],
                        task_type: CAPTURE_TASKS_DATA_CAPTURE,
                        template_id: 6024,
                      },
                    ],
                    template_id: 6031,
                  },
                ],
                template_id: 6032,
              },
            ],
          ],
          page_sequence: ['capture'],
        },
        plugins: [
          {
            params: {
              generate_otp: {
                task_key: 'generate_otp',
                task_type_key: CAPTURE_TASKS_GENERATE_OTP,
                template_id: 6031,
              },
            },
            script_src: 'ocr.js',
          },
          {
            params: {
              validate_otp: {
                parent_task_key: 'generate_otp',
              },
            },
          },
        ],
      },
    };
  };

  private static mockCaptureConfig5 = (): any => {
    return {
      validations: [],
      data: {
        page: 'capture',
        config: {
          artifacts: {
            'nil.ind_aadhaar_xml.nil.1.nil': {
              category: null,
              document_type_key: 'ind_aadhaar_xml',
              error: null,
              fact_type_key: null,
              key: 'nil.ind_aadhaar_xml.nil.1.nil',
              present: false,
              side: null,
              source: 1,
              tags: null,
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url:
                'https://storage.googleapis.com/idfy-kyc-staging-kyc-gateway/fdc02592-7789-4f18-9179-8ba03ef61c62?Expires=1581004503&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=iCBvxxPxhzQNlm9QO7E2%2Bt5oPMtiNidKL9FfsyrhNZRFtAbiY5Uzp43iEPtUzqMcK9efEv5ML3nP0jXzlor0jI3lxo5S%2BCnB95KCxM5%2FcmHg74UYvYxJ%2Fe3MPhCbC6Fqk3%2ByLU9xVtmZ72iTVG5bP9FDBK0SnxHrxef2cDczcr%2FA5MP1TaGNhjnBEgxBLOOooy5%2Bo3xiix1ZpqsAz2eZxxm3kJn6n92xR96aSc8XJ0C0E5pYeWHVqMoldsFC%2F8C7IBxaTTcsvswmF0LHxXCfEXTJpYINNV1WmijD%2F%2FJxeR2etUuvkkuBLLcS8bDvE%2F7YrS2SUfHolv0MqtDBwXjQtQ%3D%3D',
              value:
                'https://storage.googleapis.com/idfy-kyc-staging-kyc-gateway/fdc02592-7789-4f18-9179-8ba03ef61c62?Expires=1581004503&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=gvKCWMB3DiSVZJ29OZbfSeQkqkt9hPzEOVo9DSQTOIUWYzTy0xfadQJMkhWW2xglf4PWnAYS42kI0YABnk3gnVsLKbhL3wZGOhEJD4ujyawjns%2FzCPdFKE8OvYy7n%2FWflM98dIiLmcCg5dmUdMcgyCMQSdJM1aqBGYGn%2BJdLYEu91ufYQ6lpmH9Gho5yqCQI0CgvqOyP%2F5AePjMQx4HWfirOvI4w7MlFQjVtYpZSarxESGYy1lig24TkJhydupqQdpH1iQrWaJ9EB3zOqjDiKX%2FgaZ8ejzSvPB%2FgvLVmKA4FhPZgt3rKfjW%2F3ziTsiUeUDCqEGOFzuDuWYW9yyhLzQ%3D%3D',
            },
            'nil.ind_aadhaar_xml.share_code.1.nil': {
              category: null,
              document_type_key: 'ind_aadhaar_xml',
              error: null,
              fact_type_key: 'share_code',
              key: 'nil.ind_aadhaar_xml.share_code.1.nil',
              present: false,
              side: null,
              source: 1,
              tags: null,
              type: CAPTURE_TEMPLATES_TEXT,
              value: null,
            },
            'nil.nil.name.1.nil': {
              category: null,
              document_type_key: null,
              error: null,
              fact_type_key: 'name',
              key: 'nil.nil.name.1.nil',
              present: false,
              side: null,
              source: 1,
              tags: null,
              type: CAPTURE_TEMPLATES_TEXT,
              value: null,
            },
            'nil.selfie.nil.1.nil': {
              category: null,
              document_type_key: 'selfie',
              error: null,
              fact_type_key: null,
              key: 'nil.selfie.nil.1.nil',
              present: false,
              side: null,
              source: 1,
              tags: null,
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url:
                'https://storage.googleapis.com/idfy-kyc-staging-kyc-gateway/51ccf97a-6ca8-40d0-94f9-4cc366345164?Expires=1581004503&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=3wzAfTn5GIdqEFszBzHbf6Y33rfZlTOvj6s6dM0qg4oPZdreRyTa9FFbX7Q0unzGCzbiQtpWw7tGCbmWdXYvHtFup8wot6eOhvrg1Kn1gtQmAdj3OLzZx3G5N%2FSho0AxvAwKbt48EpZio5wlvvzHGBW4TqztPpFf0X7bXVidVHo%2Ft305jWIDfEYEN%2BMiNsxhqtPijp33YMg5jazc2U2pfxoY1d6Bg%2B7W0elLlwvcdZn%2FEffpHaE7FJc2k%2F6pGTHyYxGyKpKJ3EX3u6bhDbWGW%2FlCyWThg9zCrSENrAhqVW%2FjhZ2VmYB%2FSfFTuRtX0gyhb7PHLNCp7nX8twDk%2BqY0Jw%3D%3D',
              value:
                'https://storage.googleapis.com/idfy-kyc-staging-kyc-gateway/51ccf97a-6ca8-40d0-94f9-4cc366345164?Expires=1581004503&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=xZUiJDSOZTVEgxK0HxEWVMrYWSTx0H8535caL1mIkxioocOrHbbOCPytSm5ZuARIUXoY4Y5SvYLxbqs6XUVx6%2BoFZQKMnGLqe6CA25oEu1eXIRL9tG8bqCMBd5uemkUbwt14ERZuznaBw4Mjc4omXtTxWuBdU5VsBeGgK4xEhVzLYY%2F7FyHXtO0uKFnDvZZt16y7VnhwtJlwaA1RIRa9tWaoU9Fxr7Wk16fC5rcdEJHgNy3vFbeiNPRgK%2BG%2F%2FlOIBBCoDllbISQvpnWoorXPhO%2BFJEmo19zRwGqGAiEzhUzQ7nYXQokZBCNsAp4OaDJyBE75C2SvQFv%2BC30XVRJHHg%3D%3D',
            },
            'poa.nil.nil.1.back': {
              category: 'poa',
              document_type_key: null,
              error: null,
              fact_type_key: null,
              key: 'poa.nil.nil.1.back',
              present: false,
              side: 'back',
              source: 1,
              tags: null,
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url:
                'https://storage.googleapis.com/idfy-kyc-staging-kyc-gateway/1c150338-7441-4658-a837-2b50480252a6?Expires=1581004503&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=EaPJlgfi8MYCtNSz6pl7E8xhb8G90sNVvf89x8GqGrZ%2BavFG14iXNpBiB4YLYPN7%2B8CtCAMaiHeAuCDg1pPeF%2Bjs1BP8ZDaQuFiorbJzykwmPbqsom%2FHbf99E9dTPZtJKDHtI5EYltjLBlpXauU8u1LZOqIJvmSfMG1VkcI3vX2iLKRh1WkGWrXqoxMyZ0MXq5qdFYIDmgc0l8n1zhIUD5PIkVj0tFjpycA4CB1GX1CC6EzpKMl2YXgEpuVW7yvvZTZCaQkQKA4divc9ViG9epmFLLljsOLiI64fI1a6E1t7v5Z9GwOQN1Tw0J6SYf4E4MfZ2l6OUdzWSSMGjRWs3w%3D%3D',
              value:
                'https://storage.googleapis.com/idfy-kyc-staging-kyc-gateway/1c150338-7441-4658-a837-2b50480252a6?Expires=1581004503&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=thZV9IN27QPmf6GbvAtaUcVRYakt0sq0RW%2Fh%2B7eplA2fR9XsXRxSdAna7UkTBQcSWPrXTkmaG%2FQgXmyiZMX%2FYpavEQ5udLpTvifPN1%2FgbuGCfd9YTW7bKKOY4XVVox4Lies5Rp8B39UMKLGu52cq7jF1NOly6YMPaKyEYI0NBLG3RwI6dG90k3i3fS9pvMGQQ0AyuOc2yCLKTSXU4VHJ8re6XWHcR24Gp5UMdFPTDKenVXHMnnIqZS0CL1DGmSr0XoC7svzKTOuH3z7mCKIizV8Yk7mbe51V4WavapRYqk9Kj5Csb6oP%2FZ%2FbzKuXQ4xCbo0Auaj6czlz0nWMmpLGMQ%3D%3D',
            },
            'poa.nil.nil.1.front': {
              category: 'poa',
              document_type_key: null,
              error: null,
              fact_type_key: null,
              key: 'poa.nil.nil.1.front',
              present: false,
              side: 'front',
              source: 1,
              tags: null,
              type: CAPTURE_TEMPLATES_IMAGE,
              upload_url:
                'https://storage.googleapis.com/idfy-kyc-staging-kyc-gateway/a529fd9d-febe-4b33-8e76-b5192218534c?Expires=1581004503&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=lu3C%2BH6keXsi3HYLN4RGXQ2egDbIOSwTIuhEbvBS5Rce0q%2BaKOCWK7wOZtVmuNGYOphmIpDMGXIQ6b6usolIxTImoeeY7ox0zA9qw5jsuSzi1KSv%2FAwBOWLzYtVIglvLSAp6QX%2FYr9OZYYlVyDPTNnvVeGMrUgQv5WH4XsoYQQyNUiJy74u40gKmK33cGGBY%2FtHingox%2F%2FEBh%2FPauzDuEzqHxYdwyyfHGEbgVnfP83%2BVKcPFashI8ubwj9ql7JtKe44QivDdUv84SZYz3ZjFA9BDfWA01E5bo%2B98nlJdm4Mk7wO%2F4ewimH%2BUXxasm2zUnKcJGKhixWjSY%2Bpcnz2Ofg%3D%3D',
              value:
                'https://storage.googleapis.com/idfy-kyc-staging-kyc-gateway/a529fd9d-febe-4b33-8e76-b5192218534c?Expires=1581004503&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=v4F7lymvYEmabnWg66%2BoKJYTRPMsk2M9wAXP79cj1pqJAotzXJmFQKdgpaVPVJPJs0Si9AirFtEJOrnPfpENbySCvleXcZTpXfRNTdz8NkICWkFwrhz3sw36DHPIqUq4FPd3CR4%2BPGu3VaT9FoUPgxM1ASxVCO03dDi4ho2VQPcb5jWeiAsVC793Rt4R5NLjx5gAQtYpIGYZDA2q3lgIWTqdjcmiqng4X9gwSGOfc%2B2HchL5cMeD5L3QKUwsF46wXApo5fXekPw2WHfj1VQ2P%2FIg14jcpbOSl1%2FmERXPCG5gnhT6qQYAPe84Pp%2Bq2QKfq8z9PiOCdsXE8BqUx401FA%3D%3D',
            },
          },
          tasks: {
            key30: {
              key: 'key30',
              request_uid: null,
              status: 'pending',
              task_type_key: CAPTURE_TASKS_VERIFY_AADHAAR_XML,
            },
          },
          templates: {
            '6548': {
              allow_upload: false,
              artifacts: ['poa.nil.nil.1.front'],
              category_type: 'poa',
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 6548,
              label: null,
              mandatory: true,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: 'front',
              task_key: 'key24',
              task_type: CAPTURE_TASKS_DATA_CAPTURE,
              title: null,
              type: CAPTURE_TEMPLATES_IMAGE,
            },
            '6549': {
              allow_upload: false,
              artifacts: ['nil.ind_aadhaar_xml.share_code.1.nil'],
              category_type: null,
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 6549,
              label: 'Share code',
              mandatory: true,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: '^[0-9]{4}$',
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: null,
              task_type: CAPTURE_TASKS_DATA_CAPTURE,
              title: null,
              type: CAPTURE_TEMPLATES_TEXT,
            },
            '6550': {
              allow_upload: false,
              artifacts: ['poa.nil.nil.1.back'],
              category_type: 'poa',
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 6550,
              label: null,
              mandatory: true,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: 'back',
              task_key: 'key25',
              task_type: CAPTURE_TASKS_DATA_CAPTURE,
              title: null,
              type: CAPTURE_TEMPLATES_IMAGE,
            },
            '6551': {
              allow_upload: false,
              artifacts: ['nil.nil.name.1.nil'],
              category_type: null,
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 6551,
              label: 'First Name',
              mandatory: true,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: null,
              task_type: CAPTURE_TASKS_DATA_CAPTURE,
              title: null,
              type: CAPTURE_TEMPLATES_TEXT,
            },
            '6552': {
              allow_upload: false,
              artifacts: ['nil.selfie.nil.1.nil'],
              category_type: 'selfie',
              dependencies: [],
              detail: null,
              document_type_key: 'selfie',
              fact_type_key: null,
              help_text: null,
              id: 6552,
              label: null,
              mandatory: true,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: 'key6',
              task_type: CAPTURE_TASKS_DATA_CAPTURE,
              title: null,
              type: CAPTURE_TEMPLATES_IMAGE,
            },
            '6554': {
              allow_upload: false,
              artifacts: ['nil.ind_aadhaar_xml.nil.1.nil'],
              category_type: null,
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 6554,
              label: 'Upload XML File',
              mandatory: true,
              options: [
                'application/x-compressed',
                'application/x-zip-compressed',
                'application/zip',
                'multipart/x-zip',
              ],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: null,
              task_type: CAPTURE_TASKS_DATA_CAPTURE,
              title: null,
              type: CAPTURE_TEMPLATES_FILE_PICKER,
            },
            '6555': {
              allow_upload: false,
              artifacts: [],
              category_type: 'poa',
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 6555,
              label: null,
              mandatory: false,
              options: [
                'ind_aadhaar',
                'ind_driving_license',
                'ind_voter_id',
                'ind_passport',
              ],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: null,
              task_type: CAPTURE_TASKS_GROUPED,
              title: null,
              type: CAPTURE_TEMPLATES_GROUP,
            },
            '6556': {
              allow_upload: false,
              artifacts: [],
              category_type: null,
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 6556,
              label: 'Get XML',
              mandatory: true,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: null,
              task_type: CAPTURE_TASKS_FETCH_AADHAAR_XML,
              title: null,
              type: CAPTURE_TEMPLATES_ANCHOR,
            },
            '6558': {
              allow_upload: false,
              artifacts: [],
              category_type: null,
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 6558,
              label: 'Verify Aadhaar XML',
              mandatory: false,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: 'key30',
              task_type: CAPTURE_TASKS_VERIFY_AADHAAR_XML,
              title: null,
              type: CAPTURE_TEMPLATES_FORM,
            },
            '6559': {
              allow_upload: false,
              artifacts: [],
              category_type: null,
              dependencies: [],
              detail: 'Select a valid Address Proof',
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 6559,
              label: null,
              mandatory: false,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: null,
              task_type: null,
              title: 'Address Proof',
              type: CAPTURE_TEMPLATES_CARD,
            },
            '6560': {
              allow_upload: false,
              artifacts: [],
              category_type: null,
              dependencies: [],
              detail: 'Capture selfie',
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 6560,
              label: null,
              mandatory: false,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: null,
              task_type: null,
              title: 'Selfie',
              type: CAPTURE_TEMPLATES_CARD,
            },
            '6562': {
              allow_upload: false,
              artifacts: [],
              category_type: null,
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 6562,
              label: null,
              mandatory: false,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: null,
              task_type: null,
              title: 'Aadhaar XML Upload',
              type: CAPTURE_TEMPLATES_CARD,
            },
          },
          allowed_poa_documents: [
            'ind_aadhaar',
            'ind_driving_license',
            'ind_voter_id',
            'ind_passport',
          ],
          allowed_poi_documents: [],
          capture: [
            [
              {
                tasks: [
                  {
                    task_type: CAPTURE_TASKS_GROUPED,
                    tasks: [
                      {
                        artifacts: ['poa.nil.nil.1.front'],
                        task_key: 'key24',
                        task_type: CAPTURE_TASKS_DATA_CAPTURE,
                        template_id: 6548,
                      },
                      {
                        artifacts: ['poa.nil.nil.1.back'],
                        task_key: 'key25',
                        task_type: CAPTURE_TASKS_DATA_CAPTURE,
                        template_id: 6550,
                      },
                    ],
                    template_id: 6555,
                  },
                ],
                template_id: 6559,
              },
              {
                tasks: [
                  {
                    artifacts: ['nil.selfie.nil.1.nil'],
                    task_key: 'key6',
                    task_type: CAPTURE_TASKS_DATA_CAPTURE,
                    template_id: 6552,
                  },
                ],
                template_id: 6560,
              },
              {
                tasks: [
                  {
                    task_key: 'key30',
                    task_type: CAPTURE_TASKS_VERIFY_AADHAAR_XML,
                    tasks: [
                      {
                        artifacts: ['nil.ind_aadhaar_xml.nil.1.nil'],
                        task_type: CAPTURE_TASKS_DATA_CAPTURE,
                        template_id: 6554,
                      },
                      {
                        task_type: CAPTURE_TASKS_FETCH_AADHAAR_XML,
                        template_id: 6556,
                      },
                      {
                        artifacts: ['nil.ind_aadhaar_xml.share_code.1.nil'],
                        task_type: CAPTURE_TASKS_DATA_CAPTURE,
                        template_id: 6549,
                      },
                      {
                        artifacts: ['nil.nil.name.1.nil'],
                        task_type: CAPTURE_TASKS_DATA_CAPTURE,
                        template_id: 6551,
                      },
                    ],
                    template_id: 6558,
                  },
                ],
                template_id: 6562,
              },
            ],
          ],
          page_sequence: ['capture'],
        },
      },
    };
  };

  private static mockDigiLockerWithinImage = (): any => {
    return {
      data: {
        config: {
          artifacts: {
            'nil.ind_aadhaar.nil.1.nil': {
              category: null,
              document_type_key: 'ind_aadhaar',
              error: null,
              fact_type_key: null,
              key: 'nil.ind_aadhaar.nil.1.nil',
              location: {},
              metadata: {},
              present: false,
              side: null,
              source: 1,
              tags: null,
              type: 'image',
              upload_url:
                'https://e1dc150ac707.storage.pg.idfystaging.com/03f4d86b-190e-4617-892c-43284833c4bb?Expires=1634539490&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=eeZRJtIGTrwtieE67qixQcDWdlZs5bSOTyrLvKGl%2FIMumYGHqVjYVU0IhQG1F%2BZbGDKa93NBeMbQFiRd0D6Eu47Me3DP53WIMmwypFqYZOxNN0j6kCOkQ8BSB5HC2n2P4%2BCE9VjR%2FbL%2FCdBojQd2oyCppYDQ7aBj9uJz95IKuhwW1fo6H9hefYUKruzskg6N3iAzp%2BOfYRt%2F3kCHM3YcfU7fU5TuPBDklNwHx1pu47%2B3hhMKz5LbJfSFHQbmBRTu5WPDNQrdQCvtb8FBDY%2BRQSZIkkjVne5nNzbbn4x%2FOa2NmyLqKBVwEc%2BDYuCyQufF4bw4%2F%2FH1qKwqyFZJDyikqw%3D%3D',
              value:
                'https://e1dc150ac707.storage.pg.idfystaging.com/03f4d86b-190e-4617-892c-43284833c4bb?Expires=1634539490&GoogleAccessId=kyc-gateway-staging%40idfy-kyc-staging.iam.gserviceaccount.com&Signature=f0OT9Iqetx2LscCGYdHzsX%2BeOCWCJAd2PoFN0hQQtYPB5dr3fAreoDZBcCdXt4vn21%2B%2BmP%2FBNXSZ4SKsYkMHnKl6MR1Xi9ZsEY8mazReChskdvGv3ptUd5aZpRSRy2P%2FjcKWfB1BDp8vdcPg6EyA5pVTkw5p1gMrKTMIZqGG2yHJvQEfppKtJUt0vyh4y4FYrIvObVLMHkLNutTULcVfvxZHmFFVbjMF5KUK0tpe1IWDvk2I8BwacC%2FDLOG3X06MQl0DZJcpCQpjSmUURTp6IAPH0NESM74EKJdmx7jq3e73m0N2usUCpzPP5OARazehpCbxNr%2F3mhEuuuQl9cNhuQ%3D%3D',
            },
          },
          tasks: {},
          templates: {
            '53900': {
              allow_upload: true,
              artifacts: ['nil.ind_aadhaar.nil.1.nil'],
              capture_mechanisms: {
                digilocker: {
                  details: [],
                  sources: [],
                },
                camera: {
                  details: [],
                  sources: ['front'],
                },
                upload: {
                  details: [],
                  sources: [],
                },
              },
              category_type: null,
              dependencies: [],
              detail: null,
              document_type_key: 'ind_aadhaar',
              fact_type_key: null,
              help_text: null,
              id: 53900,
              label: 'Aadhaar card',
              mandatory: true,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: 'key_aadhaar',
              task_type: 'data_capture.data_capture',
              title: null,
              type: 'image',
            },
            '53901': {
              allow_upload: false,
              artifacts: [],
              capture_mechanisms: {},
              category_type: null,
              dependencies: [],
              detail: null,
              document_type_key: null,
              fact_type_key: null,
              help_text: null,
              id: 53901,
              label: null,
              mandatory: false,
              options: [],
              overlay_type_id: null,
              placeholder: null,
              refs: [],
              regex: null,
              remarks_mandatory_for: [],
              request_data: null,
              response_ref: [],
              settings: {},
              side: null,
              task_key: null,
              task_type: null,
              title: 'Aadhaar Details ',
              type: 'card',
            },
          },
          allowed_poa_documents: [],
          allowed_poi_documents: [],
          capture: [
            [
              {
                tasks: [
                  {
                    artifacts: ['nil.ind_aadhaar.nil.1.nil'],
                    task_key: 'key_aadhaar',
                    task_type: 'data_capture.data_capture',
                    template_id: 53900,
                  },
                ],
                template_id: 53901,
              },
            ],
          ],
          geotag: {
            images: false,
          },
          page_sequence: [
            {
              page: 'capture',
              validations: [],
            },
            {
              page: 'vkyc.assisted_vkyc',
              validations: [],
            },
          ],
          reverse_geocoding_provider: null,
          validation_config: {},
        },
        page: 'capture',
      },
      validations: [],
    };
  };

  private static mockPrerequisitePage = (): any => {
    return {
      data: {
        config: {
          title: 'initiate your verification from mock',
          prerequisite_items: ['Well-lit surrounding', 'Noise-free background'],
          display_as_checklist: true,
          header: 'Header from mock',
          subtitle: 'Subtitle from mock',
        },
        page: 'prerequisite',
      },
      validations: [],
    };
  };

  static getTaskData(): any {
    return {
      validations: ['location'],
    };
  }

  static getPermissionResponseAsDenied() {
    return {
      location: PERMISSION_DENIED,
      camera: PERMISSION_DENIED,
      microphone: PERMISSION_DENIED,
    };
  }

  static getPermissionResponseAsGranted() {
    return {
      location: PERMISSION_GRANTED,
      camera: PERMISSION_GRANTED,
      microphone: PERMISSION_GRANTED,
    };
  }

  // Self Video api mocks
  static fetchTaskStatus(): any {
    return {
      data: {
        status: 'incomplete',
        session_id: 'abcdefg',
        prerequisite_card: {
          box: false,
          title: '',
          prerequisite_items: [],
          display_as_checklist: false,
        },
      },
    };
  }

  static fetchTaskDetails(): any {
    return {
      status: '',
      session_id: '',
    };
  }

  static svTaskStarted(): any {
    return {
      data: {
        room_id: 'e203c434-7aec-4444-b955-f8a65cb2bcd8',
        participant_id: 'a661fcfa-6506-438e-9610-4a034d2b69fc',
        activity_count: 3,
      },
    };
  }

  static svFetchActivity(index: number): AxiosResponse {
    const actArray = [
      {
        type: 'read_random_digits',
        data: Math.floor(1000 + Math.random() * 9000).toString(),
        id: '2456',
        tasks: [
          {
            id: '123456789',
            provider: 'server',
            value: '',
            type: 'digit_auth',
          },
          {
            id: '123456789',
            provider: 'client',
            value: null,
            type: 'face_presence',
          },
        ],
      },
      {
        type: 'read_text',
        data: 'hello there',
        activity_id: '24567',
        tasks: [
          {
            id: '123456789',
            provider: 'client',
            value: null,
            type: 'face_presence',
          },
        ],
      },
      {
        type: 'show_document',
        data: 'capture front of PAN card',
        header: 'Capture document',
        activity_id: '24568',
        tasks: [],
      },
    ];

    return {
      config: {},
      status: 200,
      statusText: 'OK',
      headers: {},
      data: actArray[index],
    };
  }

  static initiateActivityTasks(): AxiosResponse {
    return {
      config: {},
      status: 200,
      statusText: 'OK',
      headers: {},
      data: {
        performed_correctly: true,
        attempts: 1,
        limit: -1,
      },
    };
  }

  /**
   * Function returns mock axios response with empty data
   */
  static staticAxiosResponse(data?: any): AxiosResponse {
    return {
      config: {},
      status: 200,
      statusText: 'OK',
      headers: {},
      data: data ? data : {},
    };
  }

  static blacklistedBrowsers(): any {
    return {
      miuibrowser: true,
      vivobrowser: true,
      heytapbrowser: true,
      oppobrowser: true,
      realmebrowser: true,
      edge: [
        {
          os: ['Android'],
          max: '0',
        },
      ],
      firefox: [
        {
          include: ['64', '66', '68', '78', '79', '80'],
        },
      ],
      opera: [
        {
          include: ['44', '50', '51', '56'],
        },
      ],
      samsungbrowser: [
        {
          include: ['7', '8'],
        },
      ],
      chrome: [
        {
          min: '59',
          exclude: ['55'],
        },
        {
          include: ['83'],
          brand: ['Vivo'],
        },
      ],
      'chrome-webview': [
        {
          min: '59',
          exclude: ['55'],
        },
        {
          include: ['83'],
          brand: ['Vivo'],
        },
      ],
    };
  }

  // Mocks for generic page
  static mockGenericPageSequence(): _CaptureSequenceNew {
    return {
      page_sequence: [
        {
          page: 'generic_page',
          page_key: 'generic_page1',
          validations: [],
          hasConfig: false,
        },
        {
          page: 'generic_page',
          page_key: 'generic_page2',
          validations: [],
          hasConfig: false,
        },
        {
          page: 'generic_page',
          page_key: 'generic_page3',
          validations: [],
          hasConfig: false,
        },
        {
          page: 'generic_page',
          page_key: 'generic_page4',
          validations: [],
          hasConfig: false,
        },
      ],
      settings: {
        blacklisted_browsers: {
          chrome: [
            {
              exclude: ['55'],
              min: '59',
            },
            {
              include: ['83'],
              os: ['Android11'],
            },
          ],
          edge: '18',
          firefox: [
            {
              include: ['64', '66', '68', '78', '79', '80'],
            },
          ],
          heytapbrowser: true,
          miuibrowser: true,
          opera: [
            {
              include: ['44', '50', '51', '56'],
            },
          ],
          oppobrowser: true,
          realmebrowser: true,
          samsungbrowser: [
            {
              include: ['7', '8'],
            },
          ],
          vivobrowser: true,
        },
        camera: true,
        file_upload: true,
        ios_version: true,
        location: true,
      },
      validation_config: {},
    };
  }

  static mockGenerateOTPTaskResponse(): any {
    return {
      data: [
        {
          key: 'nil.nil.mobile_numbers.1.nil',
          type: 'artifact',
          error: '',
        },
        {
          key: 'generate_otp',
          type: 'task',
          error: '',
          result: {
            mobile_number: 'xxxxxxxxxx',
            status: 'otp_sent',
            message: 'THIS COMES FROM GUPSHUP',
            changes: [],
          },
        },
      ],
    };
  }

  static mockValidateOTPTaskResponse(): any {
    return {
      data: [
        {
          key: 'nil.nil.mobile_numbers.1.nil',
          type: 'artifact',
          error: '',
        },
        {
          key: 'validate_otp',
          type: 'task',
          error: 'Attempts exhausted, Pleae retry',
          result: {
            automated_response: {
              attempts_exhausted: true,
            },
          },
        },
      ],
    };
  }

  static mockAadharExtraction(): ValidateResponseType {
    return {
      data: [
        {
          key: 'nil.ind_aadhaar_xml.nil.1.nil',
          type: 'artifact',
          error: '',
        },
        {
          key: 'extract.poa',
          type: 'task',
          error: '',
          result: {
            automated_response: {
              extraction_output: {
                address: '<ADDRESS>',
                age: '<AGE>',
                date_of_birth: 'YYYY-MM-DD',
                district: null,
                fathers_name: 'JOHN DOE',
                gender: 'MALE',
                house_number: 'Hno.25',
                id_number: null,
                is_scanned: null,
                name_on_card: 'JOHN DOE',
                pincode: '<PINCODE>',
                state: '<STATE>',
                street_address: '<STREET ADDRESS>',
                year_of_birth: null,
              },
            },
            manual_response: {
              extraction_output: {},
            },
            changes: [],
          },
        },
      ],
    };
  }
}
