import React from 'react';
import { DataService } from '../../services/DataService';
import { logPageVisit } from '../../helpers/loggerWrapper';
import { getTatStartRef } from '../../helpers';
import SimpleOverlay from '../SimpleOverlay';
import i18n from '../../i18n/i18n';
import ClickTracker from '../../helpers/ui-click-event';

const translate = i18n.t.bind(i18n);
export default class PoorConnectionComponent extends React.Component {
  loggerStartTime: number = getTatStartRef();

  componentDidMount(): void {
    ClickTracker?.setComponentName?.('PoorConnectionComponent');
    logPageVisit('PoorConnectivity', 'PoorConnectionComponent');
    DataService.EndCommunications().then(
      // eslint-disable-next-line
      () => {},
      // eslint-disable-next-line
      () => {},
    );
  }

  componentWillUnmount(): void {
    ClickTracker?.unsetComponentName?.();
  }

  render(): JSX.Element {
    return (
      <SimpleOverlay
        showHeader
        titleConfig={{
          primaryText: translate('POOR_CONNECTION'),
          secondaryText: translate('POOR_CONNECTION_MESSAGE'),
          tertiaryText: translate('ERROR_REST_ASSURED_MESSAGE'),
          titleSvg: 'somethingWentWrong',
        }}
        showCrossButton={false}
      />
    );
  }
}
