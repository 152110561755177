import {
  AVKycPageData,
  SaveAVKycPageConfigActions,
  AVKycStatusDetail,
  AgentDisposition,
  UploadProgress,
} from './types';
import {
  SAVE_AGENT_DISPOSITION,
  SAVE_AVKYC_PAGE_CONFIG,
  SAVE_AVKYC_STATUS,
  SAVE_UPLOAD_PROGRESS,
} from '../../../helpers/actionTypes';

export function SaveAVKycPageConfigAction(
  newPageData: AVKycPageData,
): SaveAVKycPageConfigActions {
  return {
    type: SAVE_AVKYC_PAGE_CONFIG,
    payload: newPageData,
  };
}

export function SaveAVKycStatusAction(
  newStatus: AVKycStatusDetail,
): SaveAVKycPageConfigActions {
  return {
    type: SAVE_AVKYC_STATUS,
    payload: newStatus,
  };
}

export function SaveAgentDispositionAction(
  newAgentDisposition: AgentDisposition,
): SaveAVKycPageConfigActions {
  return {
    type: SAVE_AGENT_DISPOSITION,
    payload: newAgentDisposition,
  };
}
export function SaveUploadProgressAction(
  newUploadProgress: UploadProgress,
): SaveAVKycPageConfigActions {
  return {
    type: SAVE_UPLOAD_PROGRESS,
    payload: newUploadProgress,
  };
}
