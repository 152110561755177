import { IndvCaptureStatusActions, IndvCaptureStatus } from './types';
import { UPDATE_STATUS } from '../../../helpers/actionTypes';

const initialState: IndvCaptureStatus = {
  status: '',
  status_details: '',
};

/**
 * Reducer function for storing Individual Capture Status
 * @param state Previous state from the store
 * @param action Action to be performed
 * @returns Returns IndvCaptureStatus
 */
export default function IndvCaptureStatusReducer(
  state = initialState,
  action: IndvCaptureStatusActions,
): IndvCaptureStatus {
  switch (action.type) {
    case UPDATE_STATUS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
