import { ArtifactActions } from './types';
import { ArtifactDetails } from '../../components/Capture/store/types';
import { STORE_ARTIFACTS, UPDATE_ARTIFACTS } from '../../helpers/actionTypes';

const initialState: ArtifactDetails = {};

/**
 * Reducer for storing Artifact Details
 * @param state Previous state from the store
 * @param action Action to be performed
 */
export default function ArtifactReducer(
  state = initialState,
  action: ArtifactActions,
): ArtifactDetails {
  switch (action.type) {
    case STORE_ARTIFACTS:
      return action.payload;
    case UPDATE_ARTIFACTS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
