import { ReloadFlag, HandleReloadActions } from './types';
import { UPDATE_RELOAD_STATUS } from '../../helpers/actionTypes';

const initialState: ReloadFlag = {
  value: false,
};

/**
 * Reducer function for maintaining reload flag to hanlde reload Event
 * @param state Previous state from the store
 * @param action Action to be performed
 * @returns Returns ReloadFlag
 */
export default function HandleReloadReducer(
  state = initialState,
  action: HandleReloadActions,
): ReloadFlag {
  switch (action.type) {
    case UPDATE_RELOAD_STATUS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
