import { SCHEDULING_CONFIG } from '../../helpers/actionTypes';
import { SchedulingInfo } from '../../components/IndvCapture/store/types';
import { HandleSchedulingActions } from './types';

const initialState: SchedulingInfo = {
  booking_details: {
    reference_id: '',
  },
  enabled: false,
  end_date: '',
  slots_available: false,
  start_date: '',
};

/**
 * Reducer function for storing the scheduling details
 * @param state Previous state from the store
 * @param action Action to be performed
 * @returns Returns schedulingInfo
 */
export default function HandleSchedulingReducer(
  state = initialState,
  action: HandleSchedulingActions,
): SchedulingInfo {
  switch (action.type) {
    case SCHEDULING_CONFIG:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
