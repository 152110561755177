import { RedirectUri, RedirectUriActions, ExitUri } from './types';
import { SAVE_REDIRECT_URI, SAVE_EXIT_URI } from '../../helpers/actionTypes';

const initialState: RedirectUri = {
  uri: '',
};

const exitUriInitialState: ExitUri = {
  exit_uri: '',
};

/**
 * Reducer for storing Redirect URI Details
 * @param state Previous state from the store
 * @param action Action to be performed
 */
export function RedirectUriReducer(
  state = initialState,
  action: RedirectUriActions,
): RedirectUri {
  switch (action.type) {
    case SAVE_REDIRECT_URI:
      return {
        ...state,
        uri: action.payload.uri,
      };
    default:
      return state;
  }
}

export function ExitUriReducer(
  state = exitUriInitialState,
  action: RedirectUriActions,
): ExitUri {
  switch (action.type) {
    case SAVE_EXIT_URI:
      return {
        ...state,
        exit_uri: action.payload.exit_uri,
      };
    default:
      return state;
  }
}
