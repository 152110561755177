import { CaptureConfigActions, CaptureConfigDetails } from './types';
import { CAPTURECONFIG } from '../../../helpers/actionTypes';

/**
 * Action Creator
 * @param newCaptureDetails update value for action
 * @returns CaptureConfigActions
 */
export default function CaptureConfigAction(
  newCaptureDetails: CaptureConfigDetails,
): CaptureConfigActions {
  return {
    type: CAPTURECONFIG,
    payload: newCaptureDetails,
  };
}
