import React from 'react';
import { connect } from 'react-redux';
import './RemovedFromQueue.css';
import { buttonActionTypes } from '../InstructionBasedOverlay';
import { AppState } from '../../store';
import { ClearOverlayAction } from '../../store/Error/actions';
import { RedirectUri } from '../../store/RedirectUri/types';
import { HeaderDetails } from '../Header/store/types';
import i18n from '../../i18n/i18n';
import { Box, Button, Typography } from '@material-ui/core';
import { DataService } from '../../services/DataService';
import AppBar from '../AppBar';
import { getTatStartRef } from '../../helpers';
import { LogLevel, log, logPageVisit } from '../../helpers/loggerWrapper';

const translate = i18n.t.bind(i18n);

interface OwnProps {
  actionCallback?: (actionType: buttonActionTypes) => void;
  headerConfig?: {
    appBackground: string;
    isErrorScreen: boolean;
    displayBackButton: boolean;
    displayExitButton?: boolean;
  };
  handleGoBack?: () => void;
  handleCrossBtn?: () => void;
  showCrossButton: boolean;
}

interface DispatchProps {
  ClearOverlayAction: typeof ClearOverlayAction;
}

interface StateProps {
  redirectUri: RedirectUri;
  headerDetails: HeaderDetails;
}

export interface Props extends OwnProps, DispatchProps, StateProps {}

interface State {
  displayLoader: boolean;
}

class RemovedFromQueue extends React.Component<Props, State> {
  startTime: number;
  constructor(props: Props) {
    super(props);
    this.onReconnectClick = this.onReconnectClick.bind(this);
    this.startTime = getTatStartRef();
    this.handlePageVisibilityChange =
      this.handlePageVisibilityChange.bind(this);
  }
  handlePageVisibilityChange(): void {
    if (!document.hidden) {
      log(
        LogLevel.Info,
        {
          serviceCategory: 'Capture',
          service: 'PageVisibility',
          eventType: 'RemovedFromQueue',
          eventName: 'Executed',
          component: 'RemovedFromQueue',
          eventSource: 'handlePageVisibilityChange',
          publishToDLK: true,
          referenceID: DataService.getAvTaskId(),
          referenceType: 'AV.TaskID',
        },
        {
          api: 'VisibilityChange',
          mdn_api: true,
          current_page: 'RemovedFromQueue',
          page_visibility: 'Foreground',
        },
      );
    }
  }
  componentDidMount(): void {
    document.addEventListener(
      'visibilitychange',
      this.handlePageVisibilityChange,
    );
    logPageVisit('RemovedFromQueue', 'RemovedFromQueue');
  }

  componentWillUnmount(): void {
    document.removeEventListener(
      'visibilitychange',
      this.handlePageVisibilityChange,
    );
  }

  onReconnectClick(action: buttonActionTypes): void {
    const { actionCallback } = this.props;
    log(
      LogLevel.Info,
      {
        serviceCategory: 'Capture',
        service: 'PageVisibility',
        component: 'RemovedFromQueue',
        eventName: 'Clicked',
        eventType: 'Retry',
        publishToDLK: true,
        eventSource: 'onReconnectClick',
        referenceID: DataService.getAvTaskId(),
        referenceType: 'AV.TaskID',
      },
      {
        av_session_id: DataService.getAvSessionId(),
        page_visibility: 'Foreground',
      },
    );
    actionCallback && actionCallback(action);
  }

  render(): JSX.Element {
    const { headerConfig, handleGoBack } = this.props;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        {headerConfig && (
          <AppBar
            handleBack={handleGoBack}
            UIConfig={{
              appBackground: headerConfig.appBackground,
              isErrorScreen: headerConfig.isErrorScreen,
              displayBackButton: headerConfig.displayBackButton,
              displayExitButton: headerConfig.displayExitButton,
            }}
          />
        )}
        <div className="call-dropped-content-parent">
          <div className="call-dropped-content-holder">
            <div className="call-dropped-image-bg">
              <img
                src="/images/call_end_red_button.svg"
                alt="customer-dropped"
                height="25%"
              ></img>
              <Typography variant="h6" style={{ color: '#131A25' }}>
                <Box fontWeight="fontWeightBold">
                  {translate('CALL_NOT_CONNECTED_TITLE')}
                </Box>
              </Typography>
              <Typography variant="body2" style={{ color: '#242426' }}>
                {translate('CALL_NOT_CONNECTED_SUBTITLE')}
              </Typography>
            </div>
          </div>
          <div className="proceedButton" style={{ width: '100%' }}>
            <Button
              className="idfyButton"
              color="primary"
              variant={'contained'}
              onClick={() => this.onReconnectClick('retry')}
            >
              {DataService.getSvg('retry')}
              <div style={{ marginLeft: '10px' }}>
                {translate('RETRY_CALL_CONNECTION')}
              </div>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  redirectUri: state.RedirectUri,
  headerDetails: state.HeaderDetails,
});

export default connect<StateProps, DispatchProps, OwnProps, AppState>(
  mapStateToProps,
  {
    ClearOverlayAction,
  },
)(RemovedFromQueue);
