import { SVServicePageData, SaveSVServiceTaskActions } from './types';
import { SAVE_SV_SERVICE_PAGE_CONFIG } from '../../../helpers/actionTypes';

export const initialState: SVServicePageData = {
  task: {
    key: '',
    request_uid: '',
    task_type_key: '',
    status: '',
  },
  title: '',
  text: '',
  additional_text: '',
  network_check_config: {
    enabled: false,
    timeout: 60000,
    checks: {
      room_join: false,
    },
    health_check_failure_rules: null,
  },
};
export function SaveSVServiceTaskReducer(
  state = initialState,
  action: SaveSVServiceTaskActions,
): SVServicePageData {
  switch (action.type) {
    case SAVE_SV_SERVICE_PAGE_CONFIG:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
