import { ResponseDetails, SaveResponseActions } from './types';
import { SAVE_RESPONSE } from '../../helpers/actionTypes';

const initialState: ResponseDetails = {
  full_name: '',
  package_name: '',
  status: '',
  status_detail: '',
  capture_id: '',
  scheduling_enabled: false,
  scheduling: {
    enabled: false,
    slots_available: false,
    booking_details: { reference_id: '' },
    start_date: '',
    end_date: '',
  },
  status_page: null,
  thank_you: null,
  out_of_office: {
    active: false,
    text: '',
    title: '',
  },
  task_statuses: {
    self_video: null,
    assisted_video: null,
  },
  fallback_to_location_via_ip_address: 'on_gps_location_failure',
  session_replay_enabled: false,
};

/**
 * Reducer function for maintaining api response
 * @param state Previous state from the store
 * @param action Action to be performed
 * @returns Returns user capture status and status details
 */
export default function saveResponseReducer(
  state = initialState,
  action: SaveResponseActions,
): ResponseDetails {
  switch (action.type) {
    case SAVE_RESPONSE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
