import { GroupAction, GroupDetails } from './types';
import { SAVE_GROUP } from '../../../helpers/actionTypes';

const initialState: GroupDetails = {
  header: {
    title: '',
    logo: '',
    logo_alt: '',
  },
  body: {
    info: {
      title: '',
      primary_text: '',
      secondary_text: '',
    },
    profiles: [],
  },
};

/**
 * Reducer function for storing Group Details
 * @param state Previous state from the store
 * @param action Action to be performed
 * @returns Returns GroupDetails
 */
export default function GroupReducer(
  state = initialState,
  action: GroupAction,
): GroupDetails {
  switch (action.type) {
    case SAVE_GROUP:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
