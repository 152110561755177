import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import './Header.css';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { HeaderDetails } from './store/types';
import { PaletteOptions, ThemeConfig } from '../../store/ThemeConfig/types';
import { ExitUri } from '../../store/RedirectUri/types';
import { log, LogLevel, logPageVisit } from '../../helpers/loggerWrapper';
import i18n from '../../i18n/i18n';
import { DataService } from '../../services/DataService';
import {
  ERROR_TYPE_INVALID_LINK,
  PAGE_SEQ_CAPTURE,
  PAGE_SEQ_GENERIC_PAGE,
} from '../../helpers/constants';
import { redirectTo } from '../../helpers';
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import { History } from 'history';
import { CaptureSequence } from '../IndvCapture/store/types';

interface OwnProps {
  history: History;
}

interface StateProps {
  HeaderDetails: HeaderDetails;
  ThemeConfig: ThemeConfig;
  ExitUri: ExitUri;
  CapSeq: CaptureSequence;
}

interface Props extends StateProps, OwnProps {}

interface State {
  showBackButton: boolean;
}

const translate = i18n.t.bind(i18n);
/**
 * Class Component for Header to show logo and page header
 * @param props
 */
class HeaderComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showBackButton: false,
    };
    this.handlePrev = this.handlePrev.bind(this);
    this.onCloseButtonClick = this.onCloseButtonClick.bind(this);
    this.props.history.listen((location, action) => {
      const { CapSeq } = this.props;
      let newResp = false;
      const currentIndex = location.state && location.state.captureIndex;
      const currentPageIndex = location.state && location.state.pageIndex;
      if (currentIndex > 0) {
        newResp = true;
      } else {
        newResp =
          currentPageIndex > 0 &&
          (CapSeq.pageSequence[currentPageIndex - 1]?.page ===
            PAGE_SEQ_CAPTURE ||
            CapSeq.pageSequence[currentPageIndex - 1]?.page ===
              PAGE_SEQ_GENERIC_PAGE);
      }
      this.setState({
        showBackButton: newResp,
      });
    });
  }

  /**
   * Throws Error when props are missing
   */
  componentDidMount(): void {
    const { HeaderDetails } = this.props;
    if (!HeaderDetails) {
      throw new Error('No Header Details Provided');
    }
  }

  onCloseButtonClick(): void {
    const { ExitUri } = this.props;
    log(LogLevel.Info, {
      serviceCategory: 'Capture',
      service: 'ExitJourney',
      eventName: 'CloseClicked',
      eventType: 'Clicked',
      eventSource: 'onCloseButtonClick',
      component: 'Header',
    });
    // eslint-disable-next-line
    if (window.confirm(translate('CONFIRM_EXIT_MESSAGE'))) {
      log(LogLevel.Info, {
        serviceCategory: 'Capture',
        service: 'ExitJourney',
        eventName: 'YesClicked',
        eventType: 'Clicked',
        eventSource: 'onCloseButtonClick',
        component: 'Header',
      });
      DataService.checkRedirectUrl()
        .then((resp) => {
          if (resp.data.valid) {
            log(LogLevel.Info, {
              serviceCategory: 'Capture',
              service: 'ExitJourney',
              eventName: 'YesClicked',
              eventType: 'Clicked',
              eventSource: 'onCloseButtonClick',
              component: 'Header',
            });
            redirectTo(ExitUri.exit_uri);
          } else {
            DataService.redirectToInvalidLink(resp.data.error);
            return;
          }
        })
        .catch((error) => {
          DataService.RaiseError(new Error('INVALID_REDIRECT_URL'), {
            errorServiceCategory: 'Capture',
            errorService: 'RedirectUrl',
            eventType: 'Exception',
            errorType: ERROR_TYPE_INVALID_LINK,
            errorSource: 'fetchCaptureDetails',
            component: 'IndvCaptureComponent',
            exceptionName: 'InvalidRedirectUrl',
            exceptionDescription: 'Redirect / Exit URL is not valid',
            metaData: {
              error,
            },
          });
          return;
        });
    } else {
      log(LogLevel.Info, {
        serviceCategory: 'Capture',
        service: 'ExitJourney',
        eventName: 'NoClicked',
        eventType: 'Clicked',
        eventSource: 'onCloseButtonClick',
        component: 'Header',
      });
    }
  }

  getThemeColors(colorType: string): string {
    const { ThemeConfig } = this.props;
    let color;
    const palette = ThemeConfig.palette as PaletteOptions;
    if (colorType === 'textColor') {
      if (ThemeConfig.custom_header && ThemeConfig.custom_header.color) {
        color = ThemeConfig.custom_header.color;
      } else {
        color = palette.secondary.contrastText;
      }
    } else if (
      ThemeConfig.custom_header &&
      ThemeConfig.custom_header.backgroundColor
    ) {
      color = ThemeConfig.custom_header.backgroundColor;
    } else {
      color = palette.secondary.main;
    }
    return color;
  }

  /**
   * Handles Click event of back button
   */
  handlePrev(): void {
    const { history } = this.props;
    if (history.location.state.captureIndex > 0) {
      logPageVisit(
        `Capture.${history.location.state.captureIndex - 1}`,
        'Capture',
        'handlePrev',
      );
      log(LogLevel.Info, {
        serviceCategory: 'Capture',
        service: 'RoutePage',
        eventType: `${PAGE_SEQ_CAPTURE}/${history.location.state.captureIndex}`,
        eventName: 'HistoryPop',
        component: 'Capture',
        eventSource: 'handlePrev',
      });
    }
    history.goBack();
  }

  render(): JSX.Element {
    const { HeaderDetails, ExitUri } = this.props;
    const { showBackButton } = this.state;
    return (
      <Box
        component="div"
        className="elevation1 HeaderContainer"
        style={{ backgroundColor: this.getThemeColors('backgroundColor') }}
      >
        {showBackButton && (
          <div className="header-back-button">
            <Button
              className="header-close-button-styling"
              onClick={this.handlePrev}
              disabled={false}
            >
              <ArrowBackSharpIcon
                style={{
                  color: this.getThemeColors('textColor'),
                  cursor: 'pointer',
                }}
                fontSize="small"
              />
            </Button>
          </div>
        )}
        <div className="logoDiv">
          <img
            className="logo"
            src={
              HeaderDetails.logo
                ? DataService.buildUrl(HeaderDetails.logo, 'GET')
                : ''
            }
            alt={HeaderDetails.logo_alt}
          />
        </div>
        <div className="details">
          <Typography
            variant="h5"
            style={{
              lineHeight: '0.8',
              color: this.getThemeColors('textColor'),
            }}
          >
            {HeaderDetails.title}
          </Typography>
        </div>
        {ExitUri.exit_uri && (
          <div className="header-close-button">
            <Button
              className="header-close-button-styling"
              onClick={this.onCloseButtonClick}
              disabled={false}
            >
              <CloseSharpIcon
                style={{ color: this.getThemeColors('textColor') }}
                fontSize="large"
              />
            </Button>
          </div>
        )}
      </Box>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  HeaderDetails: state.HeaderDetails,
  ThemeConfig: state.ThemeConfig,
  ExitUri: state.ExitUri,
  CapSeq: state.CaptureSequence,
});

export default connect(mapStateToProps)(HeaderComponent);
