import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'en',
    debug: false,
    keySeparator: false, // we do not use keys in form messages.welcome
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
      //  eslint-disable-next-line
      format: function (value: any, format: any, lng: any) {
        return value;
      },
    },
  });

export function translateNumbers(number: string | number): string {
  const num =
    typeof number === 'number'
      ? number.toString()
      : typeof number === 'string'
      ? number
      : '-';
  let output = '';
  for (let i = 0; i < num.length; i += 1) {
    const ch = num.charAt(i);
    if (i18n.t(ch)) {
      output += i18n.t(ch);
    } else {
      output += ch;
    }
  }
  return output;
}

export default i18n;
