import { CaptureConfigDetails, CaptureConfigActions } from './types';
import { CAPTURECONFIG } from '../../../helpers/actionTypes';

const initialState: CaptureConfigDetails = {
  tasks: {},
  templates: {},
  allowed_poi_documents: [],
  allowed_poa_documents: [],
  captureConfiguration: [],
  artifacts: {},
  geotagImages: false,
  check_tampered_pan: false,
  customer_ocr_edit: false,
  image_watermarking_config: null,
  title: '',
  disabled_button_help_text: [],
};

/**
 * Reducer for storing Capture Configuration Details
 * @param state Previous state from the store
 * @param action Action to be performed
 */
export default function CaptureConfigReducer(
  state = initialState,
  action: CaptureConfigActions,
): CaptureConfigDetails {
  switch (action.type) {
    case CAPTURECONFIG:
      return action.payload;
    default:
      return state;
  }
}
