import { History, Location } from 'history';
import { AadhaarXMLScrapedDetails } from '../store/AadhaarXMLScraping/types';
import AadhaarXMLScrapingAction from '../store/AadhaarXMLScraping/actions';
import { CaptureUIStore } from '../store';
import { ROUTE_OFFLINE_KYC } from './routes';
import { LogLevel, log } from './loggerWrapper';

export default class IntegrationService {
  static store: CaptureUIStore;

  static FetchAadhaarXML(
    history: History,
    taskKey: string,
    authTokenKey: string | null,
    authTokenValue: string | null,
  ): void {
    const histState = {
      pagePath: 'offline-kyc',
      pageIndex: 0,
      taskKey,
      authTokenKey,
      authTokenValue,
    };
    log(
      LogLevel.Info,
      {
        serviceCategory: 'Capture',
        service: 'RoutePage',
        eventType: 'OfflineKYC',
        eventName: '',
        component: 'integrations',
        eventSource: 'FetchAadhaarXML',
      },
      {
        historyState: histState,
      },
    );
    history.push({
      pathname: ROUTE_OFFLINE_KYC,
      search: window.location.search,
      state: histState,
    });
  }

  static AadhaarXMLDataBridge(data: AadhaarXMLScrapedDetails): void {
    IntegrationService.store.dispatch(AadhaarXMLScrapingAction(data));
  }

  static SetStore(store: CaptureUIStore): void {
    IntegrationService.store = store;
  }

  static AllowRouteChange(location: Location): boolean {
    return location.pathname === ROUTE_OFFLINE_KYC;
  }
}
