import { SAVE_PREREQUISITE_CONFIG } from '../../../helpers/actionTypes';
import { PrerequisiteActions, PrerequisitePageConfig } from './types';

const initialState: PrerequisitePageConfig = {
  title: '',
  prerequisite_items: [],
  display_as_checklist: true,
  header: '',
  subtitle: '',
  nextPage: '',
  disabled_button_help_text: '',
};

/**
 * Reducer function for storing Prerequisite page Details
 * @param state Previous state from the store
 * @param action Action to be performed
 * @returns Returns PrerequisitePageConfig
 */
export default function PrerequisiteReducer(
  state = initialState,
  action: PrerequisiteActions,
): PrerequisitePageConfig {
  switch (action.type) {
    case SAVE_PREREQUISITE_CONFIG:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
