import {
  ErrorDetail,
  ErrorActions,
  OverlayActions,
  RedirectingOverlayActions,
} from './types';
import {
  ERROR,
  CLEAR_ERROR,
  OVERLAY,
  CLEAR_OVERLAY,
  REDIRECTING_OVERLAY,
  CLEAR_REDIRECTING_OVERLAY,
} from '../../helpers/actionTypes';

export const initialState: ErrorDetail = {
  error: null,
};

/**
 * Reducer for storing Artifact Details
 * @param state Previous state from the store
 * @param action Action to be performed
 */
export function ErrorAndOverlayReducer(
  state = initialState,
  action: ErrorActions | OverlayActions | RedirectingOverlayActions,
): ErrorDetail {
  const { type, payload } = action;
  switch (type) {
    case ERROR:
      return payload;
    case CLEAR_ERROR:
      return { error: null };
    case OVERLAY:
      return payload;
    case CLEAR_OVERLAY:
      return { error: null };
    case REDIRECTING_OVERLAY:
      return payload;
    case CLEAR_REDIRECTING_OVERLAY:
      return { error: null };
    default:
      return state;
  }
}
