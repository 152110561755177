import { PermissionsActions, PermissionDetails } from './types';
import { SAVE_PERMISSION_CONFIG } from '../../../helpers/actionTypes';

/**
 * Action Creator
 * @param newPermissionConfDetails update value for action
 * @returns PermissionsActions
 */
export default function SavePermissionConfAction(
  newPermissionConfDetails: PermissionDetails,
): PermissionsActions {
  return {
    type: SAVE_PERMISSION_CONFIG,
    payload: newPermissionConfDetails,
  };
}
