import { Theme, createMuiTheme, Direction } from '@material-ui/core';
import i18n from './i18n/i18n';
import { DataService } from './services/DataService';
import { PaletteOptions } from './store/ThemeConfig/types';

export default function theme(): Theme {
  const themeConfig = DataService.fetchThemeConfigFromStore();
  let dir: Direction = i18n.dir();
  const config = {
    palette: themeConfig && (themeConfig.palette as PaletteOptions),
    typography: themeConfig && themeConfig.typography,
    overrides: themeConfig && themeConfig.overrides,
    custom_header: themeConfig && themeConfig.custom_header,
    direction: dir,
  };
  return createMuiTheme(config);
}
