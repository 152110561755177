/* eslint-disable */
export type DetectedInfoType =
  | 'browser'

interface DetectedInfo<
  T extends DetectedInfoType,
  N extends string,
  O,
  V = null
> {
  readonly type: T;
  readonly name: N;
  readonly version: V;
  readonly os: O;
}

export class BrowserInfo
  implements DetectedInfo<'browser', Browser, OperatingSystem | null, string> {
  public readonly type = 'browser';
  constructor(
    public readonly name: Browser,
    public readonly version: string,
    public readonly os: OperatingSystem | null,
  ) {}
}

export type Browser =
  | 'aol'
  | 'edge'
  | 'yandexbrowser'
  | 'kakaotalk'
  | 'samsungbrowser'
  | 'silk'
  | 'miuibrowser'
  | 'beaker'
  | 'chrome'
  | 'chrome-webview'
  | 'phantomjs'
  | 'crios'
  | 'firefox'
  | 'fxios'
  | 'opera-mini'
  | 'opera'
  | 'ie'
  | 'bb10'
  | 'android'
  | 'safari'
  | 'facebook'
  | 'instagram'
  | 'ios-webview'
  | 'curl'
  | 'searchbot'
  | 'oppobrowser'
  | 'vivobrowser'
  | 'heytapbrowser';
export type OperatingSystem =
  | 'iOS'
  | 'Android OS'
  | 'BlackBerry OS'
  | 'Windows Mobile'
  | 'Amazon OS'
  | 'Windows'
  | 'Windows 95'
  | 'Windows 98'
  | 'Windows 2000'
  | 'Windows XP'
  | 'Windows Server 2003'
  | 'Windows Vista'
  | 'Windows 7'
  | 'Windows 8'
  | 'Windows 8.1'
  | 'Windows 10'
  | 'Windows ME'
  | 'Open BSD'
  | 'Sun OS'
  | 'Linux'
  | 'Mac OS'
  | 'QNX'
  | 'BeOS'
  | 'OS/2'
  | 'Chrome OS';
type UserAgentRule = [Browser, RegExp];
type UserAgentMatch = [Browser, RegExpExecArray] | false;
type OperatingSystemRule = [OperatingSystem, RegExp];

const REQUIRED_VERSION_PARTS = 3;

const userAgentRules: UserAgentRule[] = [
  ['aol', /AOLShield\/([0-9\._]+)/],
  ['edge', /EdgA?\/([0-9\.]+)/],
  ['edge', /Edge?\/([0-9\.]+)/],
  ['edge', /EdgiOS\/([0-9\._]+)/],
  ['edge', /Edg?\/([0-9\.]+)/],
  ['yandexbrowser', /YaBrowser\/([0-9\._]+)/],
  ['kakaotalk', /KAKAOTALK\s([0-9\.]+)/],
  ['samsungbrowser', /SamsungBrowser\/([0-9\.]+)/],
  ['silk', /\bSilk\/([0-9._-]+)\b/],
  ['miuibrowser', /MiuiBrowser\/([0-9\.]+)/],
  ['oppobrowser', /OppoBrowser\/([0-9\.]+)(?:\s|)/],
  ['vivobrowser', /VivoBrowser\/([0-9\.]+)(?:\s|)/],
  ['heytapbrowser', /HeyTapBrowser\/([0-9\.]+)(?:\s|)/],
  ['beaker', /BeakerBrowser\/([0-9\.]+)/],
  ['chrome-webview', /(?!Chrom.*OPR)wv\).*Chrom(?:e|ium)\/([0-9\.]+)(:?\s|)/],
  ['chrome', /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9\.]+)(:?\s|)/],
  ['phantomjs', /PhantomJS\/([0-9\.]+)(:?\s|)/],
  ['chrome', /CriOS\/([0-9\.]+)(:?\s|)/],
  ['firefox', /Firefox\/([0-9\.]+)(?:\s|)/],
  ['firefox', /FxiOS\/([0-9\.]+)/],
  ['opera-mini', /Opera Mini.*Version\/([0-9\.]+)/],
  ['opera', /Opera\/([0-9\.]+)(?:\s|)/],
  ['opera', /OPR\/([0-9\.]+)(:?\s|)/],
  ['ie', /Trident\/7\.0.*rv\:([0-9\.]+).*\).*Gecko/],
  ['ie', /MSIE\s([0-9\.]+);.*Trident\/[4-7].0/],
  ['ie', /MSIE\s(7\.0)/],
  ['bb10', /BB10;\sTouch.*Version\/([0-9\.]+)/],
  ['android', /Android\s([0-9\.]+)/],
  ['safari', /Version\/([0-9\._]+).*Safari/],
  ['facebook', /FBAV\/([0-9\.]+)/],
  ['instagram', /Instagram\s([0-9\.]+)/],
  ['ios-webview', /AppleWebKit\/([0-9\.]+).*Mobile/],
  ['ios-webview', /AppleWebKit\/([0-9\.]+).*Gecko\)/],
  ['curl', /^curl\/([0-9\.]+)/],
];
const operatingSystemRules: OperatingSystemRule[] = [
  [
    'iOS',
    /(iPad|iPhone|iphone|iPod).*?(OS |os |OS\_)(\d+((_|\.)\d)?((_|\.)\d)?)/,
  ],
  ['Android OS', /android[\s/-](\d+(\.\d+)*)/i],
  ['BlackBerry OS', /BlackBerry|BB10/],
  ['Windows Mobile', /windows phone (?:os)?\s?(\d+(\.\d+)*)/i],
  ['Amazon OS', /Kindle/],
  ['Windows', /Windows ((NT|XP)( \d\d?.\d)?)/i],
  ['Open BSD', /OpenBSD/],
  ['Sun OS', /SunOS/],
  ['Chrome OS', /CrOS/],
  ['Linux', /(Linux)|(X11)/],
  ['Mac OS', /mac os x (\d+(\.?_?\d+)+)/i],
  ['QNX', /QNX/],
  ['BeOS', /BeOS/],
  ['OS/2', /OS\/2/],
];

export function detect(userAgent?: string): BrowserInfo | null {
  if (!!userAgent) {
    return parseUserAgent(userAgent);
  }

  if (typeof navigator !== 'undefined') {
    return parseUserAgent(navigator.userAgent);
  }

  return null;
}

function matchUserAgent(ua: string): UserAgentMatch {
  // opted for using reduce here rather than Array#first with a regex.test call
  // this is primarily because using the reduce we only perform the regex
  // execution once rather than once for the test and for the exec again below
  // probably something that needs to be benchmarked though
  return (
    ua !== '' &&
    userAgentRules.reduce<UserAgentMatch>(
      (matched: UserAgentMatch, [browser, regex]) => {
        if (matched) {
          return matched;
        }

        const uaMatch = regex.exec(ua);
        return !!uaMatch && [browser, uaMatch];
      },
      false,
    )
  );
}

export function browserName(ua: string): Browser | null {
  const data = matchUserAgent(ua);
  return data ? data[0] : null;
}

export function parseUserAgent(ua: string): BrowserInfo | null {
  const matchedRule: UserAgentMatch = matchUserAgent(ua);

  if (!matchedRule) {
    return null;
  }

  const [name, match] = matchedRule;

  // Do not use RegExp for split operation as some browser do not support it (See: http://blog.stevenlevithan.com/archives/cross-browser-split)
  let versionParts =
    match[1] &&
    match[1]
      .split('.')
      .join('_')
      .split('_')
      .slice(0, 3);
  if (versionParts) {
    if (versionParts.length < REQUIRED_VERSION_PARTS) {
      versionParts = [
        ...versionParts,
        ...createVersionParts(REQUIRED_VERSION_PARTS - versionParts.length),
      ];
    }
  } else {
    versionParts = [];
  }

  const version = versionParts.join('.');
  const os = detectOS(ua);

  return new BrowserInfo(name, version, os);
}

export function detectOS(ua: string): OperatingSystem | null {
  for (let ii = 0, count = operatingSystemRules.length; ii < count; ii++) {
    const [os, regex] = operatingSystemRules[ii];
    const match = regex.exec(ua);
    if (match) {
      return os;
    }
  }
  return null;
}

export function detectOSWithVersion(ua: string): any {
  for (let ii = 0, count = operatingSystemRules.length; ii < count; ii++) {
    const [os, regex] = operatingSystemRules[ii];
    const x = regex.exec(ua);
    if (x) {
      const match = ua.match(regex);
      let osInfo = '';
      if (os === 'iOS') {
        osInfo =
          (match && match.length > 0 && `${match[1]}${match[2]}${match[3]}`) ||
          '';
      } else {
        osInfo = (match && match.length > 0 && match[0]) || '';
      }

      return osInfo.replace(/[_]/g, '.').replace(/[\s]/g, '');
    }
  }
}

function createVersionParts(count: number): string[] {
  const output = [];
  for (let ii = 0; ii < count; ii++) {
    output.push('0');
  }

  return output;
}
