import { HeaderActions, HeaderDetails } from './types';
import {
  UPDATE_HEADER_TITLE,
  UPDATE_HEADER,
} from '../../../helpers/actionTypes';

const initialState: HeaderDetails = {
  title: '',
  logo: '',
  logo_alt: '',
};

/**
 * Reducer function for storing Header Details
 * @param state Previous state from the store
 * @param action Action to be performed
 * @returns Returns HeaderDetails
 */
export default function HeaderReducer(
  state = initialState,
  action: HeaderActions,
): HeaderDetails {
  switch (action.type) {
    case UPDATE_HEADER:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_HEADER_TITLE:
      return {
        ...state,
        title: action.payload.title,
      };
    default:
      return state;
  }
}
