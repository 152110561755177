import rg4js from 'raygun4js';
import { ENABLE_RAY, RAY } from './envconfig';

type callTypes =
  | 'recordBreadcrumb'
  | 'send'
  | 'apiKey'
  | 'enableCrashReporting'
  | 'enablePulse'
  | 'setUser'
  | 'setVersion'
  | 'options'
  | 'trackEvent';

export interface Rg4JsWrapperParams {
  callType: callTypes;
  stringValue?: string;
  numberValue?: number;
  booleanValue?: boolean;
  dateValue?: Date;
  arrayValue?: [];
  objectValue?: any; // eslint-disable-line
}

interface Rg4JsCallRegistry {
  [ident: string]: (p: Rg4JsWrapperParams) => void;
}

export class Rg4JsWrapperService {
  private static rg4jsCallRegistry: Rg4JsCallRegistry = {
    recordBreadcrumb: Rg4JsWrapperService.logBreadCrumbInfo,
    send: Rg4JsWrapperService.logError,
    apiKey: Rg4JsWrapperService.logApiKey,
    enableCrashReporting: Rg4JsWrapperService.enableCrashReporting,
    enablePulse: Rg4JsWrapperService.enablePulse,
    setUser: Rg4JsWrapperService.setUser,
    setVersion: Rg4JsWrapperService.setVersion,
    options: Rg4JsWrapperService.options,
    trackEvent: Rg4JsWrapperService.logTrackEvent,
  };

  /**
   * Logs information to RayGun.
   * Determined by setting callType property appropriately in incoming argument
   * @param pVal Object containing the relevant as desired by each rg4js call.
   */
  static Log(pVal: Rg4JsWrapperParams): void {
    if (ENABLE_RAY && RAY) {
      if (pVal.callType in Rg4JsWrapperService.rg4jsCallRegistry) {
        try {
          Rg4JsWrapperService.rg4jsCallRegistry[pVal.callType](pVal);
        } catch {
          // eslint-disable-next-line
        }
      }
    }
  }

  private static logBreadCrumbInfo(pVal: Rg4JsWrapperParams): void {
    let msgObj;
    let metaObj;
    if (pVal.stringValue) {
      msgObj = pVal.stringValue;
    }
    if (pVal.objectValue && pVal.objectValue.msgObj) {
      msgObj = pVal.objectValue.msgObj;
    }
    if (pVal.objectValue && pVal.objectValue.metaObj) {
      metaObj = pVal.objectValue.metaObj;
    }
    rg4js('recordBreadcrumb', msgObj, metaObj);
  }

  private static logError(pVal: Rg4JsWrapperParams): void {
    rg4js('send', pVal.objectValue);
  }

  private static logApiKey(pVal: Rg4JsWrapperParams): void {
    if (pVal.stringValue) {
      rg4js('apiKey', pVal.stringValue);
    }
  }

  private static enableCrashReporting(pVal: Rg4JsWrapperParams): void {
    if (pVal.booleanValue) {
      rg4js('enableCrashReporting', pVal.booleanValue);
    }
  }

  private static enablePulse(pVal: Rg4JsWrapperParams): void {
    if (pVal.booleanValue) {
      rg4js('enablePulse', pVal.booleanValue);
    }
  }

  private static setUser(pVal: Rg4JsWrapperParams): void {
    if (pVal.objectValue && pVal.objectValue.value) {
      rg4js('setUser', pVal.objectValue);
    }
  }

  private static setVersion(pVal: Rg4JsWrapperParams): void {
    if (pVal.stringValue) {
      rg4js('setVersion', pVal.stringValue);
    }
  }

  private static options(pVal: Rg4JsWrapperParams): void {
    if (pVal.objectValue) {
      rg4js('options', pVal.objectValue);
    }
  }

  private static logTrackEvent(pVal: Rg4JsWrapperParams): void {
    if (pVal.objectValue && pVal.objectValue.value) {
      rg4js('trackEvent', pVal.objectValue.value);
    }
  }
}
