import { AxiosResponse } from 'axios';
import { TEST_CAPTCHA_LINK } from '../helpers/constants';
import i18n from '../i18n/i18n';

const translate = i18n.t.bind(i18n);

export default class MockOfflineKYCDataService {
  static GetCaptcha = async (): Promise<AxiosResponse | undefined> => {
    const response: AxiosResponse = {
      config: {},
      status: 200,
      statusText: 'OK',
      headers: {},
      data: { ImageURL: TEST_CAPTCHA_LINK, Message: '', IsSuccess: true },
    };

    return response;
  };

  static GetOTP = async (
    id: string,
    captcha: string,
  ): Promise<AxiosResponse> => {
    const response: AxiosResponse = {
      config: {},
      status: 200,
      statusText: 'OK',
      headers: {},
      data: {},
    };

    if (id !== '123412341234' && id !== '1234123412341234') {
      response.data = {
        Message: translate('MOCK_INVALID_UID_SERVER_ERROR_CONTENT'),
        IsSuccess: false,
      };
      return response;
    }

    if (captcha !== '12345') {
      response.data = {
        Message: translate('MOCK_INVALID_CAPTCHA_SERVER_ERROR_CONTENT'),
        IsSuccess: false,
      };
      return response;
    }

    if (
      (id === '123412341234' || id === '1234123412341234') &&
      captcha === '12345'
    ) {
      response.data = {
        Message: translate('MOCK_OTP_SENT_SUCCESSFULLY'),
        IsSuccess: true,
      };
      return response;
    }
    return response;
  };

  static GetXML = async (otp: string): Promise<AxiosResponse> => {
    const response: AxiosResponse = {
      config: {},
      status: 200,
      statusText: 'OK',
      headers: {},
      data: {},
    };
    if (otp !== '123456') {
      response.headers = {
        'content-type': 'application/json',
      };
      const data = {
        Message: translate('INVALID_OTP_TOTP'),
        IsSuccess: false,
      };

      const encoder: TextEncoder = new TextEncoder();
      response.data = encoder.encode(JSON.stringify(data)).buffer;
    } else {
      response.headers = {
        'content-type': 'application/zip',
        'content-disposition': 'attachment; filename=test.zip',
      };
      response.data = new ArrayBuffer(12345);
    }

    return response;
  };
}
