import React from 'react';
import './Footer.css';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { FooterDetails } from './store/types';
import { ThemeConfig } from '../../store/ThemeConfig/types';
import i18n from '../../i18n/i18n';

interface StateProps {
  FooterDetails: FooterDetails;
  themeConfig: ThemeConfig;
}

type Props = StateProps;
const translate = i18n.t.bind(i18n);

/**
 * Component for Footer note
 */
class FooterComponent extends React.Component<Props> {
  render(): JSX.Element {
    const { FooterDetails, themeConfig } = this.props;
    return (
      <div
        style={{
          backgroundColor: FooterDetails.backgroundColor,
          color: FooterDetails.textColor,
          zIndex: 9999,
        }}
      >
        {themeConfig &&
          themeConfig.custom_footer &&
          themeConfig.custom_footer.display && (
            <Typography
              variant="subtitle2"
              className="footer"
              style={{
                backgroundColor: FooterDetails.backgroundColor,
                color: FooterDetails.textColor,
                zIndex: 9999,
              }}
            >
              {translate('FOOTER_TEXT')}
              <img
                src="/images/idfy_logo_footer.png"
                className="idfy-footer-logo"
                alt="IDfy"
              />
            </Typography>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  FooterDetails: state.FooterDetails,
  themeConfig: state.ThemeConfig,
});

export default connect<StateProps, {}, {}, AppState>(mapStateToProps)(
  FooterComponent,
);
