import { ToastActions, ToastData } from './types';
import { UPDATE_TOAST_STATE } from '../../helpers/actionTypes';

/**
 * Updates the state of toast
 * @param newToastData
 * @returns ToastActions
 */

export default function toastHandleAction(
  newToastData: ToastData,
): ToastActions {
  return {
    type: UPDATE_TOAST_STATE,
    payload: newToastData,
  };
}
