import {
  SVServicePageData,
  SaveSVServiceTaskActions,
  SVServiceStatusDetail,
} from './types';
import {
  SAVE_SV_SERVICE_PAGE_CONFIG,
  SAVE_SV_SERVICE_STATUS,
} from '../../../helpers/actionTypes';

export function SaveSVServiceTask(
  newPageData: SVServicePageData,
): SaveSVServiceTaskActions {
  return {
    type: SAVE_SV_SERVICE_PAGE_CONFIG,
    payload: newPageData,
  };
}

export function SaveSVServiceStatus(
  statusData: SVServiceStatusDetail,
): SaveSVServiceTaskActions {
  return {
    type: SAVE_SV_SERVICE_STATUS,
    payload: statusData,
  };
}
