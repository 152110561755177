import { ToastActions, ToastData, ToastTypes } from './types';
import { UPDATE_TOAST_STATE } from '../../helpers/actionTypes';

export const initialState: ToastData = {
  show: false,
  message: '',
  type: ToastTypes.info,
  position: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  autoHideDuration: 5000,
};

/**
 * Reducer function for maintaining the Toast Status
 * @param state Previous state from the store
 * @param action Action to be performed
 * @returns Returns
 */
export function handleToastReducer(
  state = initialState,
  action: ToastActions,
): ToastData {
  switch (action.type) {
    case UPDATE_TOAST_STATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
