import { TaskActions } from './types';
import { TasksInfo } from '../../components/Capture/store/types';
import {
  UPDATE_TASKS,
  SAVE_AVKYC_STATUS,
  SAVE_SV_SERVICE_STATUS,
  STORE_TASKS,
} from '../../helpers/actionTypes';
import { SaveAVKycPageConfigActions } from '../../components/AssistedVideoKYC/store/types';
import {
  CAPTURE_TASKS_SV_SERVICE,
  CAPTURE_TASKS_SELF_VERIFY_SV_SERVICE_IN_APP,
  CAPTURE_TASKS_SV_SERVICE_IN_APP,
} from '../../helpers/constants';
import { SaveSVServiceTaskActions } from '../../components/SelfVideoService/store/types';

const initialState: TasksInfo = {};

/**
 * Reducer for storing Tasks Details
 * @param state Previous state from the store
 * @param action Action to be performed
 */
export default function TasksReducer(
  state = initialState,
  action: TaskActions | SaveAVKycPageConfigActions | SaveSVServiceTaskActions,
): TasksInfo {
  switch (action.type) {
    case STORE_TASKS:
      return action.payload;
    case UPDATE_TASKS:
      return {
        ...state,
        ...action.payload,
      };
    // For the coupled approach, TasksInfo is also required to be
    // updated to the same state as AvKYC task.
    // Hence introducing this case so that state change can be reflected into TasksInfo too.
    case SAVE_AVKYC_STATUS: {
      const newState = { ...state };
      if (newState[action.payload.task_key]) {
        newState[action.payload.task_key].status = action.payload.status;
      }
      return newState;
    }
    case SAVE_SV_SERVICE_STATUS: {
      const newState = { ...state };
      if (newState[CAPTURE_TASKS_SV_SERVICE]) {
        newState[CAPTURE_TASKS_SV_SERVICE].status = action.payload.status;
      } else if (newState[CAPTURE_TASKS_SV_SERVICE_IN_APP]) {
        newState[CAPTURE_TASKS_SV_SERVICE_IN_APP].status =
          action.payload.status;
      } else if (newState[CAPTURE_TASKS_SELF_VERIFY_SV_SERVICE_IN_APP]) {
        newState[CAPTURE_TASKS_SELF_VERIFY_SV_SERVICE_IN_APP].status =
          action.payload.status;
      }
      return newState;
    }
    default:
      return state;
  }
}
