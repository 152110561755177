import { TaskActions } from './types';
import { TasksInfo } from '../../components/Capture/store/types';
import { STORE_TASKS, UPDATE_TASKS } from '../../helpers/actionTypes';

/**
 * Action Creator
 * @param newTaskDetails update value for action
 * @returns TaskAction
 */
export function updateTasks(newTaskDetails: TasksInfo): TaskActions {
  return {
    type: UPDATE_TASKS,
    payload: newTaskDetails,
  };
}

export function storeTasks(newTaskDetails: TasksInfo): TaskActions {
  return {
    type: STORE_TASKS,
    payload: newTaskDetails,
  };
}
