import {
  SAVE_SKILL_CONFIG,
  UPDATE_SKILL_CONFIG,
} from '../../../../helpers/actionTypes';
import { SaveSkillConfigActions, SkillConfiguration } from './types';

export const skillInitialState: SkillConfiguration = {
  capture: [],
  disabled_button_help_text: '',
};

export function SaveSkillReducer(
  state = skillInitialState,
  action: SaveSkillConfigActions,
): SkillConfiguration {
  switch (action.type) {
    case SAVE_SKILL_CONFIG:
      return { ...action.payload };
    case UPDATE_SKILL_CONFIG: {
      const index = state.capture.findIndex(
        (skill) => skill.skill === action.payload.skill,
      );
      if (index >= 0) {
        const tempState = state;
        tempState.capture[index] = action.payload;
        return { ...tempState };
      }
      return state;
    }
    default:
      return state;
  }
}
