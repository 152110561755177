import { HandleReloadActions, ReloadFlag } from './types';
import { UPDATE_RELOAD_STATUS } from '../../helpers/actionTypes';

/**
 * Action creator for Reload
 * @param newHeaderDetails
 * @returns Returns ReloadHandleAction
 */
export default function reloadHandleAction(
  newReloadFlag: ReloadFlag,
): HandleReloadActions {
  return {
    type: UPDATE_RELOAD_STATUS,
    payload: newReloadFlag,
  };
}
