import { ImageTitleActions, ImageTitleDetails } from './types';
import { SAVE_SELECTED_VALUE } from '../../../helpers/actionTypes';

const initialState: ImageTitleDetails = {};

/**
 * Reducer function for storing Image Title
 * @param state Previous state from the store
 * @param action Action to be performed
 * @returns Returns ImageTitleDetails
 */
export default function ImageTitleReducer(
  state = initialState,
  action: ImageTitleActions,
): ImageTitleDetails {
  switch (action.type) {
    case SAVE_SELECTED_VALUE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
