import { AADHAAR_XML } from '../../helpers/actionTypes';
import { AadhaarXMLScrapedActions, AadhaarXMLScrapedDetails } from './types';

/**
 * Action Creator
 * @param aadhaarDetails update value for action
 * @returns CaptureConfigActions
 */
export default function AadhaarXMLScrapingAction(
  AadhaarXMLScrapping: AadhaarXMLScrapedDetails,
): AadhaarXMLScrapedActions {
  return {
    type: AADHAAR_XML,
    payload: AadhaarXMLScrapping,
  };
}
