export const CAPTURECONFIG = 'CAPTURECONFIG';
export const SAVE_GROUP = 'SAVE_GROUP';
export const UPDATE_HEADER = 'UPDATE_HEADER';
export const UPDATE_HEADER_TITLE = 'UPDATE_HEADER_TITLE';
export const UPDATE_FOOTER = 'UPDATE_FOOTER';
export const SAVE_SELECTED_VALUE = 'SAVE_SELECTED_VALUE';
export const CAPTURE_CONF = 'CAPTURE_CONF';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const STORE_ARTIFACTS = 'STORE_ARTIFACTS';
export const UPDATE_ARTIFACTS = 'UPDATE_ARTIFACTS';
export const UPDATE_RELOAD_STATUS = 'UPDATE_RELOAD_STATUS';
export const UPDATE_TOAST_STATE = 'UPDATE_TOAST_STATE';
export const SAVE_RESPONSE = 'SAVE_RESPONSE';
export const SAVE_TOKEN = 'SAVE_TOKEN';
export const SAVE_REDIRECT_URI = 'SAVE_REDIRECT_URI';
export const ERROR = 'ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const OVERLAY = 'OVERLAY';
export const CLEAR_OVERLAY = 'CLEAR_OVERLAY';
export const MULTIPLE_SESSION = 'MULTIPLE_SESSION';
export const UPDATE_PAGE_HEADER = 'UPDATE_PAGE_HEADER';
export const AADHAAR_KYC = 'AADHAAR_KYC';
export const AADHAAR_XML = 'AADHAAR_XML';
export const OTP_SCREEN = 'OTP_SCREEN';
export const STORE_TASKS = 'STORE_TASKS';
export const UPDATE_TASKS = 'UPDATE_TASKS';
export const SAVE_PAGE_CONFIG = 'SAVE_PAGE_CONFIG';
export const SAVE_PERMISSION_CONFIG = 'SAVE_PERMISSION_CONFIG';
export const PAGE_SEQ = 'PAGE_SEQ';
export const SAVE_AVKYC_PAGE_CONFIG = 'SAVE_AVKYC_PAGE_CONFIG';
export const SAVE_AVKYC_STATUS = 'SAVE_AVKYC_STATUS';
export const SAVE_SV_SERVICE_STATUS = 'SAVE_SV_SERVICE_STATUS';
export const THEME = 'THEME_CONFIG';
export const SCHEDULING_CONFIG = 'SCHEDULINGCONFIG';
export const SAVE_SV_SERVICE_PAGE_CONFIG = 'SAVE_SV_SERVICE_PAGE_CONFIG';
export const SAVE_EXIT_URI = 'SAVE_EXIT_URI';
export const IN_PROGRESS_DIV = 'IN_PROGRESS_DIV';
export const SET_LANG = 'SET_LANG';
export const SAVE_DIGILOCKER_DETAILS = 'SAVE_DIGILOCKER_DETAILS';
export const CLEAR_REDIRECTING_OVERLAY = 'CLEAR_REDIRECTING_OVERLAY';
export const REDIRECTING_OVERLAY = 'REDIRECTING_OVERLAY';
export const SAVE_AGENT_DISPOSITION = 'SAVE_AGENT_DISPOSITION';
export const SAVE_SKILL_CONFIG = 'SAVE_SKILL_CONFIG';
export const UPDATE_SKILL_CONFIG = 'UPDATE_SKILL_CONFIG';
export const UPDATE_OCR_CONFIG = 'UPDATE_OCR_CONFIG';
export const SAVE_OCR_RESULT = 'SAVE_OCR_RESULT';
export const SAVE_OCR_DETAILS = 'SAVE_OCR_DETAILS';
export const SAVE_DF_SESSION_DATA = 'SAVE_DF_SESSION_DATA';
export const SAVE_PREREQUISITE_CONFIG = 'SAVE_PREREQUISITE_CONFIG';
export const SAVE_UPLOAD_PROGRESS = 'SAVE_UPLOAD_PROGRESS';
