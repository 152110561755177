import { GroupDetails, GroupAction } from './types';
import { SAVE_GROUP } from '../../../helpers/actionTypes';

/**
 * Action creator
 * @param newGroupDetails
 * @returns Returns GroupActions
 */
export default function saveGroupAction(
  newGroupDetails: GroupDetails,
): GroupAction {
  return {
    type: SAVE_GROUP,
    payload: newGroupDetails,
  };
}
