import { SaveDFSessionDetailsActions, DFSessionDetails } from './types';
import { SAVE_DF_SESSION_DATA } from '../../../helpers/actionTypes';

const initialState: DFSessionDetails = {
  session_exists: false,
  redirect_url: '',
};

/**
 * Reducer function for storing Document Fetcher Auth Session details
 * @param state Previous state from the store
 * @param action Action to be performed
 * @returns Returns DFSessionDetails
 */
export default function DFSessionReducer(
  state = initialState,
  action: SaveDFSessionDetailsActions,
): DFSessionDetails {
  switch (action.type) {
    case SAVE_DF_SESSION_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
