import React from 'react';
import { getTatStartRef } from '../../helpers';
import { Rg4JsWrapperService } from '../../helpers/raygunWrapper';
import { logPageVisit } from '../../helpers/loggerWrapper';
import SimpleOverlay from '../SimpleOverlay';
import i18n from '../../i18n/i18n';
import ClickTracker from '../../helpers/ui-click-event';

const translate = i18n.t.bind(i18n);
/**
 * Component to display Invalid Link Page
 */
export default class InvalidLinkComponent extends React.Component {
  loggerStartTime = getTatStartRef();

  componentDidMount(): void {
    ClickTracker?.setComponentName?.('InvalidLinkComponent');
    Rg4JsWrapperService.Log({
      callType: 'recordBreadcrumb',
      objectValue: {
        msgObj: {
          message: 'Redirected to invalid link',
          level: 'info',
          metadata: {},
          location: '',
        },
        metaObj: {
          message: 'Invalid link',
        },
      },
    });
    logPageVisit('InvalidLink', 'InvalidLinkComponent');
  }

  componentWillUnmount(): void {
    ClickTracker?.unsetComponentName?.();
  }

  render(): JSX.Element {
    return (
      <SimpleOverlay
        showHeader
        titleConfig={{
          primaryText: translate('INVALID_LINK'),
          secondaryText: translate('INVALID_LINK_MESSAGE_1'),
          tertiaryText: translate('INVALID_LINK_MESSAGE_2'),
          titleSvg: 'invalid',
        }}
        showCrossButton={false}
      />
    );
  }
}
