import { log, LogLevel } from './loggerWrapper';

export default class ClickTracker {
  private static currentComponent: string | null = null;

  static setComponentName(name: string): void {
    this.currentComponent = name;
  }

  static unsetComponentName(): void {
    this.currentComponent = null;
  }

  static clickEventHandler = (e: PointerEvent | MouseEvent): void => {
    try {
      const history = window.history.state;
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;
      const relativeX = e.pageX / viewportWidth;
      const relativeY = e.pageY / viewportHeight;
      let clickType: string = 'unknown';

      if (e instanceof PointerEvent) {
        clickType = e.pointerType || 'unknown';
      }

      const deviceType = ClickTracker.getDeviceType(viewportWidth);

      const clickData = {
        X: e.pageX,
        Y: e.pageY,
        viewportWidth,
        viewportHeight,
        aspectRatio: viewportWidth / viewportHeight,
        pathName: history?.state?.pagePath || '',
        clickType,
        relativeX,
        relativeY,
        deviceType,
        component: this.currentComponent || 'Unknown',
      };

      log(
        LogLevel.Info,
        {
          serviceCategory: 'Capture',
          service: 'Clicks',
          eventType: 'Click',
          eventName: 'UI Click',
          component: 'app',
          eventSource: 'clickListener',
          publishToDLK: false,
        },
        { clickData },
      );
    } catch (error) {
      log(
        LogLevel.Error,
        {
          serviceCategory: 'Capture',
          service: 'Clicks',
          eventType: 'Error',
          eventName: 'Click Error',
          component: 'app',
          eventSource: 'clickListener',
          publishToDLK: false,
        },
        { error },
      );
    }
  };

  private static getDeviceType(width: number): string {
    const breakpoints = {
      md: 768,
      lg: 1024,
    };

    if (width < breakpoints.md) {
      return 'sm'; // Small devices (<768px)
    } else if (width < breakpoints.lg) {
      return 'md'; // Medium devices (768px to <1024px)
    } else {
      return 'lg'; // Large devices (>=1024px)
    }
  }
}
