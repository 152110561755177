import { DataService } from './DataService';

export const MODEL_URL = '/models';

type faceapi = typeof import('face-api.js');

export class FaceAPIService {
  static faceapi: faceapi;

  static promiseArray: ((s: faceapi) => void)[] = [];

  static mockFaceAPI = false;
  // eslint-disable-next-line
  static mockedFaceAPI: any = {
    // eslint-disable-next-line
    detectAllFaces: (input: any, options: any) => {
      return Promise.resolve({ length: 1 });
    },
    TinyFaceDetectorOptions: {},
  };

  static async InitiateFaceApi(): Promise<void> {
    import('face-api.js')
      .then(async (api) => {
        FaceAPIService.faceapi = api;
        await FaceAPIService.faceapi.nets.tinyFaceDetector.loadFromUri(
          MODEL_URL,
        );
        for (let i = 0; i < FaceAPIService.promiseArray.length; i += 1) {
          const resolve = FaceAPIService.promiseArray[i];
          resolve(FaceAPIService.faceapi);
        }
        FaceAPIService.promiseArray = [];
      })
      .catch((error) => {
        DataService.RaiseError(new Error('NETWORK_ERROR'), {
          errorServiceCategory: 'Capture',
          errorService: 'FaceAPI',
          eventType: 'Exception',
          errorSource: 'InitiateFaceApi',
          component: 'FaceAPIService',
          exceptionName: 'FaceApiError',
          exceptionDescription:
            'Error received during face-api.js package import',
          metaData: { error },
        });
      });
  }

  static async GetFaceAPI(): Promise<faceapi> {
    if (!FaceAPIService.faceapi) {
      const promise = new Promise<faceapi>((resolve) => {
        FaceAPIService.promiseArray.push(resolve);
      });
      return promise;
    }
    if (FaceAPIService.mockFaceAPI) {
      return Promise.resolve(FaceAPIService.mockedFaceAPI);
    }
    return Promise.resolve(FaceAPIService.faceapi);
  }
}
