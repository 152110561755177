import { IN_PROGRESS_DIV } from '../../helpers/actionTypes';
import { DIVDetails, UpdateInProgressDIV } from './types';

const initialState: DIVDetails = {
  inProgressDiv: [],
};

/**
 * Reducer for storing DIV Details
 * @param state Previous state from the store
 * @param action Action to be performed
 */
export default function DIVReducer(
  state = initialState,
  action: UpdateInProgressDIV,
): DIVDetails {
  switch (action.type) {
    case IN_PROGRESS_DIV:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
