import { Middleware, AnyAction, MiddlewareAPI } from 'redux';
import { Dispatch } from 'react';
import { Rg4JsWrapperService } from '../helpers/raygunWrapper';

const ErrorReportingMiddleware: Middleware =
  (api: MiddlewareAPI) =>
  (
    next: Dispatch<AnyAction>,
    // eslint-disable-next-line
) => (action: AnyAction): void => {
    const previousState = api.getState();
    const nextAction = next(action);
    const state = api.getState();
    try {
      return next(action);
    } catch (err) {
      Rg4JsWrapperService.Log({
        callType: 'recordBreadcrumb',
        objectValue: {
          msgObj: {
            message: 'Exception occurred',
            level: 'error',
            metadata: '',
            location: '',
          },
          metaObj: {
            previousState,
            nextAction,
            state,
          },
        },
      });
      Rg4JsWrapperService.Log({ callType: 'send', objectValue: { err } });
    }
  };

export default ErrorReportingMiddleware;
