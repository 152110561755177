import { PermissionsActions, PermissionDetails } from './types';
import { SAVE_PERMISSION_CONFIG } from '../../../helpers/actionTypes';

const initialState: PermissionDetails = {
  title: '',
  capture: [],
};

/**
 * Reducer for storing Permission Details
 * @param state Previous state from the store
 * @param action Action to be performed
 */
export default function PermissionReducer(
  state = initialState,
  action: PermissionsActions,
): PermissionDetails {
  switch (action.type) {
    case SAVE_PERMISSION_CONFIG:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
