import { SaveResponseActions, ResponseDetails } from './types';
import { SAVE_RESPONSE } from '../../helpers/actionTypes';

/**
 * Action creator for response saving
 * @param newResponseDetails
 * @returns Returns SaveResponseActions
 */
export default function saveResponseBodyAction(
  newResponseDetails: ResponseDetails,
): SaveResponseActions {
  return {
    type: SAVE_RESPONSE,
    payload: newResponseDetails,
  };
}
