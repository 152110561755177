import { FooterActions, FooterDetails } from './types';
import { UPDATE_FOOTER } from '../../../helpers/actionTypes';

const initialState: FooterDetails = {
  backgroundColor: '#ffffff',
  textColor: '#000000',
};

/**
 * Reducer function for storing Footer Details
 * @param state Previous state from the store
 * @param action Action to be performed
 * @returns Returns FooterDetails
 */
export default function FooterReducer(
  state = initialState,
  action: FooterActions,
): FooterDetails {
  switch (action.type) {
    case UPDATE_FOOTER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
