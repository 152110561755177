import { AnyAction, MiddlewareAPI } from 'redux';

type IDfyPlugin = (
  params: any,
  middlewareAPI: MiddlewareAPI,
  action: AnyAction,
) => void;
type PluginParams = {
  [ident: string]: any;
};
export class PluginRegistry {
  private static pluginEntries: { plugin: IDfyPlugin; id: string }[] = [];
  private static pluginParams: PluginParams = {};

  public static execPlugins(middlewareAPI: MiddlewareAPI, action: AnyAction) {
    PluginRegistry.pluginEntries.forEach(pluginEntry =>
      pluginEntry.plugin(
        PluginRegistry.pluginParams[pluginEntry.id],
        middlewareAPI,
        action,
      ),
    );
  }

  public static registerPlugin(plugin: IDfyPlugin, id: string): void {
    this.pluginEntries.push({ plugin, id });
  }

  public static registerParams(id: string, params: any): void {
    this.pluginParams[id] = params;
  }
}

//@ts-ignore
window.PluginRegistry = PluginRegistry;
