import { DFSessionDetails, SaveDFSessionDetailsActions } from './types';
import { SAVE_DF_SESSION_DATA } from '../../../helpers/actionTypes';

/**
 * Action creator for Document Fetcher Auth Session details
 * @param newSessionDetails
 * @returns Returns ImageTitleActions
 */
export default function SaveDFSessionAction(
  newSessionDetails: DFSessionDetails,
): SaveDFSessionDetailsActions {
  return {
    type: SAVE_DF_SESSION_DATA,
    payload: newSessionDetails,
  };
}
