import { OCRDetails, OCRActions } from './types';
import { SAVE_OCR_DETAILS } from '../../../helpers/actionTypes';

const initialState: OCRDetails = {};

/**
 * Reducer for storing OCR Details
 * @param state Previous state from the store
 * @param action Action to be performed
 */
export default function OCRReducer(
  state = initialState,
  action: OCRActions,
): OCRDetails {
  switch (action.type) {
    case SAVE_OCR_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
