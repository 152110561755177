import { HandleSchedulingActions } from './types';
import { SCHEDULING_CONFIG } from '../../helpers/actionTypes';
import { SchedulingInfo } from '../../components/IndvCapture/store/types';

/**
 * Action creator for Scheduler
 * @param newSchedulingInfo
 * @returns Returns HandleSchedulingActions
 */
export default function saveSchedulingConfig(
  newSchedulingInfo: SchedulingInfo,
): HandleSchedulingActions {
  return {
    type: SCHEDULING_CONFIG,
    payload: newSchedulingInfo,
  };
}
