import {
  SAVE_AGENT_DISPOSITION,
  SAVE_AVKYC_PAGE_CONFIG,
  SAVE_AVKYC_STATUS,
  SAVE_UPLOAD_PROGRESS,
} from '../../../helpers/actionTypes';
import { CaptureTask } from '../../Capture/store/types';
import {
  NetworkCheckConfig,
  SchedulingInfo,
} from '../../IndvCapture/store/types';

export interface VideoOverlayConfig {
  enabled: boolean;
}

export enum SCHEDULE_CALL_BUTTON_STATE {
  ALWAYS = 'always',
  AFTER_THRESHOLD = 'after_threshold',
}

export interface WaitScreenThresholdConfig {
  message_before: string;
  message_after: string;
  time: number;
}

export interface WaitScreenConfig {
  schedule_call_button: SCHEDULE_CALL_BUTTON_STATE;
  display_wait_screen_countdown: boolean;
  threshold: WaitScreenThresholdConfig;
}

export interface NoAgentsConfig {
  title: string;
  text: string;
}

export interface AttemptsConfig {
  last_attempt: boolean;
  attempts_exhausted: boolean;
}

export interface AgentDisposition {
  od_payload?: {
    allocation_id: string;
    current_socket_id: string;
    overall_status: 'unable_to_verify' | 'rejected' | 'verified';
    reason?: {
      additional_remarks?: string;
      category?: 'business' | 'technical';
      code?: string;
    };
  };
}
export interface AVKycPageData {
  task: CaptureTask;
  scheduling: SchedulingInfo;
  assignedUser: boolean;
  is_location_required: boolean;
  max_user_outgoing_bitrate: number | undefined;
  network_check_config: NetworkCheckConfig;
  high_res_screenshot: boolean;
  video_overlay: VideoOverlayConfig;
  unavailable_agent_config: NoAgentsConfig;
  dynamic_wait_screen?: WaitScreenConfig;
  attempts?: AttemptsConfig;
  agentDisposition?: AgentDisposition;
  uploadProgress?: number;
  allow_agent_customer_camera_control?: AgentCamControl;
}

export interface AgentCamControl {
  enabled: boolean;
  customer_consent_required: boolean;
}

export interface AVKycStatusDetail {
  task_key: string;
  status: string;
}
export interface UploadProgress {
  progress: number;
}
interface SaveAVKycPageConfigAction {
  type: typeof SAVE_AVKYC_PAGE_CONFIG;
  payload: AVKycPageData;
}

interface SaveAVKycStatusAction {
  type: typeof SAVE_AVKYC_STATUS;
  payload: AVKycStatusDetail;
}

interface SaveAgentDispositionAction {
  type: typeof SAVE_AGENT_DISPOSITION;
  payload: AgentDisposition;
}

interface SaveUploadProgressAction {
  type: typeof SAVE_UPLOAD_PROGRESS;
  payload: UploadProgress;
}
export type SaveAVKycPageConfigActions =
  | SaveAVKycPageConfigAction
  | SaveAVKycStatusAction
  | SaveAgentDispositionAction
  | SaveUploadProgressAction;
