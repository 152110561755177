import { AadhaarXMLScrapedActions, AadhaarXMLScrapedDetails } from './types';
import { AADHAAR_XML } from '../../helpers/actionTypes';

export const initialState: AadhaarXMLScrapedDetails = {};

/**
 * Reducer for storing Capture Configuration Details
 * @param state Previous state from the store
 * @param action Action to be performed
 */
export function AadhaarXMLScrappingReducer(
  state = initialState,
  action: AadhaarXMLScrapedActions,
): AadhaarXMLScrapedDetails {
  switch (action.type) {
    case AADHAAR_XML:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
