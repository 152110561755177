import { ArtifactActions } from './types';
import { ArtifactDetails } from '../../components/Capture/store/types';
import { STORE_ARTIFACTS, UPDATE_ARTIFACTS } from '../../helpers/actionTypes';

/**
 * Action Creator
 * @param newArtifactDetails update value for action
 * @returns Updatertifacts
 */
export function updateArtifacts(
  newArtifactDetails: ArtifactDetails,
): ArtifactActions {
  return {
    type: UPDATE_ARTIFACTS,
    payload: newArtifactDetails,
  };
}

export function storeArtifacts(
  newArtifactDetails: ArtifactDetails,
): ArtifactActions {
  return {
    type: STORE_ARTIFACTS,
    payload: newArtifactDetails,
  };
}
