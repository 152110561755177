import { ThemeConfigActions, ThemeConfig } from './types';
import { THEME } from '../../helpers/actionTypes';

/**
 * Action Creator
 * @param newThemeConfig update value for action
 * @returns TaskAction
 */
export default function SaveThemeConfig(
  newThemeConfig: ThemeConfig,
): ThemeConfigActions {
  return {
    type: THEME,
    payload: newThemeConfig,
  };
}
