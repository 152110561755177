import * as Sentry from '@sentry/react';

import type { Breadcrumb } from '@sentry/react';

import {
  APP_ENV,
  SENTRY_DSN,
  LOGGER_URL,
  API_SERVER_URI,
} from '../helpers/envconfig';

import { APP_VERSION } from './constants';

const allowedEnvs = ['staging', 'uat', 'prod'];

const beforeBreadcrumb = (breadcrumb: Breadcrumb) => {
  if (
    breadcrumb?.category === 'xhr' &&
    (breadcrumb?.data?.url?.includes?.(LOGGER_URL) ||
      breadcrumb?.data?.url?.includes?.(`${API_SERVER_URI}/captures/log`))
  ) {
    return null;
  }
  return breadcrumb;
};
export default class RealUserMonitoring {
  static init(token: string): void {
    if (
      window?.location?.hostname !== 'localhost' &&
      window?.location?.hostname !== '127.0.0.1' &&
      SENTRY_DSN &&
      allowedEnvs.includes(APP_ENV)
    ) {
      Sentry.setUser({ id: token });
      // Initialize Sentry here with your DSN or configuration
      Sentry.init({
        beforeBreadcrumb,
        dsn: SENTRY_DSN,
        debug: true,
        environment: APP_ENV,
        integrations: [Sentry.browserTracingIntegration()],
        release: APP_VERSION,
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 1.0, // This is set to 100% as session reply is conditional.
        replaysOnErrorSampleRate: 1.0, // Sampling 100% sessions where errors occur.
      });
    }
  }

  static lazyLoadReplay(): void {
    import('@sentry/browser').then((lazyLoadedSentry) => {
      Sentry.addIntegration(lazyLoadedSentry.replayIntegration());
    });
  }

  static setTags(tags: { [key: string]: string }): void {
    Sentry.setTags(tags);
  }
}
