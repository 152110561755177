import { SAVE_PREREQUISITE_CONFIG } from '../../../helpers/actionTypes';
import { PrerequisiteActions, PrerequisitePageConfig } from './types';

/**
 * Action creator to save Prerequisite config
 * @param newPrerequisiteConfig
 * @returns Returns PrerequisiteActions
 */
export default function SavePrerequisiteAction(
  newPrerequisiteConfig: PrerequisitePageConfig,
): PrerequisiteActions {
  return {
    type: SAVE_PREREQUISITE_CONFIG,
    payload: newPrerequisiteConfig,
  };
}
