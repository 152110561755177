import { UPDATE_TOAST_STATE } from '../../helpers/actionTypes';

/**
 * Type declaration for Toast
 */

export enum ToastTypes {
  info = 'info',
  error = 'error',
  success = 'success',
  warning = 'waring',
}
export interface ToastData {
  show?: boolean;
  message: string;
  type: ToastTypes;
  autoHideDuration?: number;
  position?: {
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
}

/**
 * Type declaration for
 */
interface HandleToastAction {
  type: typeof UPDATE_TOAST_STATE;
  payload: ToastData;
}

export type ToastActions = HandleToastAction;
