export const ROUTE_GROUPS = '/groups';
export const ROUTE_CAPTURES = '/captures';
export const ROUTE_THANKYOU = '/thank_you';
export const ROUTE_SOMETHING_WENT_WRONG = '/something_went_wrong';
export const ROUTE_BROWSER_NOT_SUPPORTED = '/browser_not_supported';
export const ROUTE_NETWORK_ERROR = '/network_error';
export const ROUTE_INVALID_LINK = '/invalid_link';
export const INTERNAL_ROUTE_CAPTURE_IMAGE = 'capture_image';
export const INTERNAL_ROUTE_AVKYC = 'avkyc';
export const INTERNAL_ROUTE_SV_SERVICE = 'svservice';
export const ROUTE_OFFLINE_KYC = '/offline-kyc';
