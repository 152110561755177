import {
  CaptureActions,
  CaptureSequence,
  OverrideSessionActions,
  OverrideSession,
  PageData,
  SavePageConfigActions,
  PageSequence,
} from './types';
import {
  CAPTURE_CONF,
  MULTIPLE_SESSION,
  SAVE_PAGE_CONFIG,
  PAGE_SEQ,
} from '../../../helpers/actionTypes';

/**
 * Action Creator
 * @param newCaptureDetails update value for action
 * @returns CaptureDetails
 */
export function saveCaptureConf(
  newCaptureDetails: CaptureSequence,
): CaptureActions {
  return {
    type: CAPTURE_CONF,
    payload: newCaptureDetails,
  };
}

export function savePageSequence(
  newPageSequence: PageSequence,
): CaptureActions {
  return {
    type: PAGE_SEQ,
    payload: newPageSequence,
  };
}

export function overrideSessionAction(
  newMultipleSession: OverrideSession,
): OverrideSessionActions {
  return {
    type: MULTIPLE_SESSION,
    payload: newMultipleSession,
  };
}

export function SavePageConfigAction(
  newPageData: PageData,
): SavePageConfigActions {
  return {
    type: SAVE_PAGE_CONFIG,
    payload: newPageData,
  };
}
