/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
} from 'axios';
import { ALLOWED_REDIRECTS } from './envconfig';

/// //////////
// HELPERS //
/// //////////

/**
 * Checks whether the URL redirected to is allowed.
 */
function isAllowedRedirect(responseURL: string): boolean {
  const allowedRedirects = new RegExp(window.atob(ALLOWED_REDIRECTS));
  return allowedRedirects.test(responseURL);
}

/**
 * For a given combo of request URL and response object, throws an error if the
 * request has been redirected to an invalid URL.
 */
function handleRedirect(url: string, response: AxiosResponse): AxiosResponse {
  const requestURL = url.split('?')[0];
  const responseURL = response.request.responseURL.split('?')[0];
  if (requestURL === responseURL || isAllowedRedirect(responseURL)) {
    return response;
  }
  throw new Error('Invalid redirect on API call');
}

/// /////////////////////
// AXIOS REPLACEMENTS //
/// /////////////////////

/**
 * Makes an HTTP GET request
 */
export async function get(
  url: string,
  config?: AxiosRequestConfig | undefined,
): Promise<AxiosResponse<any>> {
  const response = await axios.get(url, config);
  return handleRedirect(url, response);
}

/**
 * Makes an HTTP DELETE request
 * This function is named `delet` instead of `delete` as `delete` is a reserved
 * keyword in JavaScript. Axios gets around this by inserting the function name
 * into an object prototype directly, but I wanted to opt for a simpler
 * approach.
 */
export async function delet(
  url: string,
  config?: AxiosRequestConfig | undefined,
): Promise<AxiosResponse<any>> {
  const response = await axios.delete(url, config);
  return handleRedirect(url, response);
}

/**
 * Makes an HTTP HEAD request
 */
export async function head(
  url: string,
  config?: AxiosRequestConfig | undefined,
): Promise<AxiosResponse<any>> {
  const response = await axios.head(url, config);
  return handleRedirect(url, response);
}

/**
 * Makes an HTTP OPTIONS request
 */
export async function options(
  url: string,
  config?: AxiosRequestConfig | undefined,
): Promise<AxiosResponse<any>> {
  const response = await axios.options(url, config);
  return handleRedirect(url, response);
}

/**
 * Makes an HTTP POST request
 */
export async function post(
  url: string,
  // eslint-disable-next-line
  data?: any,
  config?: AxiosRequestConfig | undefined,
): Promise<AxiosResponse<any>> {
  const response = await axios.post(url, data, config);
  return handleRedirect(url, response);
}

/**
 * Makes an HTTP PUT request
 */
export async function put(
  url: string,
  // eslint-disable-next-line
  data?: any,
  config?: AxiosRequestConfig | undefined,
): Promise<AxiosResponse<any>> {
  const response = await axios.put(url, data, config);
  return handleRedirect(url, response);
}

/**
 * Makes an HTTP PATCHs request
 */
export async function patch(
  url: string,
  // eslint-disable-next-line
  data?: any,
  config?: AxiosRequestConfig | undefined,
): Promise<AxiosResponse<any>> {
  const response = await axios.patch(url, data, config);
  return handleRedirect(url, response);
}
/**
 * Creates a new CancelTokenSource from the axios package.
 * The CancelTokenSource can be used to cancel a request.
 */
export function getCancelTokenSource(): CancelTokenSource {
  return axios.CancelToken.source();
}
/**
 * Checks if a value is a cancellation error.
 *
 */
export function isCancel(value: Error): boolean {
  return axios.isCancel(value);
}
