import { Snackbar, SnackbarContent } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import toastHandleAction from '../../store/Toast/actions';
import { initialState } from '../../store/Toast/reducers';
import { ToastData, ToastTypes } from '../../store/Toast/types';
import theme from '../../theme';

interface DispatchProps {
  toastHandleAction: typeof toastHandleAction;
}

interface OwnProps {
  toastInfo: ToastData;
}

interface Props extends OwnProps, DispatchProps {}

function Toast(props: Props): JSX.Element {
  const { toastInfo } = props;
  const { type, message, autoHideDuration, position, show } = toastInfo;
  const { toastHandleAction } = props;
  const hideToast = (): void => {
    toastHandleAction(initialState);
  };

  const getColors = (): { bgColor: string; color: string } => {
    let bgColor = '';
    let color = '';
    if (type === ToastTypes.error) {
      bgColor = theme().palette.error.main;
      color = theme().palette.error.contrastText;
    } else if (type === ToastTypes.info) {
      bgColor = theme().palette.info.main;
      color = theme().palette.info.contrastText;
    } else if (type === ToastTypes.success) {
      bgColor = theme().palette.success.main;
      color = theme().palette.success.contrastText;
    } else if (type === ToastTypes.warning) {
      bgColor = theme().palette.warning.main;
      color = theme().palette.warning.contrastText;
    }
    return {
      bgColor,
      color,
    };
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: position ? position.vertical : 'bottom',
        horizontal: position ? position.horizontal : 'center',
      }}
      open={show}
      autoHideDuration={autoHideDuration}
      onClose={hideToast}
      style={{
        backgroundColor: getColors().bgColor,
        zIndex: 99999999,
        position: 'absolute',
        maxWidth: '100%',
      }}
    >
      <SnackbarContent
        aria-describedby="client-snackbar"
        message={<span id="client-snackbar">{message}</span>}
        style={{
          backgroundColor: getColors().bgColor,
          color: getColors().color,
        }}
      />
    </Snackbar>
  );
}

export default connect<{}, DispatchProps, OwnProps, AppState>(null, {
  toastHandleAction,
})(Toast);
