import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { DataService, BehaviourOptions } from './services/DataService';
import { configureStore } from './store';
import {
  MOCK_ALL,
  MOCK_SOCKS,
  MOCK_API,
  MOCK_SUBMIT,
  RAY,
  RAY_ENDPOINT,
  ENABLE_RAY_RUM,
  ENABLE_LOGGING,
  LOGGER_URL,
  MOCK_OFFLINE_KYC_API,
} from './helpers/envconfig';
import IntegrationService from './helpers/integrations';
import { OfflineKYCDataService } from './services/OfflineKYCDataService';
import { Rg4JsWrapperService } from './helpers/raygunWrapper';
import { init, createLogger, log, LogLevel } from './helpers/loggerWrapper';
import { APP_VERSION } from './helpers/constants';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const store = configureStore();
const token: string =
  new URLSearchParams(window.location.search).get('t') || '';

// Default service behaviour
const srvcBehaviour: BehaviourOptions = {
  MockApi: false,
  MockSockets: false,
  MockSubmit: false,
  AppStore: store,
};

// Default offline KYC Service Behaviour
let offlineKYCServiceBehaviour = false;

window.addEventListener(
  'unload',
  () => {
    log(LogLevel.Info, {
      serviceCategory: 'Capture',
      service: 'Route',
      eventType: 'Unload',
      eventName: 'Refresh',
      component: 'app',
      eventSource: 'unloadListener',
    });
    DataService.EndCommunications();
  },
  false,
);

window.addEventListener(
  'beforeunload',
  () => {
    log(LogLevel.Info, {
      serviceCategory: 'Capture',
      service: 'Route',
      eventType: 'beforeunload',
      eventName: 'Refresh',
      component: 'app',
      eventSource: 'beforeunloadListener',
    });
  },
  false,
);

if (MOCK_ALL === 'yes') {
  DataService.SetBehaviourMockAll(store);
} else {
  if (MOCK_SOCKS === 'yes') {
    srvcBehaviour.MockSockets = true;
  }
  if (MOCK_API === 'yes') {
    srvcBehaviour.MockApi = true;
  }
  if (MOCK_SUBMIT === 'yes') {
    srvcBehaviour.MockSubmit = true;
  }
  DataService.SetServiceBehaviour(srvcBehaviour);
}

if (MOCK_ALL === 'yes' || MOCK_OFFLINE_KYC_API === 'yes') {
  offlineKYCServiceBehaviour = true;
}
OfflineKYCDataService.SetServiceBehaviour(offlineKYCServiceBehaviour);

IntegrationService.SetStore(store);

if (RAY_ENDPOINT) {
  Rg4JsWrapperService.Log({
    callType: 'options',
    objectValue: { apiEndpoint: RAY_ENDPOINT },
  });
}
Rg4JsWrapperService.Log({ callType: 'apiKey', stringValue: RAY });
Rg4JsWrapperService.Log({ callType: 'setVersion', stringValue: APP_VERSION });
Rg4JsWrapperService.Log({
  callType: 'enableCrashReporting',
  booleanValue: true,
});
if (ENABLE_RAY_RUM) {
  Rg4JsWrapperService.Log({ callType: 'enablePulse', booleanValue: true });
}

Rg4JsWrapperService.Log({
  callType: 'setUser',
  objectValue: { value: { identifier: token, isAnonymous: false } },
});
if (ENABLE_LOGGING) {
  init(token, LOGGER_URL || '');
  createLogger('msAdaptor', token, LOGGER_URL || '');
  createLogger('scheduler', token, LOGGER_URL || '');
  createLogger('pg', token, LOGGER_URL || '');
  createLogger('streamHandler', token, LOGGER_URL || '');
  createLogger('vs', token, LOGGER_URL || '');
  createLogger('healthCheck', token, LOGGER_URL || '');
}

ReactDOM.render(
  <StylesProvider jss={jss}>
    <Provider store={store}>
      <App />
    </Provider>
  </StylesProvider>,
  document.getElementById('root'),
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
