import { PageHeaderActions, PageHeaderDetails } from './types';
import { UPDATE_PAGE_HEADER } from '../../helpers/actionTypes';

const initialState: PageHeaderDetails = {
  title: '',
  logo: '',
  logo_alt: '',
};

/**
 * Reducer function for storing Header Details
 * @param state Previous state from the store
 * @param action Action to be performed
 * @returns Returns HeaderDetails
 */
export default function PageHeaderReducer(
  state = initialState,
  action: PageHeaderActions,
): PageHeaderDetails {
  switch (action.type) {
    case UPDATE_PAGE_HEADER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
