import {
  ERROR,
  CLEAR_ERROR,
  OVERLAY,
  CLEAR_OVERLAY,
  CLEAR_REDIRECTING_OVERLAY,
  REDIRECTING_OVERLAY,
} from '../../helpers/actionTypes';
import {
  ErrorOptions,
  ErrorActions,
  OverlayOptions,
  OverlayActions,
  RedirectingOverlayOption,
  RedirectingOverlayActions,
} from './types';
import { Rg4JsWrapperService } from '../../helpers/raygunWrapper';
import { log, LogLevel } from '../../helpers/loggerWrapper';

/**
 * Action Creator
 * @param newArtifactDetails update value for action
 * @returns SaveArtifactAction
 */
export function RaiseErrorAction(
  error: Error,
  errorOptions: ErrorOptions,
): ErrorActions {
  const isBusinessError =
    errorOptions.errorKind && errorOptions.errorKind === 'business';
  if (!isBusinessError && errorOptions.logExceptionToRaygun) {
    Rg4JsWrapperService.Log({
      callType: 'send',
      objectValue: {
        error,
      },
    });
  }
  let meta = {
    errorStack: error.stack,
  };
  if (errorOptions.metaData) {
    meta = { ...meta, ...errorOptions.metaData };
  }
  log(
    isBusinessError ? LogLevel.Info : LogLevel.Error,
    {
      serviceCategory: errorOptions.errorServiceCategory || 'Capture',
      service: errorOptions.errorService || '',
      eventType: errorOptions.eventType || '',
      eventName: error.message,
      eventSource: errorOptions.errorSource,
      component: errorOptions.component || '',
      exceptionName: errorOptions.exceptionName || '',
      exceptionDescription: errorOptions.exceptionDescription || '',
      referenceID: errorOptions.referenceID || '',
      referenceType: errorOptions.referenceType || '',
      publishToDLK: errorOptions.publishToDLK,
    },
    meta,
  );
  return {
    type: ERROR,
    payload: {
      error,
      errorType: errorOptions.errorType,
      errorMessage: errorOptions.exceptionName,
      pageIndex: errorOptions.pageIndex,
    },
  };
}

/**
 * Clear Error
 */
export function clearErrorAction(): ErrorActions {
  return {
    type: CLEAR_ERROR,
    payload: { error: null },
  };
}

/**
 * Display Error page as overlay
 * @param overlay updated value for action
 */
export function ShowOverlayAction(overlay: OverlayOptions): OverlayActions {
  return {
    type: OVERLAY,
    payload: {
      error: overlay.error,
      titleConfig: {
        primaryText: overlay.titleConfig.primaryText,
        secondaryText: overlay.titleConfig.secondaryText,
        tertiaryText: overlay.titleConfig.tertiaryText,
        titleSvg: overlay.titleConfig.titleSvg,
        displayLogo: overlay.titleConfig.displayLogo,
        justifySecondaryText: overlay.titleConfig.justifySecondaryText,
      },
      buttons: overlay.buttons,
      bottomNote: overlay.bottomNote,
      errorType: overlay.errorType,
      actionCallback: overlay.actionCallback,
      handleGoBack: overlay.handleGoBack,
      headerConfig: overlay.headerConfig,
      handleCrossBtn: overlay.handleCrossBtn,
      showHeader: overlay.showHeader,
      showCrossBtn: overlay.showCrossBtn,
      loaderConfig: overlay.loaderConfig,
    },
  };
}

/**
 * Clear Error Overlay
 */
export function ClearOverlayAction(): OverlayActions {
  return {
    type: CLEAR_OVERLAY,
    payload: {
      error: null,
      titleConfig: { primaryText: '', secondaryText: '' },
      showCrossBtn: false,
    },
  };
}

export function ShowRedirectingOverlayAction(
  overlay: RedirectingOverlayOption,
): RedirectingOverlayActions {
  return {
    type: REDIRECTING_OVERLAY,
    payload: {
      showLoader: overlay.showLoader,
      redirectingText: overlay.redirectingText,
      error: overlay.error,
      errorType: overlay.errorType,
      handleClick: overlay.handleClick,
    },
  };
}

export function ClearRedirectingOverlayAction(): RedirectingOverlayActions {
  return {
    type: CLEAR_REDIRECTING_OVERLAY,
    payload: {
      showLoader: false,
      redirectingText: '',
      error: null,
    },
  };
}
