import {
  createStore,
  combineReducers,
  applyMiddleware,
  Store,
  CombinedState,
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import GroupReducer from '../components/Group/store/reducers';
import OCRReducer from '../components/Section/store/reducers';
import {
  IndvCaptureSeqReducer,
  OverrideSessionReducer,
  SavePageConfigReducer,
} from '../components/IndvCapture/store/reducers';
import CaptureConfigReducer from '../components/Capture/store/reducers';
import ArtifactReducer from './Artifacts/reducers';
import HeaderReducer from '../components/Header/store/reducers';
import HandleReloadReducer from './Reload/reducers';
import ImageTitleReducer from '../components/Image/store/reducers';
import IndvCaptureStatusReducer from '../components/IndvCaptureStatus/store/reducers';
import saveResponseReducer from './ResponseBody/reducers';
import ErrorReportingMiddleware from './ErrorReportingMiddleware';
import BreadcrumbMiddleware from './BreadCrumbMiddleware';
import { ExitUriReducer, RedirectUriReducer } from './RedirectUri/reducers';
import { ErrorAndOverlayReducer } from './Error/reducers';
import PageHeaderReducer from './ResponseHeader/reducers';
import { ArtifactActions } from './Artifacts/types';
import {
  ErrorDetail,
  ErrorActions,
  OverlayOptions,
  OverlayActions,
  RedirectingOverlayOption,
  RedirectingOverlayActions,
} from './Error/types';
import { RedirectUriActions, RedirectUri, ExitUri } from './RedirectUri/types';
import { HandleReloadActions, ReloadFlag } from './Reload/types';
import { GroupDetails, GroupAction } from '../components/Group/store/types';
import { OCRDetails, OCRActions } from '../components/Section/store/types';
import {
  CaptureSequence,
  CaptureActions,
  OverrideSessionActions,
  OverrideSession,
  PageData,
  SavePageConfigActions,
  SchedulingInfo,
} from '../components/IndvCapture/store/types';
import {
  CaptureConfigDetails,
  ArtifactDetails,
  CaptureConfigActions,
  TasksInfo,
} from '../components/Capture/store/types';
import { HeaderDetails, HeaderActions } from '../components/Header/store/types';
import { PageHeaderDetails, PageHeaderActions } from './ResponseHeader/types';
import { SaveResponseActions, ResponseDetails } from './ResponseBody/types';
import {
  ImageTitleActions,
  ImageTitleDetails,
} from '../components/Image/store/types';
import {
  IndvCaptureStatusActions,
  IndvCaptureStatus,
} from '../components/IndvCaptureStatus/store/types';
import TasksReducer from './Tasks/reducers';
import { TaskActions } from './Tasks/types';
import { AadhaarXMLScrappingReducer } from './AadhaarXMLScraping/reducers';
import {
  AadhaarXMLScrapedDetails,
  AadhaarXMLScrapedActions,
} from './AadhaarXMLScraping/types';
import PermissionReducer from '../components/PermissionsPage/store/reducers';
import {
  PermissionsActions,
  PermissionDetails,
} from '../components/PermissionsPage/store/types';
import { SaveAVKycPageConfigReducer } from '../components/AssistedVideoKYC/store/reducers';
import {
  AVKycPageData,
  SaveAVKycPageConfigActions,
} from '../components/AssistedVideoKYC/store/types';
import ThemeReducer from './ThemeConfig/reducers';
import { ThemeConfig, ThemeConfigActions } from './ThemeConfig/types';
import { HandleSchedulingActions } from './Scheduling/types';
import HandleSchedulingReducer from './Scheduling/reducers';
import {
  SVServicePageData,
  SaveSVServiceTaskActions,
} from '../components/SelfVideoService/store/types';
import { SaveSVServiceTaskReducer } from '../components/SelfVideoService/store/reducer';
import { ToastActions, ToastData } from './Toast/types';
import { handleToastReducer } from './Toast/reducers';
import { DIVDetails, UpdateInProgressDIV } from './Div/types';
import DIVReducer from './Div/reducers';
import FooterReducer from '../components/Footer/store/reducers';
import { FooterActions, FooterDetails } from '../components/Footer/store/types';
import { SaveSkillReducer } from '../components/AssistedVideoKYC/AVSkillSelect/store/reducers';
import {
  SaveSkillConfigActions,
  SkillConfiguration,
} from '../components/AssistedVideoKYC/AVSkillSelect/store/types';
import IDfyPluginMiddleware from './IDfyPluginMiddleware';
import {
  DFSessionDetails,
  SaveDFSessionDetailsActions,
} from '../components/DocumentFetcher/store/types';
import DFSessionReducer from '../components/DocumentFetcher/store/reducers';
import PrerequisiteReducer from '../components/PrerequisitePage/store/reducers';
import {
  PrerequisiteActions,
  PrerequisitePageConfig,
} from '../components/PrerequisitePage/store/types';

const rootReducer = combineReducers({
  GroupDetails: GroupReducer,
  CaptureSequence: IndvCaptureSeqReducer,
  CaptureDetails: CaptureConfigReducer,
  ArtifactDetails: ArtifactReducer,
  HeaderDetails: HeaderReducer,
  ReloadFlag: HandleReloadReducer,
  ImageTitles: ImageTitleReducer,
  IndvCaptureStatus: IndvCaptureStatusReducer,
  ResponseBody: saveResponseReducer,
  RedirectUri: RedirectUriReducer,
  ErrorAndOverlay: ErrorAndOverlayReducer,
  PromptOverride: OverrideSessionReducer,
  PageHeaderDetails: PageHeaderReducer,
  TasksInfo: TasksReducer,
  AadhaarXMLScrapping: AadhaarXMLScrappingReducer,
  PageData: SavePageConfigReducer,
  PermissionData: PermissionReducer,
  AVKYCPageDetails: SaveAVKycPageConfigReducer,
  ThemeConfig: ThemeReducer,
  SchedulingInfo: HandleSchedulingReducer,
  SVServicePageDetails: SaveSVServiceTaskReducer,
  ToastData: handleToastReducer,
  ExitUri: ExitUriReducer,
  DIVDetails: DIVReducer,
  FooterDetails: FooterReducer,
  SkillConfiguration: SaveSkillReducer,
  DFSessionDetails: DFSessionReducer,
  PrerequisitePage: PrerequisiteReducer,
  OCRDetails: OCRReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export type CaptureUIStore = Store<
  CombinedState<{
    GroupDetails: GroupDetails;
    OCRDetails: OCRDetails;
    CaptureSequence: CaptureSequence;
    CaptureDetails: CaptureConfigDetails;
    ArtifactDetails: ArtifactDetails;
    HeaderDetails: HeaderDetails;
    ReloadFlag: ReloadFlag;
    ImageTitles: ImageTitleDetails;
    IndvCaptureStatus: IndvCaptureStatus;
    ResponseBody: ResponseDetails;
    RedirectUri: RedirectUri;
    ErrorAndOverlay: ErrorDetail | OverlayOptions | RedirectingOverlayOption;
    PromptOverride: OverrideSession;
    PageHeaderDetails: PageHeaderDetails;
    TasksInfo: TasksInfo;
    AadhaarXMLScrapping: AadhaarXMLScrapedDetails;
    PageData: PageData;
    PermissionData: PermissionDetails;
    AVKYCPageDetails: AVKycPageData;
    ThemeConfig: ThemeConfig;
    SchedulingInfo: SchedulingInfo;
    SVServicePageDetails: SVServicePageData;
    ToastData: ToastData;
    ExitUri: ExitUri;
    DIVDetails: DIVDetails;
    FooterDetails: FooterDetails;
    SkillConfiguration: SkillConfiguration;
    DFSessionDetails: DFSessionDetails;
    PrerequisitePage: PrerequisitePageConfig;
  }>,
  | ArtifactActions
  | ErrorActions
  | RedirectUriActions
  | HandleReloadActions
  | SaveResponseActions
  | PageHeaderActions
  | CaptureConfigActions
  | GroupAction
  | OCRActions
  | HeaderActions
  | ImageTitleActions
  | CaptureActions
  | OverrideSessionActions
  | IndvCaptureStatusActions
  | TaskActions
  | AadhaarXMLScrapedActions
  | SavePageConfigActions
  | PermissionsActions
  | SaveAVKycPageConfigActions
  | OverlayActions
  | RedirectingOverlayActions
  | ThemeConfigActions
  | HandleSchedulingActions
  | SaveSVServiceTaskActions
  | ToastActions
  | UpdateInProgressDIV
  | FooterActions
  | SaveSkillConfigActions
  | SaveDFSessionDetailsActions
  | PrerequisiteActions
>;
export function configureStore(): CaptureUIStore {
  const middlewares = [
    thunkMiddleware,
    BreadcrumbMiddleware,
    ErrorReportingMiddleware,
    IDfyPluginMiddleware,
  ];
  const middleWareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(
    rootReducer,
    composeWithDevTools(middleWareEnhancer),
  );
  return store;
}
