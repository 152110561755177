import compareVersions from 'compare-versions';
import {
  capitalizeString,
  isIOSVersionCompatible,
  findElement,
} from '../helpers';
import i18n from '../i18n/i18n';
import { log, LogLevel } from './loggerWrapper';
import { BrowserInfo, detect, detectOSWithVersion } from './browser-detection';

export const WEBRTC_NAME = 'webrtc';
export const AUD_VID_NAME = 'audio/video';
export const FILE_INPUT_NAME = 'file_upload';
export const CAMERA_NAME = 'camera';
export const URL_PARAMS_NAME = 'url_parameters';
export const JSON_SUPPORT_NAME = 'json';
export const BINARY_CONVERSION_NAME = 'atob';
export const CLIPBOARD_NAME = 'clipboard';
export const GEOLOCATION_NAME = 'location';
export const IOS_VERSION_NAME = 'ios_version';
export const H264_NAME = 'h264';

const translate = i18n.t.bind(i18n);

interface CompatibilityCheckType {
  checkLabel: string;
  checkName: string;
  enforceCheck: boolean;
  checkResult: boolean;
}

function checkClipboard(): boolean {
  return !!document.queryCommandSupported('copy');
}

function checkGeoLocation(): boolean {
  return !!navigator.geolocation;
}

const featureRegistry: CompatibilityCheckType[] = [
  {
    checkLabel: translate('WEBRTC'),
    checkName: WEBRTC_NAME,
    enforceCheck: true,
    // @ts-ignore
    checkResult: Modernizr.peerconnection,
  },
  {
    checkLabel: translate('AUD_VID'),
    checkName: AUD_VID_NAME,
    enforceCheck: true,
    // @ts-ignore
    checkResult: Modernizr.video,
  },
  {
    checkLabel: translate('H264'),
    checkName: H264_NAME,
    enforceCheck: true,
    // @ts-ignore
    checkResult: Modernizr.video.h264 !== '',
  },
  {
    checkLabel: translate('FILE_INPUT'),
    checkName: FILE_INPUT_NAME,
    enforceCheck: true,
    // @ts-ignore
    checkResult: Modernizr.fileinput,
  },
  {
    checkLabel: translate('CAMERA'),
    checkName: CAMERA_NAME,
    enforceCheck: true,
    // @ts-ignore
    checkResult: Modernizr.getusermedia,
  },
  {
    checkLabel: translate('URL_PARAMS'),
    checkName: URL_PARAMS_NAME,
    enforceCheck: true,
    // @ts-ignore
    checkResult: Modernizr.urlsearchparams,
  },
  {
    checkLabel: translate('JSON_SUPPORT'),
    checkName: JSON_SUPPORT_NAME,
    enforceCheck: true,
    // @ts-ignore
    checkResult: Modernizr.json,
  },
  {
    checkLabel: translate('BINARY_CONVERSION'),
    checkName: BINARY_CONVERSION_NAME,
    enforceCheck: true,
    // @ts-ignore
    checkResult: !!atob('test'),
  },
  {
    checkLabel: translate('CLIPBOARD'),
    checkName: CLIPBOARD_NAME,
    enforceCheck: false,
    // @ts-ignore
    checkResult: checkClipboard(),
  },
  {
    checkLabel: translate('GEOLOCATION'),
    checkName: GEOLOCATION_NAME,
    enforceCheck: true,
    // @ts-ignore
    checkResult: checkGeoLocation(),
  },
  {
    checkLabel: translate('IOS_VERSION'),
    checkName: IOS_VERSION_NAME,
    enforceCheck: false,
    // @ts-ignore
    checkResult: isIOSVersionCompatible(),
  },
];

function getIndexForFeature(key: string): number {
  return featureRegistry.findIndex((x) => x.checkName === key);
}

/**
 * Determine whether to blacklist user based on rule
 * @param rule
 * @param browser
 * @param os
 * @returns boolean
 */
function shouldBlacklist(
  rule: { [ident: string]: string | string[] },
  browser: BrowserInfo,
  data: { [ident: string]: string },
): boolean {
  const filteredRuleKeys = Object.keys(rule).filter((key) => {
    return (
      key !== 'include' && key !== 'exclude' && key !== 'min' && key !== 'max'
    );
  });
  for (let i = 0; i < filteredRuleKeys.length; i++) {
    if (
      Array.isArray(rule[filteredRuleKeys[i]]) &&
      (rule[filteredRuleKeys[i]] as string[]).findIndex((value) =>
        new RegExp(value).test(data[filteredRuleKeys[i]]),
      )
    ) {
      // user's details are not listed in Rule
      return false;
    }
  }

  if (rule.exclude && findElement(rule.exclude as string[], browser.version)) {
    return false;
  }

  if (rule.include && findElement(rule.include as string[], browser.version)) {
    return true;
  }

  if (rule.min && compareVersions.compare(browser.version, rule.min, '<')) {
    return true;
  }

  if (rule.max && compareVersions.compare(browser.version, rule.max, '>')) {
    return true;
  }

  return false;
}

export function setSkipCompatiblityChecks(): void {
  featureRegistry.forEach((element, elemIndex) => {
    featureRegistry[elemIndex].enforceCheck = false;
  });
}

function verifyFeatureForIndex(index: number): boolean {
  return featureRegistry[index].enforceCheck
    ? featureRegistry[index].checkResult
    : true;
}

export function getCompatibilityChecks(): {
  type: string;
  value: boolean;
}[] {
  const returnArr: { type: string; value: boolean }[] = [];
  featureRegistry.forEach((element, index) => {
    returnArr.push({
      type: element.checkLabel,
      value: verifyFeatureForIndex(index),
    });
  });
  return returnArr;
}

/**
 * Verify's whether listed browsers are compatible or not
 * @param blackListedBrowsers
 * @returns True or False
 */
export function isBrowserBlacklisted(
  blacklistedBrowserData: any, //eslint-disable-line
  userAgent: string,
  deviceDetails: { brand: string; model: string },
): boolean {
  const detectedbrowser = detect(userAgent);
  const userOS = detectOSWithVersion(userAgent);
  if (detectedbrowser && detectedbrowser.name in blacklistedBrowserData) {
    if (
      typeof blacklistedBrowserData[detectedbrowser.name] === 'boolean' &&
      blacklistedBrowserData[detectedbrowser.name]
    ) {
      // Blacklist if true
      return true;
    }
    if (
      typeof blacklistedBrowserData[detectedbrowser.name] === 'string' &&
      detectedbrowser.version
    ) {
      // if Blacklist all sub versions of blacklistedBrowserData[detectedbrowser.name]
      // blackslist on all operating systems
      if (
        detectedbrowser.version.startsWith(
          blacklistedBrowserData[detectedbrowser.name],
        )
      ) {
        return true;
      }
    } else if (Array.isArray(blacklistedBrowserData[detectedbrowser.name])) {
      for (
        let index = 0;
        index < blacklistedBrowserData[detectedbrowser.name].length;
        index += 1
      ) {
        const rule = blacklistedBrowserData[detectedbrowser.name][index];

        if (
          shouldBlacklist(rule, detectedbrowser, {
            ...deviceDetails,
            os: userOS,
          })
        ) {
          return true;
        }
      }
    }
  }
  return false;
}

/**
 * Updates the registry as per configuration
 * @param settings
 */
// eslint-disable-next-line
export function updateMandatoryOptions(settings: any): void {
  const keys = Object.keys(settings);
  keys.forEach((key: string) => {
    const foundItem = getIndexForFeature(key);
    if (foundItem > -1) {
      featureRegistry[foundItem].enforceCheck = settings[key];
    }
  });
}

export function checkAllFeatures(): boolean {
  return getCompatibilityChecks().every((item) => {
    log(LogLevel.Info, {
      serviceCategory: 'Capture',
      service: 'DeviceCompatibility',
      eventType: item.value ? 'Passed' : 'Failed',
      eventName: capitalizeString(item.type),
      component: 'Compatibility',
      eventSource: 'getCompatibilityChecks',
      logVersion: 'v2',
      publishToDLK: true,
    });
    return item.value;
  });
}

export function verifyFeature(key: string): boolean {
  const index = getIndexForFeature(key);
  return index > -1 ? verifyFeatureForIndex(index) : false;
}
