import { ThemeConfigActions, ThemeConfig } from './types';
import { THEME } from '../../helpers/actionTypes';

const initialState: ThemeConfig = {
  palette: {
    primary: {
      main: '#279a9e',
      light: '#52aeb1',
      dark: '#1b6b6e',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ffffff',
      light: '#ffffff',
      dark: '',
      contrastText: '#000000',
    },
    info: {
      main: '#279a9e',
      light: '#52aeb1',
      dark: '',
      contrastText: '',
    },
    success: {
      main: '#4caf50',
      light: '#6fbf73',
      dark: '',
      contrastText: '#000000',
    },
    error: {
      main: '#f44336',
      light: '',
      dark: '',
      contrastText: '',
    },
    warning: {
      main: '#ff9800',
      light: '#ffb74d',
      dark: '#f57c00',
      contrastText: '#000000',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Source Sans Pro',
    },
    h2: {
      fontFamily: 'Source Sans Pro',
    },
    h3: {
      fontFamily: 'Source Sans Pro',
    },
    h4: {
      fontFamily: 'Source Sans Pro',
    },
    h5: {
      fontFamily: 'Source Sans Pro',
    },
    h6: {
      fontFamily: 'Source Sans Pro',
    },
    subtitle1: {
      fontFamily: 'Source Sans Pro',
    },
    subtitle2: {
      fontFamily: 'Source Sans Pro',
    },
    caption: {
      fontFamily: 'Nunito Sans',
    },
    button: {
      fontFamily: 'Nunito Sans',
    },
    body1: {
      fontFamily: 'Nunito Sans',
    },
    body2: {
      fontFamily: 'Nunito Sans',
    },
  },
};

/**
 * Reducer for storing Tasks Details
 * @param state Previous state from the store
 * @param action Action to be performed
 */
export default function ThemeReducer(
  state = initialState,
  action: ThemeConfigActions,
): ThemeConfig {
  switch (action.type) {
    case THEME:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
